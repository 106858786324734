<template>
    <ResetPasswordBody/>
</template>
  
  <script>
  import { defineComponent } from "vue";
  import ResetPasswordBody from "@/components/account/ResetPasswordBody.vue";
  
  export default defineComponent({
    name: "ResetPasswordView",
    components: {
      ResetPasswordBody,
    },
  });
  </script>
  