<template>
    <footer class="fugu-footer-section" style="background-color: #120130;">
    <div class="container">
      <div class="row">
        <div class="col-lg-4">
          <div class="fugu-textarea">
            <div class="fugu-footer-logo">
              <img src="@/assets/baseAssets/images/logo.png" alt="" class="light-version-logo">
            </div>
            <p>OMCDEX Vault is a decentralized wallet built on the solana blockchain, fully encrypted with an advanced security system and offers optimal value in utility. Easily buy, store, collect NFTs, exchange and earn crypto. </p>
            <p class="fugu-copywright">&copy; Copyright 2024, All Rights Reserved by OMCDEX Vault</p>
          </div>
        </div>
        <div class="col-lg-2 offset-lg-1 col-md-4 col-sm-4">
          <div class="fugu-footer-menu">
            <span></span>
            <ul>
              <li><router-link :to="{name: 'home'}" class="text-white">Home</router-link></li>
              <li><router-link :to="{name: 'about'}" class="text-white">About</router-link></li>
              <li><router-link :to="{name: 'token'}" class="text-white">Token info</router-link></li>
              
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-4">
          <div class="fugu-footer-menu fugu-custom-margin">
            <span></span>
            <ul>
              <li><router-link :to="{name: 'staking'}" class="text-white">Staking</router-link></li>
              <li><router-link :to="{name: 'blog'}" class="text-white">Blog</router-link></li>
              <li><router-link :to="{name: 'contact'}" class="text-white">Contact</router-link></li>
            
            </ul>
          </div>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-4">
          <div class="fugu-footer-menu">
            <span>Contact</span>
            <ul>
              <li><a href="tel:+44 741 836 4171" class="text-white">+44 741 8364 809</a></li>
              <li><a href="mailto:support@omcdex.io" class="text-white">support@omcdex.io</a></li>
            </ul>
          </div>
          <div class="fugu-social-icon">
            <ul>
              <li>
                <a href="https://twitter.com/omcdex?s=21" target="_blank">
                  <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.6518 1.76691C12.2028 1.96645 11.7289 2.09116 11.2301 2.16598C11.7289 1.86668 12.128 1.39279 12.3026 0.819124C11.8287 1.09348 11.3049 1.29302 10.7312 1.41773C10.2823 0.943833 9.63379 0.644531 8.93542 0.644531C7.58857 0.644531 6.49113 1.74197 6.49113 3.08883C6.49113 3.28836 6.51607 3.46295 6.56595 3.63755C4.54567 3.53778 2.72492 2.56505 1.50277 1.06854C1.30323 1.44267 1.17852 1.84174 1.17852 2.29069C1.17852 3.13871 1.60253 3.88697 2.27596 4.33592C1.87689 4.31098 1.50277 4.21121 1.15358 4.03662V4.06156C1.15358 5.25876 2.0016 6.25644 3.12398 6.48091C2.92445 6.5308 2.69997 6.55574 2.4755 6.55574C2.32585 6.55574 2.15125 6.5308 2.0016 6.50585C2.32585 7.47859 3.22375 8.2019 4.29625 8.2019C3.44823 8.85038 2.40067 9.24945 1.25335 9.24945C1.05381 9.24945 0.85428 9.24945 0.679688 9.22451C1.77713 9.92288 3.04916 10.322 4.4459 10.322C8.96037 10.322 11.4296 6.58068 11.4296 3.33825C11.4296 3.23848 11.4296 3.11377 11.4296 3.014C11.9035 2.68976 12.3275 2.26575 12.6518 1.76691Z" fill="white"></path>
                  </svg>
                </a>
              </li>
              <!-- <li>
                <a href="https://www.facebook.com/profile.php?id=100084754149663" target="_blank">
                  <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.61761 13.7535V7.8033H0.615234V5.48437H2.61761V3.77424C2.61761 1.78964 3.82974 0.708984 5.60014 0.708984C6.44818 0.708984 7.17702 0.772123 7.38943 0.800344V2.87437L6.16156 2.87492C5.19872 2.87492 5.01229 3.33245 5.01229 4.00385V5.48437H7.30858L7.00959 7.8033H5.01228V13.7535H2.61761Z" fill="#ffffff"></path>
                  </svg>
                </a>
              </li> -->
              
            </ul>
          </div>
        </div>
      </div>
    </div>
    </footer>
</template>