<template>
    <Navbar/>
    <PrivacyBody/>
    <Footer/>
  </template>
  
  <script>
  import { defineComponent } from "vue";
  import Navbar from "@/components/NavbarBody.vue";
  import PrivacyBody from "@/components/PrivacyBody.vue";
  import Footer from "@/components/FooterBody.vue"; 
  
  export default defineComponent({
    name: "PrivacyView",
    components: {
      Navbar,
      PrivacyBody,
      Footer
    },
  });
  </script>
  