<template>

    <div class="page">
        <SidebarBody/>
        <SwapBody/>
    </div>
    
  </template>
  
  <script>
  import { defineComponent } from "vue";
  import SidebarBody from "@/components/dashboard/SidebarBody.vue";
  import SwapBody from "@/components/dashboard/SwapBody.vue";
  
  export default defineComponent({
    name: "SwapView",
    components: {
      SidebarBody,
      SwapBody
    },
  });
  </script>
  