<template>

    <div class="page">
        <SidebarBody/>
        <CardBody/>
    </div>
    
  </template>
  
  <script>
  import { defineComponent } from "vue";
  import SidebarBody from "@/components/dashboard/SidebarBody.vue";
  import CardBody from "@/components/dashboard/CardBody.vue";
  
  export default defineComponent({
    name: "CardView",
    components: {
      SidebarBody,
      CardBody
    },
  });
  </script>
  