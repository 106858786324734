<template>
    <a2faBody/>
</template>
  
  <script>
  import { defineComponent } from "vue";
  import a2faBody from "@/components/account/2faBody.vue";
  
  export default defineComponent({
    name: "2faView",
    components: {
      a2faBody,
    },
  });
  </script>
  