import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC_rMNt4-8t4ZeSZCaDXrsArkx3RdPkvfg",
  authDomain: "omcn-62d76.firebaseapp.com",
  projectId: "omcn-62d76",
  storageBucket: "omcn-62d76.appspot.com",
  messagingSenderId: "738220871295",
  appId: "1:738220871295:web:bb0e23d9d9fe10b6d7394a",
  measurementId: "G-N6XLP8B1H6"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export{
    db
}