<template>

    <!-- PRELOADER -->
    <div class="fugu-preloader">
        <div class="fugu-spinner">
        <img src="@/assets/baseAssets/images/omicron.png" alt="" style="width: 80px;">
        </div>
    
    </div>

    <!-- HERO -->

    <div class="fugu-hero-section hm" >
    <div class="container">
      <div class="row">
        <div class="col-xl-6 col-lg-7">
          <div class="fugu-hero-content">
            <h2 class="wow fadeInUpX text-white" data-wow-delay="0s" >Non-custodial wallet and NFT Marketplace in one place</h2>
            <p class="wow fadeInUpX text-white" data-wow-delay="0.25s" > Easily buy, store, collect NFTs, exchange and earn crypto. We support an ever growing list of assets. Sign up with us today to  secure your seat on the space ship and start your journey into the future of crypto.</p>
           
            <div class="fugu-btn-wrap">
              
                <router-link class="fugu-btn" :to="{name: 'sign-up'}" target="_blank" style="color: #ffffff;">Get Started</router-link>
                
            </div>

            <div class="fugu-hero-client-area wow fadeInUpX" data-wow-delay="0.50s" style="margin-top: 40px;">
              <div class="fugu-hero-client-wrap">
                <div class="fugu-hero-client-thumb">
                  <img src="@/assets/baseAssets/images/client.png" alt="">
                </div>
                <div class="fugu-hero-client-data text-white">
                  <h3 class="text-white">94,739</h3>
                  <p class="text-white">Happy Customers</p>
                </div>
              </div>
              <div class="fugu-hero-client-rating">
                <h3 class="text-white">4/5</h3>
                <ul>
                  <li><img src="@/assets/baseAssets/images/star1.svg" alt=""></li>
                  <li><img src="@/assets/baseAssets/images/star1.svg" alt=""></li>
                  <li><img src="@/assets/baseAssets/images/star1.svg" alt=""></li>
                  <li><img src="@/assets/baseAssets/images/star1.svg" alt=""></li>
                  <li><img src="@/assets/baseAssets/images/star2.svg" alt=""></li>
                  <li class="text-white">Rating</li>
                </ul>
              </div>
            </div>

          </div>
        </div>
        <div class="col-xl-6 col-lg-5">
          <div class="fugu-hero-thumb" >
            <img src="@/assets/baseAssets/images/hand-mocup.png" alt="">
            <div class="fugu-shape2">
              <img src="@/assets/baseAssets/images/shape2.png" alt="">
            </div>
            <div class="fugu-hero-star">
              <img src="@/assets/baseAssets/images/star-shape.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fugu-shape1">
      <img src="@/assets/baseAssets/images/shape1.png" alt="">
    </div>
    </div>
    
    <!-- End hero section -->

    <hr>

    <div class="fugu-client-section" style="background-color: #120130;">
        <div class="container">
        <div class="fugu-client-title text-white">
            <h5 class="text-white">Trusted by the world's most popular companies</h5>
        </div>
        <div class="fugu-client-slider">
            <div class="fugu-brand-logo">
            <img src="@/assets/baseAssets/images/client_icon_1.png" alt="">
            </div>
            <div class="fugu-brand-logo">
            <img src="@/assets/baseAssets/images/client_icon_2.png" alt="">
            </div>
            <div class="fugu-brand-logo">
            <img src="@/assets/baseAssets/images/client_icon_3.png" alt="">
            </div>
            <!-- <div class="fugu-brand-logo">
            <img src="@/assets/baseAssets/images/client_icon_4.png" alt="">
            </div> -->
            <div class="fugu-brand-logo">
            <img src="@/assets/baseAssets/images/client_icon_5.png" alt="">
            </div>
            <div class="fugu-brand-logo">
            <img src="@/assets/baseAssets/images/client_icon_6.png" alt="">
            </div>
            <div class="fugu-brand-logo">
            <img src="@/assets/baseAssets/images/client_icon_7.png" alt="">
            </div>
            
        </div>
        </div>
    </div>
    <!-- End client section -->

    <hr>

    <div class="section bg-warning-400 fugu-section-padding3">
        <div class="container">
        <div class="fugu-section-title title-large">
            <h2 class="text-white">Combines the best features of crypto</h2>
        </div>
        <div class="row">
            <div class="col-lg-6">
            <div class="fugu-iconbox-wrap wow fadeInUpX" data-wow-delay="0s" style="background-color: #120130;">
                <div class="fugu-iconbox-icon">
                <img src="@/assets/baseAssets/images/icon1.png" alt="">
                </div>
                <div class="fugu-iconbox-data">
                <h4 class="text-white">Secure storage</h4>
                <p class="text-white">Your keys are protected with Secure Enclave, 2 factor authentication & optional cloud backups</p>
                
                </div>
            </div>
            </div>
            <div class="col-lg-6">
            <div class="fugu-iconbox-wrap wow fadeInUpX" data-wow-delay=".20s" style="background-color: #120130;">
                <div class="fugu-iconbox-icon">
                <img src="@/assets/baseAssets/images/icon2.png" alt="">
                </div>
                <div class="fugu-iconbox-data">
                <h4 class="text-white">Digital collectibles</h4>
                <p class="text-white">Buy, sell, and store your NFTs in a single beautiful gallery</p>
                
                </div>
            </div>
            </div>
            <div class="col-lg-6">
            <div class="fugu-iconbox-wrap wow fadeInUpX" data-wow-delay=".30s" style="background-color: #120130;">
                <div class="fugu-iconbox-icon">
                <img src="@/assets/baseAssets/images/icon3.png" alt="">
                </div>
                <div class="fugu-iconbox-data">
                <h4 class="text-white">Universal access</h4>
                <p class="text-white">Our platform allows you to safely access your cryto assets from anywhere in the world</p>
                
                </div>
            </div>
            </div>

            <div class="col-lg-6">
            <div class="fugu-iconbox-wrap fugu-iconbox-wrap5 wow fadeInUpX" data-wow-delay=".40s" style="background-color: #120130;">
                <div class="fugu-iconbox-icon">
                <img src="@/assets/baseAssets/images/icon5.png" alt="">
                </div>
                <div class="fugu-iconbox-data">
                <h4 class="text-white">Watch and analyze charts</h4>
                <p class="text-white"> Our user friendly dashboard lets you analyze crypto charts and keep up with the crypto world</p>
                
                </div>
            </div>
            </div>
            
        </div>
        </div>
    </div>
    <!-- End section -->

    <hr>

    <div class="section bg-warning-400 fugu-section-padding">
        <div class="container">
        <div class="row">
            
            <div class="col-lg-6">
                <div class="fugu-apps-thumb" id="rotatetwo">
                <img class="wow fadeInUpX" data-wow-delay=".10s" src="@/assets/baseAssets/images/mocup1.png" alt="">
                </div>
            </div>


            <div class="col-lg-5 col-md-10 d-flex align-items-center">
            <div class="fugu-default-content large-content">
                <h2 class="text-white">Fast & Safe.</h2>
                <p class="text-white">Today, billions of people can’t open bank accounts, others have their payments blocked. OMCDEX's decentralized finance (DeFi) system never sleeps or discriminates. With just an internet connection, you can send, receive, borrow, earn interest, and even stream funds anywhere in the world. Just create an account and dive in</p>
                <div class="fugu-icon-list">
                <ul>
                    <li class="text-white"><img src="@/assets/baseAssets/images/check1.png" style="width: 25px" alt=""> <span style="margin-right:10px"> </span> Create an account.</li>
                    <li class="text-white"><img src="@/assets/baseAssets/images/check1.png" style="width: 25px" alt=""> <span style="margin-right:10px"> </span> Verify your email.</li>
                    <li class="text-white"><img src="@/assets/baseAssets/images/check1.png" style="width: 25px" alt=""> <span style="margin-right:10px"> </span> Start trading crypto.</li>
                </ul>
                </div>
            </div>

            </div>

        </div>
        </div>
    </div>
    <!-- End section -->

    <hr>

    <div class="fugu-testimonial-section fugu-section-padding2">
        <div class="container">
        <div class="fugu-section-title title-large">
            <h2 class="text-white">Our products</h2>
        </div>
        

        <div class="row">

            <div class="col-md-4">
                
                <div class="feature-card" style="height: 700px; margin-top: 0px;">
                    <div style="margin-top: -70px;">
                        <!--HEADER-->
                        <div style="display: flex; justify-content: flex-start; align-items: center; flex-direction: column; ">
                            <div class="feature-icon">
                                <img src="@/assets/baseAssets/images/s1.png" alt="" width="60px" height="60px">
                            </div>
                            <div style="margin-left: 5px;">
                                <p class="feature-title pb-15 text-white" style="font-size: 16px;">Solana Ecosystem Index</p>
                                <p class="feature-des text-white" style="margin-top: -12px; text-align: center;">SOLI </p>
                            </div>
                        </div>
                        
                    </div>
        
                    <div>
                        <p style="text-align: start; margin-top: 5px; font-size: 12px;" class="text-white">SOLI provides holders with a weighted index containing Solana native tokens and staked SOL for users to gain diversified exposure to the Solana Ecosystem</p>
                    </div>
        
                    <div style="margin-left: 0px; margin-top: 10px; width: 100%;">
                        <h6 style="text-align: start; width: 100%; font-size: 14px;" class="text-white">Allocations</h6>
                        <div style="display: flex; justify-content: flex-start; align-items: center; margin-top: 10px;">
                            <img src="@/assets/baseAssets/images/sol.png" alt="" width="30px" height="30px" style="margin-right: 5px;">
                            <img src="@/assets/baseAssets/images/s2.png" alt="" width="30px" height="30px" style="margin-right: 5px;">
                            <img src="@/assets/baseAssets/images/s3.png" alt="" width="30px" height="30px" style="margin-right: 5px;">
                        </div>
                    </div>
        
                    <div style="display: flex; justify-content: flex-start; width: 100%; align-items: center; margin-top: 20px;">
                        <div style="margin-right: 80px;">
                            <h6 style="font-size: 12px;" class="text-white">+22.74%</h6>
                            <p style="font-size: 10px;" class="text-white">Last 7 days</p>
                        </div>
        
                        <div>
                            <h6 style="font-size: 12px;" class="text-white">$1.09M</h6>
                            <p style="font-size: 10px;" class="text-white">Market Cap</p>
                        </div>
                    </div>
                    
                </div>


            </div>

            <div class="col-md-4">
                <div class="feature-card" style="height: 700px; margin-top: 0px;">
                    <div style="margin-top: -70px;">
                        <!--HEADER-->
                        <div style="display: flex; justify-content: flex-start; align-items: center; flex-direction: column; ">
                            <div class="feature-icon">
                                <img src="@/assets/baseAssets/images/s8.png" alt="" width="60px" height="60px">
                            </div>
                            <div style="margin-left: 5px;">
                                <p class="feature-title pb-15 text-white" style="font-size: 16px;">DeFi Index Token</p>
                                <p class="feature-des text-white" style="margin-top: -12px; text-align: center;">DEFI </p>
                            </div>
                        </div>
                        
                    </div>

                    <div>
                        <p style="text-align: start; margin-top: 5px; font-size: 12px;" class="text-white">Bullish on DeFi? Want exposure to the top coins bt market cap without having to pay for eight different swaps? Let us take care of it for you</p>
                    </div>

                    <div style="margin-left: 0px; margin-top: 10px; width: 100%;">
                        <h6 style="text-align: start; width: 100%; font-size: 14px;" class="text-white">Allocations</h6>
                        <div style="display: flex; justify-content: flex-start; align-items: center; margin-top: 10px;">
                            <img src="@/assets/baseAssets/images/s4.png" alt="" width="30px" height="30px" style="margin-right: 5px;">
                            <img src="@/assets/baseAssets/images/s5.png" alt="" width="30px" height="30px" style="margin-right: 5px;">
                        </div>
                    </div>

                    <div style="display: flex; justify-content: flex-start; width: 100%; align-items: center; margin-top: 20px;">
                        <div style="margin-right: 80px;">
                            <h6 style="font-size: 12px;" class="text-white">+15.84%</h6>
                            <p style="font-size: 10px;" class="text-white">Last 7 days</p>
                        </div>

                        <div>
                            <h6 style="font-size: 12px;" class="text-white">$325.14k</h6>
                            <p style="font-size: 10px;" class="text-white">Market Cap</p>
                        </div>
                    </div>
                    
                </div>
            </div>

            <div class="col-md-4 ccarrd">
                <div class="feature-card" style="height: 700px; margin-top: 0px;">
                    <div style="margin-top: -70px;">
                        <!--HEADER-->
                        <div style="display: flex; justify-content: flex-start; align-items: center; flex-direction: column; ">
                            <div class="feature-icon">
                                <img src="@/assets/baseAssets/images/s9.png" alt="" width="60px" height="60px">
                            </div>
                            <div style="margin-left: 5px;">
                                <p class="feature-title pb-15 text-white" style="font-size: 16px;">Polygon Ecosystem Index</p>
                                <p class="feature-des text-white" style="margin-top: -12px; text-align: center;">PECO </p>
                            </div>
                        </div>
                        
                    </div>

                    <div>
                        <p style="text-align: start; margin-top: 5px; font-size: 12px;" class="text-white">Bullish on Polygon ecosystem? Whether you are a polygon native or 100% on Ethereum, our PECO token gives you exposure to the best projects on Polygon</p>
                    </div>

                    <div style="margin-left: 0px; margin-top: 10px; width: 100%;">
                        <h6 style="text-align: start; width: 100%; font-size: 14px;" class="text-white">Allocations</h6>
                        <div style="display: flex; justify-content: flex-start; align-items: center; margin-top: 10px;">
                            <img src="@/assets/baseAssets/images/s6.png" alt="" width="30px" height="30px" style="margin-right: 5px;">
                            <img src="@/assets/baseAssets/images/s7.png" alt="" width="30px" height="30px" style="margin-right: 5px;">
                        </div>
                    </div>

                    <div style="display: flex; justify-content: flex-start; width: 100%; align-items: center; margin-top: 20px;">
                        <div style="margin-right: 80px;">
                            <h6 style="font-size: 12px;" class="text-white">+9.75%</h6>
                            <p style="font-size: 10px;" class="text-white">Last 7 days</p>
                        </div>

                        <div>
                            <h6 style="font-size: 12px;" class="text-white">$857.09k</h6>
                            <p style="font-size: 10px;" class="text-white">Market Cap</p>
                        </div>
                    </div>
                    
                </div>
            </div>

            <div class="col-md-4">
                <div class="feature-card" style="height: 700px; margin-top: 0px;">
                    <div style="margin-top: -70px;">
                        <!--HEADER-->
                        <div style="display: flex; justify-content: flex-start; align-items: center; flex-direction: column; ">
                            <div class="feature-icon">
                                <img src="@/assets/baseAssets/images/omicron.png" alt="" width="60px" height="60px">
                            </div>
                            <div style="margin-left: 5px;">
                                <p class="feature-title pb-15 text-white" style="font-size: 16px;">OMCDEX Token</p>
                                <p class="feature-des text-white" style="margin-top: -12px; text-align: center;">OMC</p>
                            </div>
                        </div>
                        
                    </div>

                    <div>
                        <p style="text-align: start; margin-top: 5px; font-size: 12px;" class="text-white">Solana's inaugural NFT/Meta Verse coin brought to you in a platter. OMC token will expose you to Solana's current innovation in the NFT World. </p>
                    </div>

                    <div style="margin-left: 0px; margin-top: 10px; width: 100%;">
                        <h6 style="text-align: start; width: 100%; font-size: 14px;" class="text-white">Allocations</h6>
                        <div style="display: flex; justify-content: flex-start; align-items: center; margin-top: 10px;">
                            <img src="@/assets/baseAssets/images/sol.png" alt="" width="30px" height="30px" style="margin-right: 5px;">
                            <img src="@/assets/baseAssets/images/omicron.png" alt="" width="30px" height="30px" style="margin-right: 5px;">
                            <img src="@/assets/baseAssets/images/s2.png" alt="" width="30px" height="30px" style="margin-right: 5px;">
                            <img src="@/assets/baseAssets/images/s3.png" alt="" width="30px" height="30px" style="margin-right: 5px;">
                        </div>
                    </div>

                    <div style="display: flex; justify-content: flex-start; width: 100%; align-items: center; margin-top: 20px;">
                        <div style="margin-right: 80px;">
                            <h6 style="font-size: 12px;" class="text-white">+2.38%</h6>
                            <p style="font-size: 10px;" class="text-white">Last 7 days</p>
                        </div>

                        <div>
                            <h6 style="font-size: 12px;" class="text-white">$12.69M</h6>
                            <p style="font-size: 10px;" class="text-white">Market Cap</p>
                        </div>
                    </div>

                    
                </div>
            </div>
            
        </div>

        </div>
    </div>

    <hr>

    <div class="section fugu-section-padding z-index" style="background-color: #120130;">
        <div class="container">
        <div class="row">
            <div class="col-lg-6">
            <div class="fugu-apps-thumb fugu-apps-thumb2" id="rotatethree">
                <img class="wow fadeInUpX" data-wow-delay=".10s" src="@/assets/baseAssets/images/mocup2.png" alt="">
            </div>
            </div>
            <div class="col-lg-6 col-md-10 d-flex align-items-center">
            <div class="fugu-default-content large-content">
                <h2 class="text-white">Trade on the go</h2>
                <p class="text-white">Deposit, withdraw, and trade 24/7 on our mobile apps for Android and iOS, so you can experience professional-level crypto exchange features on the move.</p>
                
                <div class="fugu-icon-list">
                    <ul>
                    <li class="text-white"><img src="@/assets/baseAssets/images/check1.png" style="width:25px;" alt=""> <span style="margin-right:10px"> </span> Exchange your crypto without leaving the app</li>
                    <li class="text-white"><img src="@/assets/baseAssets/images/check1.png" style="width:25px;" alt=""> <span style="margin-right:10px"> </span> Track charts and prices with ease</li>
                    <li class="text-white"><img src="@/assets/baseAssets/images/check1.png" style="width:25px;" alt=""> <span style="margin-right:10px"> </span> Keep your crypto safe from hackers & scammers</li>
                    </ul>
                </div>


            </div>
            </div>
        </div>
        </div>
        <div class="fugu-shape3">
        <img src="@/assets/baseAssets/images/shape2.png" alt="">
        </div>
    </div>
    <!-- End apps section -->

    <hr>

    <div class="fugu-testimonial-section fugu-section-padding2">
        <div class="container">
        <div class="fugu-section-title title-large">
            <h2 class="text-white">Our blog</h2>
        </div>
        
        <div class="row">

            <div class="col-md-6">
            <div class="fugu--blog-wrap fugu--blog-wrap2 wow fadeInUpX" data-wow-delay="0s">
                <div class="fugu--blog-thumb">
                <router-link :to="{name: 'blog1'}">
                    <img src="@/assets/baseAssets/images/warren2.png" alt="">
                </router-link>
                </div>
                <div class="fugu--blog-content">
                <div class="fugu--blog-date">
                    <ul>
                    <li> <a href="" class="text-white">by David Williams, New york times journalist</a> </li>
                    <li> <a href="" class="text-white">December 16,2021</a> </li>
                    </ul>
                </div>
                <div class="fugu--blog-title">
                    <router-link :to="{name: 'blog1'}">
                    <h3 class="text-white">Warren taking a bite of the Metaverse Buffet?</h3>
                    </router-link>
                    <p>The business tycoon having been in around the top richest people in the planet shows no sign of stopping as he looks for more  innovative ways to increase his portfolio.</p>
                    <br>
                </div>
                <router-link class="fugu--readmore-btn" :to="{name: 'blog1'}">Read more <span><img src="@/assets/baseAssets/images/arrow-right.svg" alt=""></span> </router-link>
                </div>
            </div>
            </div>
    
            <div class="col-md-6">
            <div class="fugu--blog-wrap fugu--blog-wrap2 wow fadeInUpX" data-wow-delay="0s">
                <div class="fugu--blog-thumb">
                <router-link :to="{name: 'blog3'}">
                    <img src="@/assets/baseAssets/images/decentraWallet1.png" alt="">
                </router-link>
                </div>
                <div class="fugu--blog-content">
                <div class="fugu--blog-date">
                    <ul>
                    <li> <a href="" class="text-white">by Steve Baker, SurvivorNet Journalist</a> </li>
                    <li> <a href="" class="text-white">December 20,2021</a> </li>
                    </ul>
                </div>
                <div class="fugu--blog-title">
                    <router-link :to="{name: 'blog3'}">
                    <h3 class="text-white">The Decentralized wallet</h3>
                    </router-link>
                    <p>Let’s look at what the centralised structure entails for its users: Centralized exchanges can be used to conduct trades from fiat to cryptocurrency (or vice versa).</p>
                    <br>
                </div>
                <router-link class="fugu--readmore-btn" :to="{name: 'blog3'}">Read more <span><img src="@/assets/baseAssets/images/arrow-right.svg" alt=""></span> </router-link>
                </div>
            </div>
            </div>

        </div>



        <div class="cDiv" style="margin-top: 50px;">
            <router-link class="fugu-btn" :to="{name: 'blog'}" style="color: #ffffff;">See more</router-link>
        </div>
        </div>
    </div>

    <hr>

    <!-- FAQ -->

    <div class="section fugu-section-padding2" style="background-color: #120130;">
    <div class="container" >
      <div class="row">
        <div class="col-lg-5 position-relative">
          <div class="fugu-default-content large-content">
            <h2 class="text-white">Frequently asked questions </h2>
            <p class="text-white">Here are some of the more common questions we get asked about our platform</p>
            <router-link :to="{name: 'contact'}" class="fugu-faq-btn text-white">Don't find the answer? Contact us here</router-link>
          </div>
        </div>
        <div class="col-lg-5 offset-lg-2">
          <div class="fugu-accordion-wrap">
            <div class="fugu-accordion-item">
              <h4 class="text-white">What is an ICO?</h4>
              <p class="text-white">An initial coin offering or initial currency offering is a type of funding using cryptocurrencies. It is often a form of crowdfunding, although a private ICO which does not seek public investment is also possible. Interested investors can buy into the offering and receive a new cryptocurrency token issued by the company.</p>
            </div>
            <div class="fugu-accordion-item">
              <h4 class="text-white">Why should I use OMCDEX?</h4>
              <p class="text-white">OMCDEX Wallet is a software product that gives you access to a wide spectrum of decentralized innovation - buy and store ERC-20 tokens, participate in airdrops and ICOs, collect rare digital art and other collectibles, browse decentralized apps (DApps), shop at stores that accept cryptocurrency, and send crypto to anyone around the world. If you’re looking to simply invest in digital currency, OMCDEX remains the easiest place to buy, sell, and manage your digital currency.</p>
            </div>
            <div class="fugu-accordion-item">
              <h4 class="text-white">How do I protect against losing my crypto assets?</h4>
              <p class="text-white">OMCDEX Wallet is a user-controlled, non-custodial product. The app generates a 12 word recovery phrase which is what gives you, and only you, access to your account to move received funds. OMCDEX will never have access to this seed, meaning that we cannot move funds on your behalf even if you lose access to your recovery phrase. We built an encrypted Google Drive and iCloud recovery phrase backup feature to help protect against seed loss. We encourage you to back up your recovery phrase using both the cloud backup feature as well as writing down your recovery phrase and storing it in a secure location. To access your recovery phrase, tap Settings, and then Recovery Phrase.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>

    <div class="fugu-go-top">
        <img src="@/assets/baseAssets/images/arrow-black-right.svg" alt="">
    </div>

    <hr>


</template>