<template>
    <Navbar/>
    <Blog1Body/>
    <Footer/>
  </template>
  
  <script>
  import { defineComponent } from "vue";
  import Navbar from "@/components/NavbarBody.vue";
  import Blog1Body from "@/components/blog/Blog1Body.vue";
  import Footer from "@/components/FooterBody.vue"; 
  
  export default defineComponent({
    name: "Blog1View",
    components: {
      Navbar,
      Blog1Body,
      Footer
    },
  });
  </script>
  