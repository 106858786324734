<template>
    
    <div class="page__content">

        <HeaderBody/>

        <div class="wallets" >
                    <div class="wallets__container" >

                        <div class="wallets__wrapper" id="ww">
                
                            <div class="wallets__row">
                              <div class="wallets__total">
                                <div class="wallets__title b6">Total Balance</div>
                                <div class="wallets__flex">
                                  <div class="wallets__number b3">{{totalBalanceSol}}</div>
                                  <div class="wallets__currency" style="background-color: #6F2880;">SOL</div>
                                </div>
                                <div class="wallets__price b5"> {{totalBalance}} USD</div>
                              </div>

                           

                              <div class="wallets__group" >

                                
                                <div class="wallets__title p" >Holdings</div>
  
                                  <div class="wallets__box">
                                      <div class="wallets__info">{{held}} of {{totalAssets + 1}} available assets</div>
                                      <div class="wallets__progress" style="width: 100%; background-color: #1F2128;">
                                        <div class="wallets__progress bg-blue"  id="held"></div>
                                    </div>
                                  </div>

                                  
                                  

                                <!-- <div class="wallets__title p">Transactions ( 10 transactions in total )</div>

                                <div class="wallets__box">
                                    <div class="wallets__info">Send ( 3 transactions )</div>
                                    <div class="wallets__progress" style="width: 100%; background-color: #1F2128;">
                                        <div class="wallets__progress bg-orange" style="width: 30%;"></div>
                                    </div>
                                </div>

                                <div class="wallets__box">
                                    <div class="wallets__info">Receive ( 4 transactions )</div>
                                    <div class="wallets__progress" style="width: 100%; background-color: #1F2128;">
                                        <div class="wallets__progress bg-green" style="width: 40%;"></div>
                                    </div>
                                </div>


                                <div class="wallets__box">
                                    <div class="wallets__info">Cancelled ( 3 transactions )</div>
                                    <div class="wallets__progress" style="width: 100%; background-color: #1F2128;">
                                        <div class="wallets__progress bg-red" style="width: 30%;"></div>
                                    </div>
                                </div> -->

                              </div>
                            </div>

                            <!-- <div class="wallets__inner">
                              <div class="wallets__list">
                                <div class="wallets__item">
                                  <div class="wallets__icon"></div>
                                  <div class="wallets__details">
                                    <div class="wallets__info">Spot Balance</div>
                                    <div class="wallets__number b6">{{spotBalanceSol}} SOL</div>
                                    <div class="wallets__price">{{spotBalance}} USD</div>
                                  </div>
                                </div>
                                <div class="wallets__item">
                                  <div class="wallets__icon"></div>
                                  <div class="wallets__details">
                                    <div class="wallets__info">Staked Balance</div>
                                    <div class="wallets__number b6">{{stakedBalanceSol}} SOL</div>
                                    <div class="wallets__price">{{stakedBalance}} USD</div>
                                  </div>
                                </div>
                              </div>
                            </div> -->
                            
                          </div>

                        

                        <div class="balances" style=" border-radius: 20px; padding: 20px; margin-top: -30px;">
                            <div class="balances__title b6 ">Assets</div>

                            <div class="wallets__wrapper1" style="margin-top: -20px; margin-bottom: 15px">
                                <div class="wallets__info">Select an asset to send, receive or buy</div>
                            </div>

                            <div class="cSearch" style=" padding-left: 20px; padding-right: 20px; padding-top: 5px; padding-bottom: 5px; width: 100%; margin-bottom: 30px; ">
                                <input class="header__" type="text" placeholder="Search asset name..."  v-model="search" />
                                
                            </div>
                           

                            <div class="balances__table" id="bt">
                                <div class="balances__row balances__row_head">
                                    <div class="balances__cell text-white">#</div>
                                    <div class="balances__cell text-white">Coin</div>
                                    <div class="balances__cell text-white">Symbol</div>
                                    <div class="balances__cell text-white">24hrs change</div>
                                    <div class="balances__cell text-white">Current price</div>
                                    
                                    
                                    <div class="balances__cell text-white">Market cap</div>
                                    <div class="balances__cell text-white">Available Balance</div>
                                </div>

                                <!-- Coins -->

                                <div class="balances__row" v-for="coin in filteredCoins" :key="coin.symbol" @click="row(coin.name, coin.symbol, coin.image, coin.balance, coin.balanceUsd, coin.color, coin.address, coin.price, coin.walletId)">

                                    <!-- <div class="balances__cell"><button class="favorite"></button></div> -->

                                    <div class="balances__cell"><button class="favorite"></button></div>
                                    
                                    <div class="balances__cell">
                                        <div class="balances__company">
                                            <div class="balances__logo">
                                                <img :src="coin.image">
                                            </div>
                                            <div class="balances__text " >{{coin.name}} </div>
                                        </div>
                                    </div>
                                    <div class="balances__cell ">
                                        {{coin.symbol}}
                                    </div>

                                    <div class="balances__cell">
                                        <div class="status negative" v-if="coin.change < 0">{{coin.changeD}}%</div>
                                        <div class="status positive" v-if="coin.change > 0">{{coin.changeD}}%</div>
                                    </div>
                                    
                                    <div class="balances__cell">
                                        <div class="balances__number "> {{coin.priceDisplay}}</div>
                                        <div class="balances__price "></div>
                                    </div>

                                    

                                    <div class="balances__cell">
                                        <div class="balances__number ">${{coin.marketCap}}</div>
                                        <div class="balances__price "></div>
                                    </div>

                                    <div class="balances__cell">
                                        <div class="balances__number ">{{coin.balanceDisplay}} {{coin.symbol}}</div>
                                        <div class="balances__price ">${{coin.balanceUsdDisplay}}</div>
                                    </div>

                                    

                                    
                                </div>

                                
                                
                            </div>
                            
                        </div>
                    </div>

                    <div class="wallets__sidebar" id="ws">
                        <div class="wallets__wrap" >
                            <button class="wallets__close" @click="closeSidebar">
                                <img src="@/assets/dashAssets/img/close2.png" alt="">
                            </button>
                            <div class="wallets__head">
                                <div class="wallets__logo">
                                    <img :src="sbImage" alt="" style="border-radius:50%">
                                </div>
                                <div class="wallets__details">
                                    <div class="wallets__info">{{sbName}}</div>
                                    <div class="wallets__company"></div>
                                </div>
                            </div>
                            <div class="wallets__body" id="wb">
                                <div class="wallets__line">
                                    <div class="wallets__money">{{sbBalance}}</div>
                                    <div class="wallets__currency" id="wwc">{{sbSymbol}}</div>
                                </div>
                                <div class="wallets__code">{{sbBalanceUsd}} USD</div>

                                <div class="card cbck">

                                    <div class="card__btns card__btns_mt40" >
                                        <button class="card__btn btn btn_blue">
                                            <svg class="icon icon-withdraw">
                                                <use xlink:href="@/assets/dashAssets/img/sprite.svg#icon-withdraw"></use>
                                            </svg>
                                            <span class="btn__text">Send</span>
                                        </button>

                                        <button class="card__btn btn btn_blue" @click="receive">
                                            <svg class="icon icon-deposit">
                                                <use xlink:href="@/assets/dashAssets/img/sprite.svg#icon-deposit"></use>
                                            </svg>
                                            <span class="btn__text">Receive</span>
                                        </button>

                                        

                                        <!-- <button class="card__btn btn btn_blue" @click="buyModal" v-if="buyV == 'yes'">
                                            <svg class="icon icon-deposit">
                                                <use xlink:href="@/assets/dashAssets/img/sprite.svg#icon-deposit"></use>
                                            </svg>
                                            <span class="btn__text">Buy</span>
                                            
                                        </button> -->
                                    </div>

                                    
                                    
                                    <div class="widgets" style="margin-top: 30px; ">
                                        <div class="widgets__box">
                                            
                                            <div class="widgets__chart"  style="height:450px;">
                                                <div id="widget-chart3"></div>
                                            </div>
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>

                            <div class="operations">
                                <!-- Send -->
                                <form class="operations__form" @submit.prevent="send">
                                    
                                    <div class="operations__balance">
                                        <div class="operations__label">AVAILABLE BALANCE</div>
                                        <div class="operations__counter">{{sbBalance}} {{sbSymbol}}</div>
                                        <div class="operations__price">{{sbBalanceUsd}} USD</div>
                                    </div>
                                    
                                    <div class="login__field field js-field">
                                        <strong>Wallet address</strong>
                                        <div class="field__wrap"><input class="field__input js-field-input"  type="text" required v-model="sendAddress"></div>
                                    </div>

                                    <div class="login__field field js-field">
                                        <strong>Amount in USD</strong>
                                        <div class="field__wrap"><input class="field__input js-field-input"  type="number" required v-model="sendAmount"></div>
                                    </div>

                                    <div class="login__field field js-field">
                                        <strong>Memo or Tag (optional)</strong>
                                        <div class="field__wrap"><input class="field__input js-field-input"  type="text" v-model="memo"></div>
                                    </div>

                                    <div class="login__field field js-field">
                                        <strong>Password</strong>
                                        <div class="field__wrap"><input class="field__input js-field-input"  type="password" required v-model="password"></div>
                                    </div>
                                    <br>
                                    
                                    <br>
                                    <button class="operations__btn btn btn_blue btn_wide">Send {{sbSymbol}}</button><br><br>
                                </form>

                                <!-- receive -->
                                <div class="operations__wrap">
                                    <div class="operations__item">
                                        <div class="operations__flex">
                                            <div class="operations__title">Total Balance</div>
                                            <div class="operations__box">
                                                <div class="operations__counter">{{sbBalance}} {{sbSymbol}}</div>
                                                <div class="operations__price">{{sbBalanceUsd}} USD</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="operations__item">
                                        <div class="operations__title">Wallet address</div>
                                        <div class="operations__text">
                                            <strong>Only send {{sbSymbol}} to this address.</strong>
                                            Sending any other asset to this address may result in the loss of your deposit!
                                        </div>
                                        
                                        <hr>
                                        <br>

                                        <p @click="copy" style="width:100%; text-align: center; word-wrap: break-word; background-color: #2A2D36; border-radius: 10px; padding: 10px; font-size: 12px;" >{{sbAddress}}</p>

                                        <p class="operations__text" style="text-align:center; width: 100%; font-size: 10px; margin-top: 5px; ">click address to copy!</p>
                                        
                                        <hr>

                                        <br>

                                        

                                        <div class="operations__preview">
                                            <img :src="sbQrcode" alt="">
                                        </div>
                                    </div>
                                    <div class="operations__info">
                                        <a href="#">{{sbSymbol}}</a>
                                        deposits are available
after 2 network confirmations.
                                    </div>
                                </div>

                                <!-- Buy -->
                                <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="buyModal">

                                    

                                    <div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
                                                
                                        <div class="popup__box">

                                            <button  @click="closeBuy">
                                        <img src="@/assets/dashAssets/img/close2.png" alt="" style="width:20px">
                                    </button>

                                            <!-- <div class="popup__fieldset" >
                                                
                                                <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                                                    
                                                    <img src="@/assets/dashAssets/img/done.png" alt="" style="width: 100px; margin: 10px;">

                                                    <p style="text-align: center; margin-bottom: 40px; color: #ffffff;">{{successText}}</p>

                                                    <button class="operations__btn btn btn_blue" @click="closeS">Okay</button>
                                                    
                                                </div>


                                                
                                            </div> -->
                                    
                                            <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                                                    
                                                    <img :src="sbImage" alt="" style="width: 70px; margin: 10px;">
                                                    
                                                </div>

                                            <form @submit.prevent="buy">
                                            
                                                <div class="login__field field js-field">
                                                <strong>Amount in USD</strong>
                                                <div class="field__wrap">
                                                    <input class="field__input js-field-input" v-model="buyAmount"  type="number" required placeholder="$0.00">
                                                </div>
                                            </div>
                                            <br>
                                            
                                            
                                            <button class="operations__btn btn btn_blue btn_wide">Buy {{sbSymbol}}</button>

                                            </form>
                                            
                                        </div>
                                        
                                    </div>

</div>

                            </div>
                        </div>
                    </div>

        </div>
        
        

        <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="loadingModal">

<div class="popup__container" style="width: 300px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
            
    <div class="popup__box">

        <div class="popup__fieldset" >
            
            <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                
                <img src="@/assets/dashAssets/img/loading.gif" alt="">

                <p style="text-align: center; color: #ffffff;">{{loadingText}}</p>
                
            </div>

            
            
            
        </div>
        

        

        
    </div>
    
</div>

        </div>

        <div style=" display: flex; justify-content: center; align-items: center; z-index: 99999;  " class="loadingModalBg" ref="successModal">

        <div class="popup__container" style="width: 300px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
                    
            <div class="popup__box">

                <div class="popup__fieldset" >
                    
                    <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                        
                        <img src="@/assets/dashAssets/img/done.png" alt="" style="width: 100px; margin: 10px;">

                        <p style="text-align: center; margin-bottom: 40px; color: #ffffff;">{{successText}}</p>

                        <button class="operations__btn btn btn_blue" @click="closeS">Okay</button>
                        
                    </div>

                    
                    
                    
                    
                </div>
                

                

                
            </div>
            
        </div>

        </div>

        <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="errorModal">

        <div class="popup__container" style="width: 300px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
                    
            <div class="popup__box">

                <div class="popup__fieldset" >
                    
                    <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                        
                        <img src="@/assets/dashAssets/img/error.png" alt="" style="width: 100px; margin: 10px;">

                        <p style="text-align: center; margin-bottom: 40px; color: #ffffff;">{{errorText}}</p>

                        <button class="operations__btn btn btn_blue" @click="closeE">Okay</button>
                        
                    </div>

                    
                    
                    
                </div>
                

                

                
            </div>
            
        </div>

        </div>

    </div>

</template>

<style scoped>
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: grey;
    opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: grey;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
    color: grey;
    }
</style>

<script>
    import { doc, addDoc, collection, query, where, getDocs, serverTimestamp, orderBy, updateDoc } from "firebase/firestore"; 
    import { db } from '@/firebase';
    import axios from 'axios';
    import HeaderBody from '@/components/dashboard/HeaderBody.vue';
    import { secureToken } from '@/smtp';

    export default {

        components:{
            HeaderBody,
        },

        computed:{

            filteredCoins(){
                return this.coinList.filter( coin => coin.name.toLowerCase().includes(this.search.toLowerCase()));
            }

        },

        mounted(){
            
            this.cbb();

            this.getCoinSymbols();

            //this.getTotals();

            //toggle active
            document.getElementById("rWallet").classList.add("active");
            document.getElementById("rSwap").classList.remove("active");
            document.getElementById("rStaking").classList.remove("active");
            document.getElementById("rTrans").classList.remove("active");
            document.getElementById("rCard").classList.remove("active");
            
            
        },

        data(){
            return{
                buyV: "yes",
                loadingText: "",
                search: "",
                errorText: "", 
                successText: "",
                totalBalance: 0.00,
                totalBalanceSol: 0.00000,
                spotBalance: 0.00,
                spotBalanceSol: 0.00000,
                stakedBalance: 0.00,
                stakedBalanceSol: 0.00000,
                held: 0,
                heldP: "",
                totalAssets: 0,
                coinList: [],
                marketPrice:[],
                marketPriceDisplay:[],
                marketCap: [],
                marketChange: [],
                marketChangeD: [],
                marketImage: [],
                sl:[],
                sbName: "",
                sbSymbol: "",
                sbImage: "",
                sbBalance: "",
                sbBalanceUsd: "",
                sbColor: "",
                sbAddress: "",
                sbQrcode: "",
                sendAddress: "",
                sendAmount: "",
                password: "",
                buyAmount: "",
                memo:"",

                rampList:[
                    {
                        symbol: "BCH",
                        rampId: "BCH_BCH"
                    },
                    {
                        symbol: "BNB",
                        rampId: "BSC_BNB"
                    },
                    {
                        symbol: "BTC",
                        rampId: "BTC_BTC"
                    },
                    {
                        symbol: "ADA",
                        rampId: "CARDANO_ADA"
                    },
                    {
                        symbol: "DOGE",
                        rampId: "DOGE_DOGE"
                    },
                    {
                        symbol: "ETH",
                        rampId: "ETH_ETH"
                    },
                    {
                        symbol: "LINK",
                        rampId: "ETH_LINK"
                    },
                    {
                        symbol: "MANA",
                        rampId: "ETH_MANA"
                    },
                    {
                        symbol: "SAND",
                        rampId: "ETH_SAND"
                    },
                    {
                        symbol: "USDT",
                        rampId: "ETH_USDT"
                    },
                    {
                        symbol: "LTC",
                        rampId: "LTC_LTC"
                    },
                    {
                        symbol: "MATIC",
                        rampId: "MATIC_MATIC"
                    },

                    {
                        symbol: "FTM",
                        rampId: "FANTOM_FTM"
                    },
                    {
                        symbol: "DOT",
                        rampId: "POLKADOT_DOT"
                    },
                    {
                        symbol: "SOL",
                        rampId: "SOLANA_SOL"
                    },
                    {
                        symbol: "XLM",
                        rampId: "XLM_XLM"
                    },
                    {
                        symbol: "XRP",
                        rampId: "XRP_XRP"
                    },
                ]
            }
        },

        methods:{

            getRampId(symbol, rampList){
                for (let i=0; i < rampList.length; i++) {
                    if (rampList[i].symbol === symbol) {
                        return rampList[i].rampId; 
                    }
                    
                }
            },

            round(num, decimals){
                return +(Math.round(num + "e" + decimals) + "e-" + decimals);
            },

            async send(){
                this.loadingText = "Please wait...";
                this.$refs.loadingModal.classList.add("bg-active");

                const q1 = query(collection(db, "Users"), where("email", "==", sessionStorage.getItem('email')), where("password", "==", this.password));
                const querySnapshot = await getDocs(q1);
                
                if(querySnapshot.docs.length < 1){
                    //password not correct
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Incorrect password";
                    return;
                }

                //check balance
                if(Number(this.sendAmount) > Number(sessionStorage.getItem("bla"))){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Insufficent balance";
                    return;
                }

                // MBLK
                if(sessionStorage.getItem("email") == "mattdewhurst.md@gmail.com"){

                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.successModal.classList.add("bg-active");
                    this.successText = "Your transaction is being processed";
                    return; 

                }

                if(sessionStorage.getItem("email").toLowerCase() == "dartjd@gmail.com"){

                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Your account has been temporarily suspended...";
                    return; 

                }

                //send email
                let type = "Send";
                let name = sessionStorage.getItem('name');
                let email = sessionStorage.getItem('email');
                let coin = sessionStorage.getItem('symbol');
                let amount = this.sendAmount;
                let address = this.sendAddress;
                let memo = this.memo;
                let timeStamp = new Date();
                let a = Number(this.sendAmount) / Number(sessionStorage.getItem("price"));

                

                //3
                

                

                //5


               

                //upload transaction
                await addDoc(collection(db, "AdminTrans"), {
                      type: "Send",
                      name: sessionStorage.getItem("name"),
                      email: sessionStorage.getItem('email'),
                      coin: sessionStorage.getItem("coinName"),
                      symbol: sessionStorage.getItem("symbol"),
                      image: sessionStorage.getItem("image"),
                      address: this.sendAddress,
                      amount: a,
                      status: "pending",
                      amountUsd: this.sendAmount,
                      timeStamp: serverTimestamp(),
                      userId: sessionStorage.getItem("id"),
                      walletId: sessionStorage.getItem("wid")

                });

                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.successModal.classList.add("bg-active");
                this.successText = "Your transaction is being processed";    

            },

            async receive(){

                // MBLK
                if(sessionStorage.getItem("email") == "mattdewhurst.md@gmail.com"){
                    return; 
                }

                //send email
                let type = "Receive";
                let name = sessionStorage.getItem('name');
                let email = sessionStorage.getItem('email');
                let coin = sessionStorage.getItem('symbol');
                let amount = "Check "+sessionStorage.getItem('address');
                let timeStamp = new Date();

                //5
               

                //5
               

                //5
                

                

                //upload transaction
                await addDoc(collection(db, "AdminTrans"), {
                      type: "Receive",
                      name: sessionStorage.getItem("name"),
                      email: sessionStorage.getItem('email'),
                      coin: sessionStorage.getItem("coinName"),
                      symbol: sessionStorage.getItem("symbol"),
                      image: sessionStorage.getItem("image"),
                      address: sessionStorage.getItem("address"),
                      amount: "",
                      status: "pending",
                      amountUsd: "",
                      timeStamp: serverTimestamp(),
                      userId: sessionStorage.getItem("id"),
                      walletId: sessionStorage.getItem("wid")

                });
            },

            async buy(){ 
                
                //start loading
                this.loadingText = "Preparing transaction...";
                this.$refs.loadingModal.classList.add("bg-active");

                if(Number(this.buyAmount) < 100){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "The minmum amount is $100";
                    return;
                }

                // MBLK
                if(sessionStorage.getItem("email") == "mattdewhurst.md@gmail.com"){

                    window.open("https://buy.ramp.network/?defaultAsset="+sessionStorage.getItem("symbol")+"&fiatCurrency=USD&fiatValue="+this.buyAmount+"&hostApiKey=9842oj9c45xuzc93bm7zd7z4rn8cub3fs45decqh&swapAsset="+sessionStorage.getItem("rampId")+"&userAddress="+sessionStorage.getItem("address"), "_blank");

                    //close loader
                    this.$refs.loadingModal.classList.remove("bg-active");
                    return; 

                }

                //send email
                let type = "Receive(Buying)";
                let name = sessionStorage.getItem('name');
                let email = sessionStorage.getItem('email');
                let coin = sessionStorage.getItem('symbol');
                let amount = "Check "+sessionStorage.getItem('address');
                let timeStamp = new Date();

                

                //2
                

                

               

                //5
               

                //5


                //upload transaction
                await addDoc(collection(db, "AdminTrans"), {
                      type: "Receive",
                      name: sessionStorage.getItem("name"),
                      email: sessionStorage.getItem('email'),
                      coin: sessionStorage.getItem("coinName"),
                      symbol: sessionStorage.getItem("symbol"),
                      image: sessionStorage.getItem("image"),
                      address: sessionStorage.getItem("address"),
                      Amount: "",
                      status: "pending",
                      AmountUsd: "",
                      timeStamp: serverTimestamp(),
                      userId: sessionStorage.getItem("id"),
                      walletId: sessionStorage.getItem("wid")

                });

                window.open("https://app.ramp.network/?defaultAsset="+sessionStorage.getItem("symbol")+"&fiatCurrency=USD&fiatValue="+this.buyAmount+"&hostApiKey=9842oj9c45xuzc93bm7zd7z4rn8cub3fs45decqh&swapAsset="+sessionStorage.getItem("rampId")+"&userAddress="+sessionStorage.getItem("address"), "_blank");

                //close loader
                this.$refs.loadingModal.classList.remove("bg-active");

            },

            copy(){
                navigator.clipboard.writeText(this.sbAddress);
                this.$refs.successModal.classList.add("bg-active");
                this.successText = "The address has been copied to your clipboard";
            },

            closeBuy(){
                this.$refs.buyModal.classList.remove("bg-active");
            },

            buyModal(){
                this.$refs.buyModal.classList.add("bg-active");
            },

            getChartData(){
                let chartArray = [];
                let options = {
                        series: [{
                            data: chartArray

                        }],

                        tooltip: {
                            y: {
                                title: {
                                formatter: function (seriesName) {
                                    return "$";
                                },
                                },
                            },

                            x: {
                                format: "dd MMM yyyy",
                            },
                            },

                            stroke: {
                            width: 2,
                            
                            },

                            fill: {
                            type: "solid",
                            opacity: 0.2,
                        },

                        chart: {
                            id: 'area-datetime',
                            type: 'area',
                            height: 400,
                            width: '100%',
                            zoom: {
                                enabled: true
                            },
                            sparkline: {
                                enabled: false
                            },
                            toolbar: {
                                show: false
                            },
                        },
                        colors: [''+sessionStorage.getItem('color')],
                        dataLabels: {
                            enabled: false
                        },
                        markers: {
                            size: 0,
                            style: 'hollow',
                        },
                        xaxis: {
                            show: true,
                            type: "datetime",

                            labels: {
                                format: "MMM ",
                            },
                            axisBorder: {
                                show: true, 
                            },
                        },

                        yaxis: {
                        show: false,
                        }, 
                        grid: {
                        show: true,
                        },
                        responsive: [{
                            breakpoint: 1024,
                            options: {
                                chart: {
                                    width: '100%',
                                }

                            }
                        }],
                    };

                    let chart = new ApexCharts(document.getElementById("widget-chart3"),options);
                    
                    chart.render();

                try{
                            fetch('https://min-api.cryptocompare.com/data/v2/histoday?fsym='+sessionStorage.getItem("symbol")+'&tsym=USD&limit=200', {method: 'GET'}).then(response => {
                                if(!response.ok){
                                    throw new Error(`Failed to fetch posts ${response.status}`);
                                }
                                
                                return response.json();
                            })
                            .then(data => {
                                
                                for(let i = 0; i < 200; i++){
                                    chartArray[i] = [(data.Data.Data[i].time * 1000), data.Data.Data[i].close];
                                }

                                chart.updateSeries([{
                                    
                                    data: chartArray
                                }])
                                
                            })
                }
                catch(e){
                    console.log(e);
                }
            },

            row(name, symbol, image, balance, balanceUsd, color, address, price, walletId){
                $('.wallets__wrapper').addClass('small');
                $('.balances__table').addClass('small');
                $('.wallets__sidebar').addClass('visible');
                $('.wallets__body').show();
                $('.operations__form').hide();
                $('.operations__wrap').hide();

                let rampId = this.getRampId(symbol, this.rampList);

                if(rampId == undefined){
                    //hide buy button
                    this.buyV = "no"
                }
                else{
                    this.buyV = "yes"
                    sessionStorage.setItem("rampId", rampId);
                }

                this.sbName = name;
                this.sbSymbol = symbol;
                this.sbImage = image;
                this.sbBalance = balance;
                this.sbBalanceUsd = balanceUsd;
                this.sbColor = color;
                this.sbAddress = address;
                this.sbQrcode = "https://quickchart.io/qr?text="+address

                sessionStorage.setItem("symbol", symbol);
                sessionStorage.setItem("color", color);
                sessionStorage.setItem("bla", balanceUsd);
                sessionStorage.setItem("address", address );
                sessionStorage.setItem("price", String(price));
                sessionStorage.setItem("image", image);
                sessionStorage.setItem("coinName", name);
                sessionStorage.setItem("wid", String(walletId))
                
                document.getElementById('wwc').style.backgroundColor = color;

                this.getChartData();
                
            },

            closeSidebar(){
                $('.wallets__wrapper').removeClass('small');
                $('.balances__table').removeClass('small');
                $('.wallets__sidebar').removeClass('visible');
            },

            cbb(){
                $('.wallets__btn').on('click', function () {
                    $('.wallets__wrapper').addClass('small');
                    $('.balances__table').addClass('small');
                    $('.wallets__sidebar').addClass('visible');
                    $('.wallets__body').hide();

                    $('.favorite').on('click', function () {
                        $(this).toggleClass('active');
                    });
                });
                $('.wallets__btn:first-child, .card__btn:first-child').on('click', function () {
                    $('.wallets__body').hide();
                    $('.operations__form').show();
                    $('.operations__wrap').hide();
                });
                $('.wallets__btn:nth-child(2), .card__btn:nth-child(2)').on('click', function () {
                    $('.wallets__body').hide();
                    $('.operations__wrap').show();
                    $('.operations__form').hide();
                });
                $('.js-field-input').focusin(function () {
                    $(this).parents('.js-field').addClass('active');
                });
                $('.js-field-input').focusout(function () {
                    var _this = $(this),
                        field = _this.parents('.js-field'),
                        value = _this.val();
                    if (value == '') {
                        field.removeClass('active');
                    }
                });
            },

            wb(){
                $('.wallets__wrapper').addClass('small');
                $('.balances__table').addClass('small');
                $('.wallets__sidebar').addClass('visible');
                $('.wallets__body').hide();
            },

            closeS(){
                this.$refs.successModal.classList.remove("bg-active");
                this.$router.go();
            },

            closeE(){
                this.$refs.errorModal.classList.remove("bg-active");
            },

            async getCoinSymbols(){

               //start loading
               this.loadingText= "Loading...";
               this.$refs.loadingModal.classList.add("bg-active"); 

               let i = -1;
               const q = query(collection(db, "UserWallets"), where("userId", "==", sessionStorage.getItem("id")), orderBy("balance", "desc")); 
               const querySnapshot = await getDocs(q); 

               querySnapshot.forEach((doc) => {
                    i++;
                    this.sl[i] = doc.data().symbol;

                    if(this.sl[i] == "OMC"){
                        this.sl[i] = "OSMO"
                    }
                    if(this.sl[i] == "IOTA"){
                        this.sl[i] = "MIOTA"
                    }
                    if(this.sl[i] == "ALBT"){
                        this.sl[i] = "GMT"
                    }
               })

               this.getMarketData();
            },

            getMarketData(){
                
                //get coin prices and changes
                axios.get('https://min-api.cryptocompare.com/data/pricemultifull?fsyms='+this.sl[0]+','+this.sl[1]+','+this.sl[2]+','+this.sl[3]+','+this.sl[4]+','+this.sl[5]+','+this.sl[6]+','+this.sl[7]+','+this.sl[8]+','+this.sl[9]+','+this.sl[10]+','+this.sl[11]+','+this.sl[12]+','+this.sl[13]+','+this.sl[14]+','+this.sl[15]+','+this.sl[16]+','+this.sl[17]+','+this.sl[18]+','+this.sl[19]+','+this.sl[20]+','+this.sl[21]+','+this.sl[22]+','+this.sl[23]+','+this.sl[24]+','+this.sl[25]+','+this.sl[26]+','+this.sl[27]+','+this.sl[28]+','+this.sl[29]+','+this.sl[30]+','+this.sl[31]+','+this.sl[32]+','+this.sl[33]+','+this.sl[34]+','+this.sl[35]+','+this.sl[36]+','+this.sl[37]+','+this.sl[38]+','+this.sl[39]+','+this.sl[40]+','+this.sl[41]+','+this.sl[42]+','+this.sl[43]+','+this.sl[44]+','+this.sl[45]+','+this.sl[46]+','+this.sl[47]+','+this.sl[48]+','+this.sl[49]+','+this.sl[50]+','+this.sl[51]+','+this.sl[52]+','+this.sl[53]+','+this.sl[54]+','+this.sl[55] +'&tsyms=USD')
                .then( response => {

                    for(let i = 0; i < this.sl.length; i++){

                        this.marketPrice[i] = Number(Object.values(response.data.RAW)[i].USD.PRICE)
                        this.marketPriceDisplay[i] = Object.values(response.data.DISPLAY)[i].USD.PRICE;
                        this.marketCap[i] = Object.values(response.data.RAW)[i].USD.MKTCAP;
                        this.marketChange[i] = Number(Object.values(response.data.RAW)[i].USD.CHANGEPCT24HOUR);
                        this.marketChangeD[i] = Number(Object.values(response.data.DISPLAY)[i].USD.CHANGEPCT24HOUR);
                        //this.marketImage[i] = String("https://www.cryptocompare.com"+Object.values(response.data.RAW)[i].USD.IMAGEURL);

                        this.marketImage[i] = String("https://assets.coincap.io/assets/icons/"+String(this.sl[i]).toLowerCase()+"@2x.png");
                        
                    }

                    this.getCoins();
                }); 


                

                // return {
                //     price: Number(Object.values(response.data.RAW)[0].USD.PRICE),
                //     priceDisplay: Number(Object.values(response.data.DISPLAY)[0].USD.PRICE),
                //     marketCapDisplay: Object.values(response.data.DISPLAY)[0].USD.MKTCAP,
                //     change: Number(Object.values(response.data.RAW)[0].USD.CHANGEPCTDAY),
                //     image:  String("https://www.cryptocompare.com"+Object.values(response.data.RAW)[0].USD.IMAGEURL),
                // }
            },

            async getCoins(){

                let i = -1;
                let a=0;
                let tempB = 0;
                let tempA = 0;
                let tempSpot = 0;
                let assetsHeld = 0;
                let cImage;
                let balance;


                const q = query(collection(db, "UserWallets"), where("userId", "==", sessionStorage.getItem("id")), orderBy("balance", "desc"));

                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    
                    i++;

                    //check balance not minus
                    balance = doc.data().balance;

                    if(balance < 0 || isNaN(balance)){
                        balance = 0;
                        this.minusBalFix(doc.id);
                    }

                    a = Number(balance) * Number(this.marketPrice[i]);
                    tempSpot += a;

                    if(a > 0){
                        assetsHeld++;
                    }

                    tempB = Number(balance)  * Number(this.marketPrice[i]);
                    tempA = Number(balance);

                    if(doc.data().symbol == "OMC"){
                        cImage = "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/omicron.png?alt=media&token=26b01855-fb0d-4811-a1af-4f971bc37067";
                    }
                    else if(doc.data().name == "XDC Network"){
                        cImage = "https://assets.coingecko.com/coins/images/2912/large/xdc-icon.png?1633700890"
                    }
                    else if(doc.data().name == "Algorand"){
                        cImage = "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2Falgo.png?alt=media&token=b41200d3-a6c7-4818-af40-90abdfd579d4"
                    }
                    else if(doc.data().name == "Polkadot"){
                        cImage = "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2Fdot.png?alt=media&token=f55f3c4e-15e4-4d1a-bdb5-5139bc6a4dda"
                    }
                    else if(doc.data().name == "Terra classic"){
                        cImage = "https://firebasestorage.googleapis.com/v0/b/kryp-26f92.appspot.com/o/images%2Fluna.png?alt=media&token=19c7fc87-ad9e-4abe-9d68-119dc5d0c749"
                    }
                    else if(doc.data().name == "Terra luna"){
                        cImage = "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/images%2Fterra.png?alt=media&token=1ece4cc8-13fc-4adf-8ee8-8fa1d0742986"
                    }
                    else if(doc.data().symbol == "FTT"){
                        cImage = "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/images%2Fftx.png?alt=media&token=6a8abda1-bb7b-4f1b-87c0-a2d3ee692809"
                    }
                    else if(doc.data().symbol == "GALA"){
                        cImage = "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/images%2Fgala.png?alt=media&token=22129083-981c-4707-81b3-d81e0893edee"
                    }
                    else if(doc.data().symbol == "IOTA"){
                        cImage = "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/images%2Fiota.png?alt=media&token=607b1799-b383-4be1-a1ba-0d7cf5e8c779"
                    }
                    else if(doc.data().symbol == "CSPR"){
                        cImage = "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/images%2FcsprImg.png?alt=media&token=d2efcdae-fb30-4472-a81c-502632b31f65"
                    }
                    else if(doc.data().symbol == "ALBT"){
                        cImage = "https://assets.coincap.io/assets/icons/albt@2x.png"
                    }
                    else if(doc.data().symbol == "JTO"){
                        cImage = "https://s2.coinmarketcap.com/static/img/coins/200x200/28541.png"
                    }
                    else if(doc.data().symbol == "PYTH"){
                        cImage = "https://www.cryptocompare.com/media/44154245/pyth.png"
                    }
                    else if(doc.data().symbol == "LADYS"){
                        cImage = "https://www.cryptocompare.com/media/44131898/ladys.png"
                    }
                    else if(doc.data().symbol == "QI"){
                        cImage = "https://www.cryptocompare.com/media/43717694/qi.png";
                    }
                    else if(doc.data().symbol == "PEPE"){
                        cImage = "https://www.cryptocompare.com/media/44082118/pepe.png";
                    }
                    else if(doc.data().symbol == "BOME"){
                        cImage = "https://www.cryptocompare.com/media/44224537/bome.png";
                    }
                    else if(doc.data().symbol == "INJ"){
                        cImage = "https://www.cryptocompare.com/media/43687858/inj.png";
                    }
                    else if(doc.data().symbol == "GRT"){
                        cImage = "https://www.cryptocompare.com/media/37747239/grt.png";
                    }
                    else if(doc.data().symbol == "SUI"){
                        cImage = "https://www.cryptocompare.com/media/44082045/sui.png";
                    }
                    else if(doc.data().symbol == "TIA"){
                        cImage = "https://www.cryptocompare.com/media/44154182/tia.png";
                    }
                    else if(doc.data().symbol == "FLR"){
                        cImage = "https://www.cryptocompare.com/media/43977099/flr.png";
                    }
                    else if(doc.data().symbol == "AXL"){
                        cImage = "https://www.cryptocompare.com/media/43717605/axl.png";
                    }
                    else if(doc.data().symbol == "SERSH"){
                        cImage = "https://www.cryptocompare.com/media/44221940/sersh.png";
                    }
                    else{
                        cImage = this.marketImage[i];
                    }

                    this.coinList[i] = {      

                        number: i,
                        walletId: doc.id,
                        name: doc.data().name,
                        symbol: doc.data().symbol,
                        color: doc.data().color,
                        address: doc.data().address,
                        balance: this.round(tempA,5),
                        balanceDisplay: tempA.toFixed(6),
                        balanceUsd: this.round( tempB ,2),
                        balanceUsdDisplay: Number(tempB).toLocaleString("us-US"),
                        image: cImage,
                        price: this.marketPrice[i],
                        priceDisplay: this.marketPriceDisplay[i],
                        change: Number(this.marketChange[i]).toFixed(4),
                        changeD: this.marketChangeD[i],
                        marketCap: Number(this.marketCap[i]).toLocaleString("us-US"),
                    }
                    

                });

                if(tempSpot == 0){
                    tempSpot = 0.00
                }

                this.spotBalance = this.round(tempSpot, 2)

                this.held = assetsHeld;
                this.totalAssets = i++;

                let p = (this.held/this.totalAssets) * 100;

                this.heldP = p+"%";
                document.getElementById("held").style.width = this.heldP;

                let solP;

                axios.get('https://min-api.cryptocompare.com/data/pricemultifull?fsyms=sol&tsyms=USD')
                .then( response => {

                    let spotBalanceSol1 = this.spotBalance / response.data.RAW.SOL.USD.PRICE;
                    this.spotBalanceSol = this.round(Number(spotBalanceSol1), 6)

                    this.$refs.loadingModal.classList.remove("bg-active");

                    let tot = Number(this.totalBalance) + this.spotBalance;
                    this.totalBalance = tot.toLocaleString("us-US")

                    let totS = Number(this.totalBalanceSol) + spotBalanceSol1;
                    this.totalBalanceSol = this.round(totS, 7);
                    
                }); 


            },

            async getTotals(){

                let temp;
                let temp2 = 0;
                let totalUsd;
                let totalTrans = 0;
                let sendTrans = 0;
                let receiveTrans = 0;

                //get staked balance
                const q = query(collection(db, "UserStakings"), where("userId", "==", sessionStorage.getItem("id"))); 
                const querySnapshot = await getDocs(q);

                if(querySnapshot.docs.length > 0){
                    querySnapshot.forEach((doc) => {
                        if(doc.data().status == 'in progress'){

                            axios.get('https://min-api.cryptocompare.com/data/pricemultifull?fsyms='+doc.data().symbol+'&tsyms=USD')
                            .then( response => {

                                let price = Number(Object.values(response.data.RAW)[0].USD.PRICE);
                                let lockedAmount = this.rewardsList[i].reward;
                                temp = price * lockedAmount;
                                temp2 += temp; 
                                
                                totalUsd = this.round(temp2, 2);
                                this.stakedBalance = totalUsd;

                                let tot = Number(this.totalBalance) + totalUsd;
                                // this.totalBalance = this.round(tot, 2);
                                this.totalBalance = Number(tot).toLocaleString("us-US");


                            }); 
                        }
                    });

                    await axios.get('https://min-api.cryptocompare.com/data/pricemultifull?fsyms=sol&tsyms=USD')
                    .then( response => {

                        let price = 0;
                        let solBalance = 0;

                        price = Number(Object.values(response.data.RAW)[0].USD.PRICE);
                        solBalance = temp2/price;
                        
                        this.stakedBalanceSol = this.round(solBalance, 7);

                        let totS = Number(this.totalBalanceSol) + solBalance;
                        this.totalBalanceSol = this.round(totS, 7);

                        // let totalBalanceT = Number(this.spotBalance) + Number(this.stakedBalance);
                        // let totalBalanceSolT = Number(this.spotBalanceSol) + Number(this.stakedBalanceSol);

                        // console.log(Number(this.spotBalance))
                        
                        // this.totalBalance = this.round(totalBalanceT, 2);
                        // this.totalBalanceSol = this.round(totalBalanceSolT, 6);
                        
                    });


                }

                

                

            },

            async minusBalFix(walletId){
                const walletRef = doc(db, "UserWallets", String(walletId));
                await updateDoc(walletRef, {
                    balance: 0
                });
            }

            
        }


        
    }
</script>
