<template>

    <div class="page">
        <SidebarBody/>
        <StakingRewardsBody/>
    </div>
    
  </template>
  
  <script>
  import { defineComponent } from "vue";
  import SidebarBody from "@/components/dashboard/SidebarBody.vue";
  import StakingRewardsBody from "@/components/dashboard/StakingRewardsBody.vue";
  
  export default defineComponent({
    name: "StakingRewardsView",
    components: {
      SidebarBody,
      StakingRewardsBody
    },
  });
  </script>
  