<template>
    <Navbar/>
    <StakingBody/>
    <Footer/>
  </template>
  
  <script>
  import { defineComponent } from "vue";
  import Navbar from "@/components/NavbarBody.vue";
  import StakingBody from "@/components/StakingBody.vue";
  import Footer from "@/components/FooterBody.vue"; 
  
  export default defineComponent({
    name: "StakingView",
    components: {
      Navbar,
      StakingBody,
      Footer
    },
  });
  </script>
  