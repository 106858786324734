<template>
    
    <header class="site-header site-header--menu-right fugu-header-section" id="sticky-menu">
    <div class="container-fluid">
      <nav class="navbar site-navbar" >
        <!-- Brand Logo-->
        <div class="brand-logo">
          <router-link :to="{name: 'home'}">
            <img src="@/assets/baseAssets/images/logo.png" alt="" class="light-version-logo" >
          </router-link>
        </div>
        <div class="menu-block-wrapper">
          <div class="menu-overlay"></div>
          <nav class="menu-block" id="append-menu-header">
            <div class="mobile-menu-head">
              <div class="go-back">
                <i class="fa fa-angle-left"></i>
              </div>
              <div class="current-menu-title"></div>
              <div class="mobile-menu-close">&times;</div>
            </div>
            <ul class="site-menu-main text-white">
              <li class="nav-item ">
                <router-link :to="{name: 'home'}" class="nav-link-item text-white" >Home</router-link>
              </li>

              <li class="nav-item ">
                <router-link :to="{name: 'about'}" class="nav-link-item text-white">About</router-link>
              </li>

              <li class="nav-item ">
                <router-link :to="{name: 'staking'}" class="nav-link-item text-white">Staking</router-link>
              </li>

              <li class="nav-item ">
                <router-link :to="{name: 'token'}" class="nav-link-item text-white">Token info</router-link>
              </li>

              <!-- <li class="nav-item ">
                <a href="./nfts.html" target="_blank" class="nav-link-item text-white">NFTs</a>
              </li> -->

              <li class="nav-item ">
                <router-link :to="{name: 'blog'}" class="nav-link-item text-white">Blog</router-link>
              </li>

              <li class="nav-item ">
                <router-link :to="{name: 'contact'}" class="nav-link-item text-white">Contact</router-link>
              </li>

              <li class="nav-item sNav">
                <router-link class="nav-link-item text-white shd" :to="{name: 'sign-in'}" target="_blank" style="background-color: #6f2880; margin-top: 10px; width: 50%; margin-left: 10px; border-radius: 20px; color: #ffffff;">
                  Sign in
                </router-link>
              </li>
              
            </ul>
          </nav>
        </div>
        <div class="header-btn header-btn-l1 ms-auto d-none d-xs-inline-flex">
          <router-link :to="{name: 'sign-in'}" class="fugu-btn fugu-header-btn shd" target="_blank" style="background-color: #6f2880; border-radius: 20px; color: #ffffff;">
            SIGN IN
          </router-link>
        </div>
        <!-- mobile menu trigger -->
        <div class="mobile-menu-trigger">
          <span></span>
        </div>
        <!--/.Mobile Menu Hamburger Ends-->
      </nav>
    </div>
    </header>

</template>

<style scoped>

    @import url(@/assets/baseAssets/css/bootstrap.min.css);
    @import url(@/assets/baseAssets/css/fontawesome.css);
    @import url(@/assets/baseAssets/css/slick.css);
    @import url(@/assets/baseAssets/css/magnific-popup.css);
    @import url(@/assets/baseAssets/css/swiper-bundle.min.css);
    @import url(@/assets/baseAssets/css/icomoon-font.css);
    @import url(@/assets/baseAssets/css/animate.css);

    @import url(@/assets/baseAssets/css/main.css);
    @import url(@/assets/baseAssets/css/app.min.css);

    .sNav{
        display: none;
      }
    
    @media only screen and (max-width: 600px){
      .sNav{
        display: block;
      }
    }

</style>

<script>
  export default {

    mounted(){

      

      //
      const script2Check = document.getElementById("Script2");
      if(script2Check){
        document.body.removeChild(script2Check);
      }
      let Script2 = document.createElement("script");
      Script2.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/bootstrap.bundle.min.js?alt=media&token=e763f60e-5c32-4ad6-ac9b-398493b7adc7");
      Script2.setAttribute("id", "Script2");
      document.body.appendChild(Script2);

      //
      const script3Check = document.getElementById("Script3");
      if(script3Check){
        document.body.removeChild(script3Check);
      }
      let Script3 = document.createElement("script");
      Script3.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/menu.js?alt=media&token=6b8f18cb-ef4d-446d-90bd-b674852309d4");
      Script3.setAttribute("id", "Script3");
      document.body.appendChild(Script3);

      //slick
      const script4Check = document.getElementById("Script4");
      if(script4Check){
        document.body.removeChild(script4Check);
      }
      let Script4 = document.createElement("script");
      Script4.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/slick.js?alt=media&token=ff2c207a-bd5b-440a-9b89-a1a794c865eb");
      Script4.setAttribute("id", "Script4");
      document.body.appendChild(Script4);

      //isotope
      const script5Check = document.getElementById("Script5");
      if(script5Check){
        document.body.removeChild(script5Check);
      }
      let Script5 = document.createElement("script");
      Script5.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/isotope.pkgd.min.js?alt=media&token=0d1d8247-1060-402c-9f0d-47183e17ca36");
      Script5.setAttribute("id", "Script5");
      document.body.appendChild(Script5);

      //magnific-pop
      const script6Check = document.getElementById("Script6");
      if(script6Check){
        document.body.removeChild(script6Check);
      }
      let Script6 = document.createElement("script");
      Script6.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/jquery.magnific-popup.min.js?alt=media&token=000e6333-0bfb-48a7-b0f5-f699e4975020");
      Script6.setAttribute("id", "Script6");
      document.body.appendChild(Script6);

      //swiper
      const script7Check = document.getElementById("Script7");
      if(script7Check){
        document.body.removeChild(script7Check);
      }
      let Script7 = document.createElement("script");
      Script7.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/swiper-bundle.min.js?alt=media&token=101cee10-d1b4-4cc5-9e64-748724ec55bd");
      Script7.setAttribute("id", "Script7");
      document.body.appendChild(Script7);

      //countdown
      // const script8Check = document.getElementById("Script8");
      // if(script8Check){
      //   document.body.removeChild(script8Check);
      // }
      // let Script8 = document.createElement("script");
      // Script8.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/countdown.js?alt=media&token=19d93ba3-95f1-45bb-ad9f-4660b685ace8");
      // Script8.setAttribute("id", "Script8");
      // document.body.appendChild(Script8);

      //wow
      const script9Check = document.getElementById("Script9");
      if(script9Check){
        document.body.removeChild(script9Check);
      }
      let Script9 = document.createElement("script");
      Script9.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/wow.min.js?alt=media&token=33d73d47-03f5-4c51-a989-dc6039123e7a");
      Script9.setAttribute("id", "Script9");
      document.body.appendChild(Script9);

      //app
      const script10Check = document.getElementById("Script10");
      if(script10Check){
        document.body.removeChild(script10Check);
      }
      let Script10 = document.createElement("script");
      Script10.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/app.js?alt=media&token=da603ff3-53a0-4eb4-9264-2468f240cbce");
      Script10.setAttribute("id", "Script10");
      document.body.appendChild(Script10);

    }
    
  }
</script>
