<template>
    <SignUpBody/>
</template>
  
  <script>
  import { defineComponent } from "vue";
  import SignUpBody from "@/components/account/SignUpBody.vue";
  
  export default defineComponent({
    name: "SignUpView",
    components: {
      SignUpBody,
    },
  });
  </script>
  