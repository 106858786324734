<template>
    
    <div class="login" style="background-color:#1F2128 ;">
      <div class="login__row">
        <div class="login__col">
          <h1 class="login__title h1 text-white">One with <br>the future</h1>
          <div class="login__info h6 text-white">OMCDEX</div>
          <div class="login__preview"><img src="@/assets/dashAssets/img/figures-3.png" alt=""></div>
        </div>
        <div class="login__col">

          <form class="login__form" @submit.prevent="signUp">
            <div class="login__stage h4 text-white">Create an <br> OMCDEX account</div>

            <div class="login__field field js-field">
                <div class="field__label"></div>
                <div class="field__wrap"><input class="field__input js-field-input" style="color:grey" type="text" placeholder="Full name*" required v-model="name"></div>
            </div>

            <div class="login__field field js-field">
              <div class="field__label"></div>
              <div class="field__wrap"><input class="field__input js-field-input" style="color:grey" type="email" placeholder="Email*" required v-model="email"></div>
            </div>

            <div class="login__field field js-field">
              <div class="field__label"></div>
              <div class="field__wrap"><input class="field__input js-field-input" style="color:grey" type="password" placeholder="Password*" required v-model="password"></div>
            </div>

            <div class="login__field field js-field">
                <div class="field__label"></div>
                <div class="field__wrap"><input class="field__input js-field-input" style="color:grey" type="password" placeholder="Type password again*" required v-model="cPassword"></div>
            </div>

            <div class="login__field field js-field">
                <div class="field__label"></div>
                <div class="field__wrap"><input class="field__input js-field-input" style="color:grey" type="text" placeholder="Referral code" v-model="rCode"></div>
            </div>

            <div class="login__note text-white" >By signing up I confirm that I am 18 years of age or older and agree to the 
                
                <router-link :to="{name: 'user-agree'}" target="_blank" style="color:#ca05fc">User Agreement</router-link> and <router-link :to="{name: 'privacy'}" target="_blank" style="color:#ca05fc">Privacy Policy</router-link>
            
            </div>
            
              <br>
            
            <button class="login__btn btn btn_blue btn_wide text-white" >Sign up</button>


            <div class="login__flex">
              <div class="login__text text-white">Already have an account?</div>
              
              <router-link :to="{name: 'sign-in'}" class="login__link" style="color:#ca05fc">
                Sign in
              </router-link>

            </div>
          </form>

          
           <!-- <button class="login__btn btn btn_blue btn_wide text-white" style="margin-top: 80px;" @click="test2">TEST...</button> -->

           <!-- <br> 
           <br>

           <div class="login__field field js-field">
                <div class="field__label"></div>
                <div class="field__wrap"><input class="field__input js-field-input" style="color:grey" type="text" placeholder="Symbol" v-model="tSymbol"></div>
            </div>

            <div class="login__field field js-field">
                <div class="field__label"></div>
                <div class="field__wrap"><input class="field__input js-field-input" style="color:grey" type="text" placeholder="Address" v-model="tAddress"></div>
            </div>

            <button class="login__btn btn btn_blue btn_wide text-white" @click="changeAddress" style="margin-top: 80px;">Change address</button> -->

        </div>
      </div>
    </div>


<div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="loadingModal">

<div class="popup__container" style="width: 300px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
            
    <div class="popup__box">

        <div class="popup__fieldset" >
            
            <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                
                <img src="@/assets/dashAssets/img/loading.gif" alt="">

                <p style="text-align: center; color: #ffffff;">{{loadingText}}</p>
                
            </div>

            
            
            
        </div>
        

        

        
    </div>
    
</div>

</div>

<div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="successModal">

<div class="popup__container" style="width: 300px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
            
    <div class="popup__box">

        <div class="popup__fieldset" >
            
            <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                
                <img src="@/assets/dashAssets/img/done.png" alt="" style="width: 100px; margin: 10px;">

                <p style="text-align: center; margin-bottom: 40px; color: #ffffff;">{{successText}}</p>

                <button class="operations__btn btn btn_blue" @click="closeS">Okay</button>
                
            </div>

            
            
            
            
        </div>
        

        

        
    </div>
    
</div>

</div>

<div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="errorModal">

<div class="popup__container" style="width: 300px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
            
    <div class="popup__box">

        <div class="popup__fieldset" >
            
            <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                
                <img src="@/assets/dashAssets/img/error.png" alt="" style="width: 100px; margin: 10px;">

                <p style="text-align: center; margin-bottom: 40px; color: #ffffff;">{{errorText}}</p>

                <button class="operations__btn btn btn_blue" @click="closeE">Okay</button>
                
            </div>

            
            
            
        </div>
        

        

        
    </div>
    
</div>

</div>

</template>

<style scoped>

    @import url(@/assets/dashAssets/css/app.css);

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
    color: white;
    }

</style>

<script>

    import { doc, setDoc, collection, query, where, getDocs, serverTimestamp, updateDoc, addDoc } from "firebase/firestore"; 
    import { db } from '@/firebase';
    import axios from 'axios';
    import { secureToken } from '@/smtp';
    

    export default {
     
        mounted(){

            document.body.classList.add('dark')

            //SVG FOR EVEYBODY
            const script2Check = document.getElementById("Script2");
            if(script2Check){
                document.body.removeChild(script2Check);
            }
            let Script2 = document.createElement("script");
            Script2.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/DASH%20JS%2Fsvg4everybody.min.js?alt=media&token=ce8a5c47-4a98-4ae7-a4db-c8f07f09cc8f");
            Script2.setAttribute("id", "Script2");
            document.body.appendChild(Script2);

            //OWL CAROUSEL
            const script3Check = document.getElementById("Script3");
            if(script3Check){
                document.body.removeChild(script3Check);
            }
            let Script3 = document.createElement("script");
            Script3.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/DASH%20JS%2Fowl.carousel.min.js?alt=media&token=97c8ad60-ade0-464b-ba41-b160a85d79b1");
            Script3.setAttribute("id", "Script3");
            document.body.appendChild(Script3);

            //NICE SELECT
            const script4Check = document.getElementById("Script4");
            if(script4Check){
                document.body.removeChild(script4Check);
            }
            let Script4 = document.createElement("script");
            Script4.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/DASH%20JS%2Fjquery.nice-select.min.js?alt=media&token=69c0a5bc-91e5-4087-8805-e909be8fdc9e");
            Script4.setAttribute("id", "Script4");
            document.body.appendChild(Script4);

            //APEX CHART
            const script5Check = document.getElementById("Script5");
            if(script5Check){
                document.body.removeChild(script5Check);
            }
            let Script5 = document.createElement("script");
            Script5.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/DASH%20JS%2Fapexcharts.min.js?alt=media&token=bafc7498-0b92-4648-a776-d8860b5168e5");
            Script5.setAttribute("id", "Script5");
            document.body.appendChild(Script5);

            //MAGNIFIC POP
            const script6Check = document.getElementById("Script6");
            if(script6Check){
                document.body.removeChild(script6Check);
            }
            let Script6 = document.createElement("script");
            Script6.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/DASH%20JS%2Fjquery.magnific-popup.min.js?alt=media&token=42959ee8-2fde-4c80-96cd-25be9f7261a4");
            Script6.setAttribute("id", "Script6");
            document.body.appendChild(Script6);

            //CHARTS
            const script7Check = document.getElementById("Script7");
            if(script7Check){
                document.body.removeChild(script7Check);
            }
            let Script7 = document.createElement("script");
            Script7.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/DASH%20JS%2Fcharts.js?alt=media&token=95932c08-ba22-4819-b9d4-7387d0b3e8c5");
            Script7.setAttribute("id", "Script7");
            document.body.appendChild(Script7);

            //APP
            const script8Check = document.getElementById("Script8");
            if(script8Check){
                document.body.removeChild(script8Check);
            }
            let Script8 = document.createElement("script");
            Script8.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/DASH%20JS%2Fapp.js?alt=media&token=184882ad-d8fa-4ecb-8538-f3bd841d292b");
            Script8.setAttribute("id", "Script8");
            document.body.appendChild(Script8);

            //GET REF CODE
            this.getRefCode();


        },

        data(){
          return {
              
              name: "",
              email: "",
              password: "",
              cPassword: "",
              rCode: "",
              loadingText: "",
              errorText: "",
              successText: "",
              tSymbol: "",
              tAddress: "",
          }
        },

        methods:{

            async test2(){

                const querySnapshot = await getDocs(collection(db, "Users"));
                querySnapshot.forEach((doc) => {
                
                    this.assign2(String(doc.id));
                    
                });

            },

            async assign2(userId){

                const cardRef = doc(db, "Cards", userId);
                await updateDoc(cardRef, {
                    cardBalance: 0
                });

                console.log("DONE");

            },

        //  async test(){

        //     const querySnapshot = await getDocs(collection(db, "Users"));
        //     querySnapshot.forEach((doc) => {
            
        //         this.assign(String(doc.id));
                
        //     });

        //  },    

        // async assign(userId){

        //     await addDoc(collection(db, "UserWallets"), {
        //         address: "0x4a94c08c355F1cE23BaE5C30e4948DeE0E2115f7",
        //         balance: 0,
        //         color: "#4528A0",
        //         name: "The graph",
        //         symbol: "GRT",
        //         userId: userId,
        //     });

        //     console.log("DONE");
        // },  
         

          closeS(){
            this.$refs.successModal.classList.remove("bg-active");
          },

          closeE(){
            this.$refs.errorModal.classList.remove("bg-active");
          },

          getRefCode(){
            let urlString = String(window.location.href);
            let url = new URL(urlString);
            let ref = url.searchParams.get("ref");
            if(ref == null){
                return;
            }
            this.rCode = String(ref);
          },

          getLocation(name, email){

                axios.get('https://api.geoapify.com/v1/ipinfo?apiKey=5af290eb2cfd4bf3ae9db6b35af307c1')
                .then( response => {

                    
                    let city = response.data.city.name;
                    let country = response.data.country.name;
                    let timeStamp = new Date();

                    //1


                    
                    
                });

            },

          async signUp(){

            //start loading
            this.loadingText= "Creating your account...";
            this.$refs.loadingModal.classList.add("bg-active");

            //get verify pin
            let verifyPin = String(Math.floor(Math.random()*90000) + 10000);

            //get pin expiry
            let pinExpiry = (new Date().getTime() / (1000 * 60)) + 30;

            //get userId
            let userId = this.name + Math.floor(Math.random()*9000000) + 1000000;

            //get referral code
            let referralCode = "OMC"+ String(Math.floor(Math.random()*9000000000) + 1000000000);

            //password length
            if(!(this.password.length >= 8)){
                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.errorModal.classList.add("bg-active");
                this.errorText = "Password must be 8 characters or more";
                console.log("ERROR");
                return;
            }

            //password match
            if(!(this.password == this.cPassword)){
                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.errorModal.classList.add("bg-active");
                this.errorText = "Passwords do not match";
                console.log("ERROR");
                return;
            }

            //check if email exists
            const usersRef = collection(db, "Users");
            const q = query(collection(db, "Users"), where("email", "==", this.email.toLowerCase()));

            const querySnapshot = await getDocs(q);
            if(querySnapshot.docs.length > 0){
               this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.errorModal.classList.add("bg-active");
                this.errorText = "Email already exists";
                console.log("ERROR");
                return; 
            }


            //firebase signup

            setDoc(doc(db, "Users", String(userId)), {
                email : this.email.toLowerCase(),
                name : this.name,
                password : this.password,
                profilePic : "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/ava-header.png?alt=media&token=48722676-c285-4cc2-82b5-72d460a8848e",
                userId : userId,
                verified : "no",
                auth: "yes",
                dateJoined: serverTimestamp(),
                phrase: "",
                refCode: referralCode,
                totalRefs: 0,
                refBalance: 0,
                upline: this.rCode,
                
            })
            .then(() => {

                //save id
                sessionStorage.setItem('id', String(userId));
                sessionStorage.setItem('name', String(this.name));
                sessionStorage.setItem('email', String(this.email.toLowerCase()));
                sessionStorage.setItem('verifyPin', verifyPin);
                sessionStorage.setItem('pinExpiry', pinExpiry);
                sessionStorage.setItem('upline', String(this.rCode));
                sessionStorage.setItem('profilePic', "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/ava-header.png?alt=media&token=48722676-c285-4cc2-82b5-72d460a8848e");

                let name = this.name;

                //send verification pin
               

            })
            .catch(error =>{
                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.errorModal.classList.add("bg-active");
                this.errorText = "An error occured while creating your account";
                console.log(error);
            })

            await setDoc(doc(db, "Cards", userId), {
                cardTier: "none",
                cardAmount: 0,
                cardBalance: 0
            });

          },

        //   async updateAddress(id){

        //     const updateRef = doc(db, "UserWallets", id);
        //     await updateDoc(updateRef, {
        //         address: this.tAddress
        //     });

        //     console.log("DONE");

        //   },

        //   async changeAddress(){

        //     const q = query(collection(db, "UserWallets"), where("symbol", "==", this.tSymbol));
        //     const querySnapshot = await getDocs(q);
        //     querySnapshot.forEach((doc) => {
        //         this.updateAddress(doc.id)
        //     })

        //   }

        }


    }

</script>
