<template>

    <div class="page__content">

        <HeaderBody/>

        <div class="activity">

            <div id="noT" style="display:none; width:100%">
                    
                    <h3 style="text-align:center; color: #ffffff; width: 100%; margin-top: 100px;">No transactions yet...</h3>
                </div>

            <div class="activity__container" id="yesT">
                <div class="activity__stage h6 mobile-show" style="color: #ffffff">All crypto transactions</div>
                <div class="activity__stage h6 mobile-hide" style="color: #ffffff">All crypto transactions</div>
                <p class="activity__transaction" style="margin-top: -20px; margin-bottom:30px">Select a transaction to see it's details.</p>
                
                <div class="activity__table" >
                    
                    <div class="activity__row activity__row_head">
                        
                        
                        <div class="activity__cell">DATE</div>
                        <div class="activity__cell">TYPE/Coin</div>
                        <div class="activity__cell">
                            AMOUNT <span class="activity__hidden">/ DATE</span>
                        </div>
                        <div class="activity__cell">STATUS</div>
                        <div class="activity__cell">ADDRESS</div>
                        
                    </div>

                    <div class="activity__row" v-for="trans in transList" :key="trans.coin" @click="row(trans.number)">
                        
                        
                        <div class="activity__cell">
                            <div class="activity__date">{{trans.timeStamp}}</div>
                        </div>
                        <div class="activity__cell">
                            <div class="activity__company">
                                <div class="activity__logo" style="border-radius: 50%; ">
                                    <img :src="trans.image" alt="">
                                </div>
                                <div class="activity__name">{{trans.type}} {{trans.symbol}}</div>
                            </div>
                        </div>
                        <div class="activity__cell">
                            <div class="activity__price">${{trans.amountUsd}}</div>
                            <div class="activity__date activity__hidden">{{trans.timeStamp}}</div>
                        </div>
                        <div class="activity__cell">
                            <div class=" activity__status" v-if="trans.status=='successful'">{{trans.status}}</div>
                            <div class=" activity__status_red" v-if="trans.status=='cancelled'">{{trans.status}}</div>
                            
                        </div>
                        <div class="activity__cell">
                            <div class="activity__address">{{trans.address}}</div>
                            
                            
                        </div>
                        
                    </div>

                    
                </div>
                
            </div>

            <div class="activity__sidebar">
                <div class="activity__wrap">
                    <button class="activity__close">
                        <img src="@/assets/dashAssets/img/close2.png" alt="">
                    </button>
                    <div class="activity__head">
                        <div class="activity__logo">
                            <img :src="image" alt="" style="border-radius: 50%; ">
                        </div>
                        <div class="activity__details">
                            <div class="activity__info">{{type}} {{symbol}}</div>
                            <div class="activity__date">{{timeStamp}}</div>
                        </div>
                    </div>
                    <div class="activity__line">
                        <div class="activity__money">${{amountUsd}}</div>
                        
                    </div>
                    
                    <div class="activity__parameters">
                        <div class="activity__parameter">
                            <div class="activity__preview">
                                <img src="@/assets/dashAssets/img/check.svg" alt="">
                            </div>
                            <div class="activity__box">
                                <div class="activity__category">Status</div>
                                <div class="activity__status" v-if="status=='successful'">{{status}}</div>
                                <div class="activity__status_red" v-if="status=='cancelled'">{{status}}</div>
                            </div>
                        </div>
                        <div class="activity__parameter">
                            <div class="activity__preview">
                                <img src="@/assets/dashAssets/img/coins-1.svg" alt="">
                            </div>
                            <div class="activity__box">
                                <div class="activity__category">Amount</div>
                                <div class="activity__value">{{amount}} {{symbol}}</div>
                            </div>
                        </div>
                        <div class="activity__parameter">
                            <div class="activity__preview">
                                <img src="@/assets/dashAssets/img/wallet.svg" alt="">
                            </div>
                            <div class="activity__box">
                                <div class="activity__category">Address </div>
                                <div class="activity__value">{{address}}</div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>

        </div>


    </div>

    <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="loadingModal">

<div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
            
    <div class="popup__box">

        <div class="popup__fieldset" >
            
            <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                
                <img src="@/assets/dashAssets/img/loading.gif" alt="">

                <p style="text-align: center; color: #ffffff;">{{loadingText}}</p>
                
            </div>

            
            
            
        </div>
        

        

        
    </div>
    
</div>

        </div>

        <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="successModal">

        <div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
                    
            <div class="popup__box">

                <div class="popup__fieldset" >
                    
                    <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                        
                        <img src="@/assets/dashAssets/img/done.png" alt="" style="width: 100px; margin: 10px;">

                        <p style="text-align: center; margin-bottom: 40px; color: #ffffff;">{{successText}}</p>

                        <button class="operations__btn btn btn_blue" @click="closeS">Okay</button>
                        
                    </div>

                    
                    
                    
                    
                </div>
                

                

                
            </div>
            
        </div>

        </div>

        <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="errorModal">

        <div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
                    
            <div class="popup__box">

                <div class="popup__fieldset" >
                    
                    <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                        
                        <img src="@/assets/dashAssets/img/error.png" alt="" style="width: 100px; margin: 10px;">

                        <p style="text-align: center; margin-bottom: 40px; color: #ffffff;">{{errorText}}</p>

                        <button class="operations__btn btn btn_blue" @click="closeE">Okay</button>
                        
                    </div>

                    
                    
                    
                </div>
                

                

                
            </div>
            
        </div>

        </div> 
    
</template>

<style scoped>
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: grey;
    opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: grey;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
    color: grey;
    }   

</style>

<script>

    import { doc, addDoc, collection, query, where, getDocs, getDoc, serverTimestamp, updateDoc, orderBy } from "firebase/firestore"; 
    import { db } from '@/firebase';
    import axios from 'axios';
    import HeaderBody from '@/components/dashboard/HeaderBody.vue';

    export default {
        components:{
            HeaderBody,
        },

        mounted(){
            //toggle sidebar active
            document.getElementById("rWallet").classList.remove("active");
            document.getElementById("rSwap").classList.remove("active");
            document.getElementById("rStaking").classList.remove("active");
            document.getElementById("rTrans").classList.add("active");
            document.getElementById("rCard").classList.remove("active");

            this.getTrans();
        },

        data(){
            return{
                loadingText:"",
                successText: "",
                errorText: "",
                transList: [],
                name: "",
                image: "",
                symbol: "",
                amount: "",
                amountUsd: "",
                address: "",
                status: "",
                type: "",
                timeStamp: "",
            }
        },

        methods: {
            round(num, decimals){
                return +(Math.round(num + "e" + decimals) + "e-" + decimals);
            },

            closeS(){
                    this.$refs.successModal.classList.remove("bg-active");
                    //this.$router.go();
            },

            closeE(){
                this.$refs.errorModal.classList.remove("bg-active");
            },

            async getTrans(){

                this.loadingText= "Getting transactions...";
                this.$refs.loadingModal.classList.add("bg-active");

                const q = query(collection(db, "UserTrans"), where("userId", "==", sessionStorage.getItem("id")), orderBy("timeStamp", "desc")); 
                const querySnapshot = await getDocs(q);

                if(querySnapshot.docs.length > 0){
                    let i = -1;
                    let a2;
                    document.getElementById("noT").style.display = "none";

                    querySnapshot.forEach((doc) => {
                        i++;
                        
                        a2 = String(new Date(Number(doc.data().timeStamp.seconds) * 1000));
                        

                        this.transList[i] = {
                            number: i,
                            type: doc.data().type,
                            name: doc.data().name,
                            email: doc.data().email,
                            coin: doc.data().coin,
                            symbol: doc.data().symbol,
                            image: doc.data().image,
                            address: doc.data().address,
                            amount: doc.data().amount,
                            status: doc.data().status,
                            amountUsd: doc.data().amountUsd,
                            timeStamp: a2.slice(0, -36),
                            
                        }

                        
                    })
                }
                else{
                 
                    document.getElementById("yesT").style.display = "none";
                    document.getElementById("noT").style.display = "flex";
                }

                this.$refs.loadingModal.classList.remove("bg-active");
            },

            row(i){
                this.name = this.transList[i].name;
                this.image = this.transList[i].image;
                this.symbol = this.transList[i].symbol;
                this.amount = this.transList[i].amount;
                this.amountUsd = this.transList[i].amountUsd;
                this.address = this.transList[i].address;
                this.status = this.transList[i].status;
                this.type = this.transList[i].type;
            }


        }
    }
</script>