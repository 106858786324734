<template>
    
    <div class="page__content" style="margin-top:100px">

        <input id="nameInput" class="header__" type="text" placeholder="Search by name, email, date or status..."  v-model="search" style="margin-left:20px; margin-right: 20px; width: 100%;" />
        <br>
        
        

        <div class="promotions">
                    <div class="promotions__wrapper" >
                        
                        <br>
                        <hr>
                        <br>

                        
                        <div class="promotions__list">

                            <div class="promotions__item" v-for="trans in filteredTrans" :key="trans.number">
                                <a class="promotions__preview" style="width: 100%; display:flex; justify-content:space-around; align-items:center; margin-top: 5px;">
                                    <img :src="trans.image" alt="" style="width: 50px;">
                                </a>
                                

                                <div style="width: 100%; display:flex; justify-content:space-around; align-items:center; flex-direction: column; color: #fff;">
                                    <h4 style="color:#fff">{{trans.type}} {{trans.symbol}}</h4>
                                    
                                </div>     

                                <div class="promotions__body">

                                    <div class="hr-line"></div>
                                    

                                    <div>

                                        <div style="width: 100%; display:flex; justify-content:space-between; align-items:center; padding: 10px">
                                            <p style="font-size: 12px; color:#8f8e8e; ">Name</p>
                                            <p>{{trans.name}}</p>
                                        </div>

                                        <div style="width: 100%; display:flex; justify-content:space-between; align-items:center; padding: 10px">
                                            <p style="font-size: 12px; color:#8f8e8e; ">Email</p>
                                            <p>{{trans.email}}</p>
                                        </div>

                                        
    
                                        <div style="width: 100%; display:flex; justify-content:space-between; align-items:center; padding: 10px;">
                                            <p style="font-size: 12px; color:#8f8e8e; ">Date</p>
                                            <p>{{trans.date}}</p>
                                        </div>


                                        <div style="width: 100%; display:flex; justify-content:space-between; align-items:center; padding: 10px;">
                                            <p style="font-size: 12px; color:#8f8e8e; ">Status</p>
                                            <div class="promotions__date bg-green-opacity color-orange" v-if="trans.status=='pending'">{{trans.status}}</div>
                                            <div class="promotions__date bg-green-opacity color-green " v-if="trans.status=='successful'">{{trans.status}}</div>
                                            <div class="promotions__date bg-green-opacity color-red " v-if="trans.status=='cancelled'">{{trans.status}}</div>
                                        </div>

                                        <div class="operations__field field js-field" v-if="trans.status == 'pending'">
                                            <div class="popup__label">Amount</div>
                                            <div class="field__wrap">
                                                <input class="field__input js-field-input" type="number" step="any" placeholder="0.0000" style="text-align: center; font-size: 30px;" :id="trans.number+'Amount'" >
                                            </div>
                                        </div>

                                        <div class="operations__field field js-field" v-if="trans.status == 'pending'">
                                            <div class="popup__label">Amount (USD)</div>
                                            <div class="field__wrap">
                                                <input class="field__input js-field-input" type="number" placeholder="$0.00" style="text-align: center; font-size: 30px;" :id="trans.number+'AmountUsd'">
                                            </div>
                                        </div>

                                        <div class="operations__field field js-field" v-if="trans.status == 'pending'">
                                            <div class="popup__label">Address</div>
                                            <div class="field__wrap">
                                                <input class="field__input js-field-input" type="text" placeholder="wallet address" style="text-align: center; font-size: 30px;" :id="trans.number+'Address'" >
                                            </div>
                                        </div>

                                    </div>

                                    
                                    <button class="details__btn btn btn_blue btn_wide" style="margin-top: 10px; margin-bottom:10px; background-color: #4FBF67;" v-if="trans.status == 'pending'" @click="approve(trans.number)">Approve</button>

                                    <br><br>

                                    <button class="details__btn btn btn_blue btn_wide" style="margin-top: 10px; margin-bottom:10px; background-color: #FF2525;" v-if="trans.status == 'pending'" @click="decline(trans.number)">Decline</button>

                                </div>
                            </div>
                            
                        </div>
                        
                    </div>
        </div>

    </div>

        <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="loadingModal">

<div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
            
    <div class="popup__box">

        <div class="popup__fieldset" >
            
            <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                
                <img src="@/assets/dashAssets/img/loading.gif" alt="">

                <p style="text-align: center; color: #ffffff;">{{loadingText}}</p>
                
            </div>

            
            
            
        </div>
        

        

        
    </div>
    
</div>

        </div>

        <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="successModal">

        <div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
                    
            <div class="popup__box">

                <div class="popup__fieldset" >
                    
                    <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                        
                        <img src="@/assets/dashAssets/img/done.png" alt="" style="width: 100px; margin: 10px;">

                        <p style="text-align: center; margin-bottom: 40px; color: #ffffff;">{{successText}}</p>

                        <button class="operations__btn btn btn_blue" @click="closeS">Okay</button>
                        
                    </div>

                    
                    
                    
                    
                </div>
                

                

                
            </div>
            
        </div>

        </div>

        <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="errorModal">

        <div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
                    
            <div class="popup__box">

                <div class="popup__fieldset" >
                    
                    <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                        
                        <img src="@/assets/dashAssets/img/error.png" alt="" style="width: 100px; margin: 10px;">

                        <p style="text-align: center; margin-bottom: 40px; color: #ffffff;">{{errorText}}</p>

                        <button class="operations__btn btn btn_blue" @click="closeE">Okay</button>
                        
                    </div>

                    
                    
                    
                </div>
                

                

                
            </div>
            
        </div>

        </div>


</template>

<style scoped>
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: grey;
    opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: grey;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
    color: grey;
    }
</style>

<script>
    import { doc, addDoc, collection, query, where, getDocs, getDoc, serverTimestamp, updateDoc, increment, deleteDoc, orderBy } from "firebase/firestore"; 
    import { db } from '@/firebase';
    import { secureToken } from '@/smtp';
    
    export default {
        data(){
            return{
                transList:[],
                loadingText: "",
                errorText: "",
                successText: "",
                search: "",
                
            }
        },

        computed:{

            filteredTrans(){
                return this.transList.filter( trans => String(trans.name).toLowerCase().includes(this.search.toLowerCase()) || String(trans.date).toLowerCase().includes(this.search.toLowerCase()) || String(trans.email).toLowerCase().includes(this.search.toLowerCase()) || String(trans.status).toLowerCase().includes(this.search.toLowerCase()) );
                
            }

        },

        mounted(){

            this.getUserTrans();
            
        },

        methods:{

            round(num, decimals){
                return +(Math.round(num + "e" + decimals) + "e-" + decimals);
            },

            closeS(){
                this.$refs.successModal.classList.remove("bg-active");
                this.$router.go()
            },

            closeE(){
                this.$refs.errorModal.classList.remove("bg-active");
            },

            async getUserTrans(){

                this.loadingText= "Loading...";
                this.$refs.loadingModal.classList.add("bg-active");

                let i = -1;
                let a2;
                //get user transactions
                const q = query(collection(db, "AdminTrans"), orderBy("timeStamp", "desc") );

                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    i++;
                    a2 = String(new Date(Number(doc.data().timeStamp.seconds) * 1000));
                    this.transList[i] = {
                        id: doc.id,
                        number: i,
                        type: doc.data().type,
                        image: doc.data().image,
                        name: doc.data().name,
                        email: doc.data().email,
                        date: a2.slice(0, -36),
                        symbol: doc.data().symbol,
                        status: doc.data().status,
                        walletId: doc.data().walletId,
                        coin: doc.data().coin,
                        timeStamp: doc.data().timeStamp,
                        userId: doc.data().userId,
                    }
                });

                this.$refs.loadingModal.classList.remove("bg-active");
            } ,
            
            async approve(i){

                this.loadingText= "approving transaction...";
                this.$refs.loadingModal.classList.add("bg-active");

                //get balance
                let balance;
                let amount;
                let amountUsd;
                let address;
                let newBalance;

                amount = Number(document.getElementById(""+i+"Amount").value);
                amountUsd = Number(document.getElementById(""+i+"AmountUsd").value);
                address = document.getElementById(""+i+"Address").value;

                //empty check
                if(document.getElementById(""+i+"Amount").value == "" || document.getElementById(""+i+"AmountUsd").value == "" || document.getElementById(""+i+"Address").value == ""){

                    this.errorText= "All fields must be filled...";
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");

                    return;
                }

                const walletRef = doc(db, "UserWallets", this.transList[i].walletId);
                const walletSnap = await getDoc(walletRef);

                const AdminTransRef = doc(db, "AdminTrans", this.transList[i].id);

                if(walletSnap.exists()){
                    balance = walletSnap.data().balance;
                }

                //approve send
                if(this.transList[i].type == "Send"){
                    
                    newBalance = balance - amount;

                    //update balance
                    await updateDoc(walletRef, {
                        balance: this.round(Number(newBalance), 7)
                    });

                    //update Admin transaction
                    await updateDoc(AdminTransRef, {
                        status: "successful",
                        amount: this.round(amount, 7),
                        amountUsd: this.round(amountUsd, 2),
                        address: address,
                    });

                    //Add doc to User trans
                    await addDoc(collection(db, "UserTrans"), {
                        type: "Send",
                        name: this.transList[i].name,
                        email: this.transList[i].email,
                        coin: this.transList[i].coin,
                        symbol: this.transList[i].symbol,
                        image: this.transList[i].image,
                        address: address,
                        amount: this.round(amount, 7),
                        status: "successful",
                        amountUsd: this.round(amountUsd, 2),
                        timeStamp: this.transList[i].timeStamp,
                        userId: this.transList[i].userId,
                        walletId: this.transList[i].walletId
                    });

                    let sName = this.transList[i].name;
                    let sEmail = this.transList[i].email;
                    let sSymbol = this.transList[i].symbol;
                    let sAddress = address;
                    let sAmount = amount;

                    //send mail to user
                    Email.send({
                    SecureToken: secureToken,
                    To : sEmail,
                    From : "support@omcdex.io",
                    Subject : sSymbol+" Withdrawal Successful",
                    Body : `<!DOCTYPE html>
<html lang="en">
<head>

    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet">
    
</head>
<body >
    <section style="width: 100vw; margin: 0; padding: 0; background-color: #14112e;">


        <style>
            *{
                @import url(https://fonts.googleapis.com);
                @import url(https://fonts.gstatic.com);
                @import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
                margin: 0; 
                padding: 0; 
                font-family: sans-serif; 
                
                overflow-x: hidden; 
                font-family: 'Nunito', sans-serif;
            }
        </style>

        <div style="text-align:center; padding-bottom: 20px; padding-top: 20px; width: 100%;">
            <img src="https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/images%2Flogo.png?alt=media&token=bf7f4424-2c79-4d80-b73f-f49a9fe78bc4" alt="" width="130px">
        </div>

        <hr>

        <div style="padding-left: 10px; padding-right: 10px; padding-top: 20px; padding-bottom: 20px; font-size: 14px; background-color: #120130; color: #ffffff;">

            <div style=" padding-top: 10px; padding-bottom: 20px; font-size: 16px; text-align: center; font-weight: 700; color: #da4bfb;">
                ${sSymbol} Withdrawal Successful
            </div>

            <div style=" padding-top: 10px; padding-bottom: 20px; font-size: 14px; color: #ffffff;">
                Hello ${sName}, 
            </div>

            <div style=" font-size: 14px; margin-bottom: 15px; color: #ffffff;">
                Your withdrawal of ${sAmount} ${sSymbol} to ${sAddress} has been completed successfully. Sign in to omcdex.io (https://www.omcdex.io) to view your balance. 
            </div>

            <div style=" font-size: 14px; margin-bottom: 15px; color: #ffffff;">
                If you are not aware of this activity, please reset your password and contact support@omcdex.io immediately. 
            </div>


            <div style=" font-size: 14px; margin-bottom: 15px; color: #ffffff;">
                
            </div>

            <div style=" font-size: 14px; margin-bottom: 15px; color: #ffffff;">
                
            </div>

            

           
        </div>

        <hr>

        <div style=" padding-top: 20px; padding-left: 10px; padding-bottom: 20px; font-size: 10px; text-align: center; color: #ffffff;">
            Stay connected @ <span><a href="mailto:support@omcdex.io" style="color: #da4bfb;">support@omcdex.io</a></span>
        </div>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 10px; font-style: italic; color: #ffffff;">
            Risk warning:  Cryptocurrency trading is subject to high market risk. OMCDEX will endeavor to choose exclusive coins, but will not be held accountable for your trading losses. Please be vigilant about your trades.
        </div>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 10px; font-style: italic; color: #ffffff;">
            Kindly note: Please be aware of phishing sites and always make sure you are visiting the official Omcnvault.io website when entering sensitive data.
        </div>

        <div style="padding-left: 10px; padding-right: 10px; text-align: center; padding-top: 20px; padding-bottom: 70px; font-size: 10px; color: #ffffff;">
            ©2024 <span><a href="https://www.omcdex.io/" target="_blank" rel="noopener noreferrer" style="color: #da4bfd;">omcdex.io</a></span> All Rights Reserved
        </div>
        
    </section>
</body>
</html>`
                }).
                then((message) => {
                    // console.log(message);

                });
                    

                }
                else{
                    //approve receive

                    newBalance = balance + amount;

                    //update balance
                    await updateDoc(walletRef, {
                        balance: this.round(Number(newBalance), 7)
                    });

                    //update Admin transaction
                    await updateDoc(AdminTransRef, {
                        status: "successful",
                        amount: this.round(amount, 7),
                        amountUsd: this.round(amountUsd, 2),
                        address: address,
                    });

                    //Add doc to User trans
                    await addDoc(collection(db, "UserTrans"), {
                        type: "Receive",
                        name: this.transList[i].name,
                        email: this.transList[i].email,
                        coin: this.transList[i].coin,
                        symbol: this.transList[i].symbol,
                        image: this.transList[i].image,
                        address: address,
                        amount: this.round(amount, 7),
                        status: "successful",
                        amountUsd: this.round(amountUsd, 2),
                        timeStamp: this.transList[i].timeStamp,
                        userId: this.transList[i].userId,
                        walletId: this.transList[i].walletId
                    });

                    let rName = this.transList[i].name;
                    let rEmail = this.transList[i].email;
                    let rSymbol = this.transList[i].symbol;
                    let rAddress = address;
                    let rAmount = amount;

                    //send mail to user
                    Email.send({
                    SecureToken: secureToken,
                    To : rEmail,
                    From : "support@omcdex.io",
                    Subject : rSymbol+" Desposit Successful",
                    Body : `<!DOCTYPE html>
<html lang="en">
<head>

    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet">
    
</head>
<body >
    <section style="width: 100vw; margin: 0; padding: 0; background-color: #14112e;">


        <style>
            *{
                @import url(https://fonts.googleapis.com);
                @import url(https://fonts.gstatic.com);
                @import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
                margin: 0; 
                padding: 0; 
                font-family: sans-serif; 
                
                overflow-x: hidden; 
                font-family: 'Nunito', sans-serif;
            }
        </style>

        <div style="text-align:center; padding-bottom: 20px; padding-top: 20px; width: 100%;">
            <img src="https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/images%2Flogo.png?alt=media&token=bf7f4424-2c79-4d80-b73f-f49a9fe78bc4" alt="" width="130px">
        </div>

        <hr>

        <div style="padding-left: 10px; padding-right: 10px; padding-top: 20px; padding-bottom: 20px; font-size: 14px; background-color: #120130; color: #ffffff;">

            <div style=" padding-top: 10px; padding-bottom: 20px; font-size: 16px; text-align: center; font-weight: 700; color: #da4bfb;">
                ${rSymbol} Deposit Successful
            </div>

            <div style=" padding-top: 10px; padding-bottom: 20px; font-size: 14px; color: #ffffff;">
                Hello ${rName}, 
            </div>

            <div style=" font-size: 14px; margin-bottom: 15px; color: #ffffff;">
                Your deposit of ${rAmount} ${rSymbol} is now available in your OMCDEX account. Log in to omcdex.io (https://www.omcdex.io) to view your balance. 
            </div>

            <div style=" font-size: 14px; margin-bottom: 15px; color: #ffffff;">
                If you are not aware of this activity, please reset your password and contact support@omcdex.io immediately. 
            </div>


            <div style=" font-size: 14px; margin-bottom: 15px; color: #ffffff;">
                
            </div>

            <div style=" font-size: 14px; margin-bottom: 15px; color: #ffffff;">
                
            </div>

            

           
        </div>

        <hr>

        <div style=" padding-top: 20px; padding-left: 10px; padding-bottom: 20px; font-size: 10px; text-align: center; color: #ffffff;">
            Stay connected @ <span><a href="mailto:support@omcdex.io" style="color: #da4bfb;">support@omcdex.io</a></span>
        </div>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 10px; font-style: italic; color: #ffffff;">
            Risk warning:  Cryptocurrency trading is subject to high market risk. OMCDEX will endeavor to choose exclusive coins, but will not be held accountable for your trading losses. Please be vigilant about your trades.
        </div>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 10px; font-style: italic; color: #ffffff;">
            Kindly note: Please be aware of phishing sites and always make sure you are visiting the official Omcnvault.io website when entering sensitive data.
        </div>

        <div style="padding-left: 10px; padding-right: 10px; text-align: center; padding-top: 20px; padding-bottom: 70px; font-size: 10px; color: #ffffff;">
            ©2024 <span><a href="https://www.omcdex.io/" target="_blank" rel="noopener noreferrer" style="color: #da4bfd;">omcdex.io</a></span> All Rights Reserved
        </div>
        
    </section>
</body>
</html>`
                }).
                then((message) => {
                    // console.log(message);

                });

                    //****get user's upline*****
                    let uplineRefCode, refId, refWalletId, refReward, refRewardUsd;

                    const userRef = doc(db, "Users", this.transList[i].userId);
                    const userSnap = await getDoc(userRef);

                    if(userSnap.exists()){
                        if(userSnap.data().upline == ""){
                            this.successText= "Successful"
                            this.$refs.loadingModal.classList.remove("bg-active");
                            this.$refs.successModal.classList.add("bg-active");
                            console.log("NO REF");
                            return;
                        }
                        uplineRefCode = userSnap.data().upline;
                    }

                    //****get upline userId */
                    const q = query(collection(db, "Users"), where("refCode", "==", uplineRefCode));
                    const querySnapshot = await getDocs(q);
                    if(querySnapshot.docs.length > 0){

                        querySnapshot.forEach((doc) => {
                        
                            refId = String(doc.id)
                        });

                    }
                    else{

                        this.successText= "Successful"
                        this.$refs.loadingModal.classList.remove("bg-active");
                        this.$refs.successModal.classList.add("bg-active");
                        console.log("NO REF");
                        return;
                    }

                    // *******get upline wallet
                    const q2 = query(collection(db, "UserWallets"), where("userId", "==", refId), where("symbol", "==", this.transList[i].symbol) );
                    const querySnapshot2 = await getDocs(q2);
                    if(querySnapshot2.docs.length > 0){
                        querySnapshot2.forEach((doc) => {
                        
                            refWalletId = String(doc.id)
                        });
                    }
                    else{

                        this.successText= "Successful"
                        this.$refs.loadingModal.classList.remove("bg-active");
                        this.$refs.successModal.classList.add("bg-active");
                        console.log("NO REF");
                        return;
                    }

                    // ********get ref reward
                    refReward = amount * 0.03;
                    refRewardUsd = amountUsd * 0.03;

                    // ******update ref wallet balance
                    const refWallet = doc(db, "UserWallets", refWalletId);
                    await updateDoc(refWallet, {
                        balance: increment(refReward)
                    });

                    // ********Update ref total balance
                    const refTotal = doc(db, "Users", refId);
                    await updateDoc(refTotal, {
                        refBalance: increment(refRewardUsd)
                    });

                }

                this.successText= "Successful"
                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.successModal.classList.add("bg-active");


                
            },

            async decline(i){
                this.loadingText= "declining transaction...";
                this.$refs.loadingModal.classList.add("bg-active");

                const AdminTransRef = doc(db, "AdminTrans", this.transList[i].id);

                //Decline send
                if(this.transList[i].type == "Send"){

                    //update Admin transaction
                    await updateDoc(AdminTransRef, {
                        status: "cancelled",
                        amount: "---",
                        amountUsd: "---",
                        address: "---",
                    });

                    //Add doc to User trans
                    await addDoc(collection(db, "UserTrans"), {
                        type: "Send",
                        name: this.transList[i].name,
                        email: this.transList[i].email,
                        coin: this.transList[i].coin,
                        symbol: this.transList[i].symbol,
                        image: this.transList[i].image,
                        address: "---",
                        amount: "---",
                        status: "cancelled",
                        amountUsd: "---",
                        timeStamp: this.transList[i].timeStamp,
                        userId: this.transList[i].userId,
                        walletId: this.transList[i].walletId
                    });

                }
                else{
                    //Decline receive

                    //delete Admin transaction
                    await deleteDoc(doc(db, "AdminTrans", this.transList[i].id));

                }

                this.successText= "Successful"
                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.successModal.classList.add("bg-active");
            }
        }
    }
</script>

