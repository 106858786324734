<template>
    
    <div class="fugu-blog-section fugu-section-padding">

        <div class="container">
      <div class="row">
        
        <div class="col-xl-8 col-lg-7" style="width: 100%; margin-bottom: 50px;">
          <div class="fugu-blog-single-wrap">
            <img class="wow fadeInUpX" src="@/assets/baseAssets/images/metaverse.png" alt="">
            <div class="fugu-blog-meta">
              <ul>
                <li class="text-white">by Philip Miller,</li>
                <li class="text-white">December 22,2021</li>
              </ul>
            </div>
            <h3 class="text-white">The OMCDEX Metaverse (Omniverse)</h3>

            <h5 class="text-white">What is the Metaverse?</h5>

            <p class="text-white">The word 'metaverse' catapulted into the collective consciousness of the world after Facebook recently announced it was rebranding itself as Meta. The renaming was undertaken as a nod to the term metaverse, which has been discussed in tech circles as the next big revolution after the Internet.</p>

            <p class="text-white">Simply put, the metaverse is the creation of a virtual universe where avatars of human beings will be present through technology. Such metaverses can be created by various companies for various reasons: entertainment, education and business.</p>

            <h5 class="text-white">The Omniverse
            </h5>

            <p class="text-white">The OMCDEX token is about to take the world by storm since the launch of its blockchain-based virtual reality platform — “The Omniverse”.</p>

            <p class="text-white">A virtual space where users can buy, develop, and sell ‘Celestial bodies’ , a non-fungible SPL token that represents the ownership of virtual entities in the Omniverse.</p>

            <p class="text-white">This virtual space is a community-driven creative ecosystem and NFT collection. Through metaverse-ready 3D objects such as planets, satellites, stars e.t.c. , the Omniverse is here to inspire creativity and connection amongst its users.</p>

            <p class="text-white">We are building the first virtual interstellar space . It highlights gamers as they explore through space claiming and buying Celestial bodies as NFTs. Built to infrastructure and in the end of the day, gamers will be handed the keys to the avatars and become explorers . A decentralized server will be built how players can have ownership and will have a studio to create a variety of environments. Creators can build their own terrain and produce NFTS out of it by buying planetary bodies, stars, moons e.t.c.</p>

            <p class="text-white">The Omniverse has a virtual economy where users can engage in a myriad of in-world economic transactions as well as monetize the content and applications they build. Unlike other virtual worlds such as Active Worlds, it is not controlled by a centralized organization or company.</p>

            <h5 class="text-white">The OMCDEX Token (OMC)</h5>

            <p class="text-white">The Omiciron token (OMC) is a metaverse token operating on the Solana blockchain network. As indicated earlier, the OMC token would be used to create, buy and sell these interstellar space entities as well as tokenized funds. OMC is the digital asset token used to pay for ‘assets’ in Omniverse . It is built on Solana in accordance with the SLP standard for tokens.</p>

            <p class="text-white">So far, OMC can only be found on the OMCDEX wallet before its listing on major exchange platforms in the first quarter of 2022 so, get in while it’s still early.</p>
            
        </div>
        
      </div>
    </div>
  </div>

    </div>

    <hr>

</template>