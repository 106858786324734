<template>
    <ForgotPassword2Body/>
</template>
  
  <script>
  import { defineComponent } from "vue";
  import ForgotPassword2Body from "@/components/account/ForgotPassword2Body.vue";
  
  export default defineComponent({
    name: "ForgotPassword2View",
    components: {
      ForgotPassword2Body,
    },
  });
  </script>
  