<template>

    <div class="page">
        <SidebarBody/>
        <LockedStakingBody/>
    </div>
    
  </template>
  
  <script>
  import { defineComponent } from "vue";
  import SidebarBody from "@/components/dashboard/SidebarBody.vue";
  import LockedStakingBody from "@/components/dashboard/LockedStakingBody.vue";
  
  export default defineComponent({
    name: "LockedStakingView",
    components: {
      SidebarBody,
      LockedStakingBody
    },
  });
  </script>
  