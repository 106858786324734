<template>
    <Navbar/>
    <Blog6Body/>
    <Footer/>
  </template>
  
  <script>
  import { defineComponent } from "vue";
  import Navbar from "@/components/NavbarBody.vue";
  import Blog6Body from "@/components/blog/Blog6Body.vue";
  import Footer from "@/components/FooterBody.vue"; 
  
  export default defineComponent({
    name: "Blog6View",
    components: {
      Navbar,
      Blog6Body,
      Footer
    },
  });
  </script>
  