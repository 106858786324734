<template>
  <!-- BANNER -->

  <div class="fugu-about-section">
    <div class="container">
      <div class="row row-pad">
        <div class="col-xl-7 col-lg-6">
          <div class="fugu-about-thumb">
            <img
              src="@/assets/baseAssets/images/work-process2.png"
              class="rotation-img"
              alt="Work Process"
            />
          </div>
        </div>
        <div class="col-xl-5 col-lg-6 col-md-11 d-flex align-items-center">
          <div class="fugu-default-content">
            <h2 class="text-white">About OMCDEX</h2>
            <p class="text-white" style="font-size: 15px">
              We are building the perfect cryptoeconomy – a fair, accessible,
              efficient, and transparent financial system enabled by crypto. We
              started out with the radical idea that anyone, anywhere, should be
              able to easily and securely send and receive crypto through a
              decentralized system. Today, we offer a trusted and easy-to-use
              platform for accessing the broader cryptoeconomy. OMCDEX will
              bring the future of crypto-based financial and health freedom to
              millions.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <hr />

  <div class="fugu-counter-section">
    <div id="fugu-counter"></div>
    <div class="container">
      <div class="row">
        <div class="col-xl-7">
          <div class="fugu-counter-wrap">
            <div class="fugu-counter-data wow fadeInUpX" data-wow-delay=".10s">
              <h2>
                <span data-percentage="54" class="fugu-counter"></span
                ><strong>K</strong>
              </h2>
              <p>Verified users</p>
            </div>
            <div class="fugu-counter-data wow fadeInUpX" data-wow-delay=".20s">
              <h2>
                <span data-percentage="1.7" class="fugu-counter"></span
                ><strong>M</strong>
              </h2>
              <p>Quarterly volume traded</p>
            </div>
            <div class="fugu-counter-data wow fadeInUpX" data-wow-delay=".30s">
              <h2>
                <span data-percentage="6" class="fugu-counter"></span
                ><strong>M</strong>
              </h2>
              <p>Assets on platform</p>
            </div>
          </div>
        </div>
        <div class="col-xl-5 d-flex align-items-center">
          <div class="fugu-default-content content-white">
            <h2>Take a look at what we've accomplished</h2>
            <div class="fugu-counter-rating">
              <ul>
                <li>
                  <img src="@/assets/baseAssets/images/trustpilot.png" alt="" />
                </li>
                <li>
                  <img src="@/assets/baseAssets/images/star1.svg" alt="" />
                </li>
                <li>
                  <img src="@/assets/baseAssets/images/star1.svg" alt="" />
                </li>
                <li>
                  <img src="@/assets/baseAssets/images/star1.svg" alt="" />
                </li>
                <li>
                  <img src="@/assets/baseAssets/images/star1.svg" alt="" />
                </li>
                <li>
                  <img src="@/assets/baseAssets/images/star2.svg" alt="" />
                </li>

                <li>4/5 Rating</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <hr />

  <div class="section fugu-section-padding2">
    <div class="container">
      <div class="fugu-section-title2">
        <div class="row">
          <div class="col-lg-6">
            <h2 class="text-white">
              Our mission is to increase economic freedom in the world.
            </h2>
          </div>
          <div class="col-lg-5 offset-lg-1 d-flex align-items-center">
            <p class="text-white">
              Everyone deserves access to financial and health services that can
              help empower them to create a better life for themselves and their
              families. If the world economy ran on a common set of standards
              that could not be manipulated by any company or country, the world
              would be a more fair and free place, and human progress would
              accelerate. Our mission is used to spread the information about
              the virus to the masses and also used in funding medical supplies
              and the Covid-19 benefit funds to other parts of the world all
              through the blockchain. Also, as our name implies, we are in
              charge of the blockchain distribution of the OMCDEX Variant
              Relief Funds and Aid to Ukraine.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <hr />

  <div class="fugu-client-section">
    <div class="container">
      <div class="fugu-client-title text-white">
        <h5 class="text-white">
          Trusted by the world's most popular companies
        </h5>
      </div>
      <div class="fugu-client-slider">
        <div class="fugu-brand-logo">
          <img src="@/assets/baseAssets/images/client_icon_1.png" alt="" />
        </div>
        <div class="fugu-brand-logo">
          <img src="@/assets/baseAssets/images/client_icon_2.png" alt="" />
        </div>
        <div class="fugu-brand-logo">
          <img src="@/assets/baseAssets/images/client_icon_3.png" alt="" />
        </div>
        <div class="fugu-brand-logo">
          <img src="@/assets/baseAssets/images/client_icon_4.png" alt="" />
        </div>
        <div class="fugu-brand-logo">
          <img src="@/assets/baseAssets/images/client_icon_5.png" alt="" />
        </div>
        <div class="fugu-brand-logo">
          <img src="@/assets/baseAssets/images/client_icon_6.png" alt="" />
        </div>
        <div class="fugu-brand-logo">
          <img src="@/assets/baseAssets/images/client_icon_7.png" alt="" />
        </div>
      </div>
    </div>
  </div>

  <div class="fugu-go-top">
    <img src="@/assets/baseAssets/images/arrow-black-right.svg" alt="" />
  </div>

  <hr />
</template>

<style scoped>
.rotation-img {
  animation: rotation 15s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
