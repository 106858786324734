<template>
  <Navbar/>
  <HomeBody/>
  <Footer/>
</template>

<script>
import { defineComponent } from "vue";
import Navbar from "@/components/NavbarBody.vue";
import HomeBody from "@/components/HomeBody.vue";
import Footer from "@/components/FooterBody.vue"; 

export default defineComponent({
  name: "HomeView",
  components: {
    Navbar,
    HomeBody,
    Footer
  },
});
</script>
