<template>
    
    <div class="fugu-blog-section fugu-section-padding">

<div class="container">
  <div class="row">
    
    <div class="col-xl-8 col-lg-7" style="width: 100%; margin-bottom: 50px;">
      <div class="fugu-blog-single-wrap">
        <img class="wow fadeInUpX" src="@/assets/baseAssets/images/warren2.png" alt="">
        <div class="fugu-blog-meta">
          <ul>
            <li class="text-white">by David williams</li>
            <li class="text-white">December 16,2021</li>
          </ul>
        </div>
        <h3 class="text-white">Warren taking a bite of the Metaverse Buffet?</h3>

        <p class="text-white">The only easy way to earn 50% more than you are worth now is by honing communication skills, both written and verbal," said the legendary US investor and CEO of Berkshire Hathaway as an advice on how ‘young’ people should make twice the money they already make. Another way of saying byte metaverse, eh Warren?</p>

        <p class="text-white">The business tycoon having been in around the top richest people in the planet shows no sign of stopping as he looks for more innovative ways to increase his ‘portfolio’ (wink wink).</p>

        <p class="text-white">Oh his impending partnership with the OMCDEX project, he says ; “It is something we have been looking into for a while and is the perfect opportunity for us to enter the cryptoverse with the Solana network as our main entry”.</p>

        <p class="text-white">It’s glaring Mr. Buffet has done his homework on the leading NFT network , Ethereum looking to use the former’s lower (or next to nothing ) gas fees to oversee transactions on the said network. It remains to be seen on how high the OMCDEX token can go, but signs are promising .</p>

        <p class="text-white"></p>

        <p class="text-white"></p>
        
    </div>
    
  </div>
</div>
</div>

    </div>

    <hr>

</template>