<template>
    
    <div class="fugu-blog-section fugu-section-padding">

        <div class="container">
      <div class="row">
        
        <div class="col-xl-8 col-lg-7" style="width: 100%; margin-bottom: 50px;">
          <div class="fugu-blog-single-wrap">
            <img class="wow fadeInUpX" src="@/assets/baseAssets/images/CvsT.png" alt="">
            <div class="fugu-blog-meta">
              <ul>
                <li class="text-white">by Racheal Adams, SurvivorNet journalist</li>
                <li class="text-white">December 18,2021</li>
              </ul>
            </div>
            <h3 class="text-white">Crypto coin vs Crypto Token</h3>

            <p class="text-white">We are all guilty of using the terms ‘coin’ and ‘token’ interchangeably at some point in our journey in the crypto world; some of us still do(as long as it makes us money, who cares?). However, it’s important to know the difference between these two concepts to avoid misuse.</p>

            <h5 class="text-white">Coins</h5>

            <p class="text-white">Coins (that are also often called altcoins or alternative cryptocurrency coins) are digital money, created using encryption techniques, that store value over time. Basically it is a digital equivalent of money. A major example is the Bitcoin which was created to replace the traditional money with its anonymity in transactions the major selling point.</p>

            <p class="text-white">Coins exist as a unit of value- on its own independent ledger. Some cryptocurrency coins, such as Bitcoin, are limited in supply. Their value is, therefore, influenced by the basic principle of supply and demand.</p>

            <p class="text-white">A coin also operates on its own blockchain—</p>

            <p class="text-white">A blockchain keeps track of all transactions that involve its native crypto coin.  An example is When you pay someone with Ethereum, the receipt goes to the Ethereum blockchain.</p>

            <p class="text-white">It’s safe to say that decentralized crypto wallets aren’t really for beginners, as you’ll need a certain level of expertise to maintain them securely. But chances are, the more you get into cryptocurrency, the more appealing decentralized wallets will become.</p>

            <h5 class="text-white">Tokens</h5>

            <p class="text-white">Tokens are digital assets, issued by the project, which can be used as a method of payment inside project’s ecosystem, performing similar functions with coins, but the main difference is that it also gives the holder a right to participate in the network.

            </p>

            <p class="text-white">It may perform the functions of digital asset, represent a company’s share, give access to the project’s functional and many more. Crypto tokens can represent an investor's stake in the company or they can serve an economic purpose, just like legal tender. This means token holders can use them to make purchases or they can trade tokens just like other securities to make a profit.</p>

            <p class="text-white">Simply put, although a cryptocurrency token can act as a form of payment, its primary purpose is to be used within a blockchain platform’s wider ecosystem.</p>

            <p class="text-white">Unlike coins, tokens do not have their blockchain. Instead, they operate on other crypto coins' blockchains, such as Ethereum (BAT, BNT, Tether)</p>

            <h5 class="text-white">How do they differ?</h5>

            <p class="text-white">By its very nature, a crypto token is simpler to create than a cryptocurrency coin. To form a new token, a developer can simply follow a template approach on their chosen blockchain, i.e., Ethereum or the NEO platform.</p>

            <p class="text-white">Typically, crypto tokens are programmable, permissionless, trustless, and transparent. Programmable simply means that they run on software protocols, which are composed of smart contracts that outline the features and functions of the token and the network’s rules of engagement. Permissionless means that anyone can participate in the system without the need for special credentials. Trustless means that no one central authority controls the system; instead it runs on the rules predefined by the network protocol. And finally, transparency implies that the rules of the protocol and its transactions are viewable and verifiable by all.</p>

            <p class="text-white">While crypto tokens, like cryptocurrency, can hold value and be exchanged, they can also be designed to represent physical assets or more traditional digital assets, or a certain utility or service. For instance, there are crypto tokens that represent tangible assets such as real estate and art, as well as intangible assets such as processing power or data storage space. Tokens are also frequently used as a governance mechanism for voting on specific parameters like protocol upgrades and other decisions that dictate the future direction of various blockchain projects. The process of creating crypto tokens to serve these various functions is known as tokenization.</p>

            <p class="text-white">When a token is spent, it physically moves from one place to another. This is different from coins because crypto coins do not move around; only account balances change. When you transfer money from your bank to someone else's, your money doesn't go anywhere. The bank changed the balances of both accounts and kept the fees. The same thing happens with blockchain - the balance in your wallet changes, and the transaction notes that.</p>

            <p class="text-white">Coins are related in a way that tokens operate on the blockchain of crypto coins. The most common platform for cryptocurrency tokens is Ethereum (coin). Typically, cryptocurrency token created using the Ethereum platform is known as an ERC20 token. These ERC20 tokens can be stored and distributed between Ethereum addresses.</p>

            <h5 class="text-white">Summary</h5>

            <p class="text-white">Coins are just method of payment while tokens may present a company’s share, give access to product or service and perform many other functions. Coins are currencies that can be used for buying and selling things. You can buy a token with a coin, but not vice versa. Coin operates independently, while token has a specific use in the project’s ecosystem.</p>
            
        </div>
        
      </div>
    </div>
    </div>

    </div>

    <hr>

</template>