<template>
    <Navbar/>
    <ContactBody/>
    <Footer/>
  </template>
  
  <script>
  import { defineComponent } from "vue";
  import Navbar from "@/components/NavbarBody.vue";
  import ContactBody from "@/components/ContactBody.vue";
  import Footer from "@/components/FooterBody.vue"; 
  
  export default defineComponent({
    name: "ContactView",
    components: {
      Navbar,
      ContactBody,
      Footer
    },
  });
  </script>
  