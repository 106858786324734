<template>
    
    <div class="page__content">

        <HeaderBody/>

        <div class="activity" >
                    <div class="activity__container">
                        
                        <div class="activity__sorting">

                            <div class="activity__tags tablet-hide">
                                <router-link class="activity__link " :to="{name: 'locked-staking'}" id="rStaking11">Locked staking</router-link>
                                <router-link class="activity__link active" :to="{name: 'flexible-staking'}" id="rStaking12">Flexible staking</router-link>
                                <router-link class="activity__link" :to="{name: 'staking-rewards'}" id="rStaking13">Rewards</router-link>
                                
                            </div>

                            <div class="activity__tags tablet-show">
                                <router-link class="activity__link" :to="{name: 'locked-staking'}" id="rStaking21"  style="padding: 10px 15px 10px 15px;">Locked staking</router-link>
                                <router-link class="activity__link active"  :to="{name: 'flexible-staking'}" id="rStaking22" style="padding: 10px 15px 10px 15px;">Flexible staking</router-link>
                                <router-link class="activity__link"  :to="{name: 'staking-rewards'}" id="rStaking23" style="padding: 10px 15px 10px 15px;">Rewards</router-link>
                                
                            </div>
                            
                        </div>
                        
                        
                    </div>
                    
        </div>

        <div class="promotions">
                    <div class="promotions__wrapper">

                        <div class="search" style="margin-top: -40px; margin-bottom: 0px;">
                            <div class="search__container" >
                                <div class="search__wrap">
                                    <div class="search__title h3" style="color:#ffffff">Flexible Staking</div>
                                    <div class="search__info" style="margin-bottom: 5px;">OMCDEX flexible staking allows you to redeem your staked assets at any time. When you choose to redeem, we'll return your assets to your Spot wallet.</div>
                                    
                                    <div class="search__info" style="margin-bottom: 5px; font-weight: 700;">The minimum amount required for flexible staking is $1000</div>
                                </div>
                                
                                <div class="search__preview">
                                    <img src="@/assets/dashAssets/img/figures-23.png" alt="">
                                </div>
                            </div>
                        </div>
                        
                        <br>
                        <hr>
                        <br>
                        <br>
                        <br>

                        
                        <div class="promotions__list">

                            
                            <div class="promotions__item" v-for="stake in stakingList" :key="stake.symbol">
                                <a class="promotions__preview" style="width: 100%; display:flex; justify-content:space-around; align-items:center">
                                    <img :src="stake.image" alt="" style="width: 70px; margin-top: 10px; border-radius: 50%;">
                                </a>
                                

                                <div style="width: 100%; display:flex; justify-content:space-around; align-items:center; flex-direction: column; margin-top: 5px;">
                                    <p>{{stake.name}}</p>
                                    
                                </div>

                                <div class="promotions__body">
                                    
                                    <div class="operations__field field js-field">
                                        <div class="popup__label">APY</div>
                                        <div class="field__wrap">
                                            <div class="promotions__date bg-green-opacity color-green" style="font-size:14px">{{stake.apy}}%</div>
                                        </div>
                                    </div>

                                    <div class="operations__field field js-field">
                                        <div class="popup__label">Amount</div>
                                        <div class="field__wrap">
                                            <input class="field__input js-field-input" type="number" placeholder="$0.00" style="text-align: center; font-size: 30px;" :id="stake.name+'Input'">
                                        </div>
                                    </div>

                                    <br>
                                    
                                    <button class="details__btn btn btn_blue btn_wide" style="margin-bottom:10px" @click="stakeN(stake.name, stake.image, stake.symbol, stake.apy ,stake.name+'Input')">Stake {{stake.symbol}}</button>
                                   
                                </div>
                            </div>
                            
                        </div>
                        
                    </div>
        </div>

    </div>

        <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="loadingModal">

<div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
            
    <div class="popup__box">

        <div class="popup__fieldset" >
            
            <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                
                <img src="@/assets/dashAssets/img/loading.gif" alt="">

                <p style="text-align: center; color: #ffffff;">{{loadingText}}</p>
                
            </div>

            
            
            
        </div>
        

        

        
    </div>
    
</div>

        </div>

        <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="successModal">

        <div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
                    
            <div class="popup__box">

                <div class="popup__fieldset" >
                    
                    <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                        
                        <img src="@/assets/dashAssets/img/done.png" alt="" style="width: 100px; margin: 10px;">

                        <p style="text-align: center; margin-bottom: 40px; color: #ffffff;">{{successText}}</p>

                        <button class="operations__btn btn btn_blue" @click="closeS">Okay</button>
                        
                    </div>

                    
                    
                    
                    
                </div>
                

                

                
            </div>
            
        </div>

        </div>

        <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="errorModal">

        <div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
                    
            <div class="popup__box">

                <div class="popup__fieldset" >
                    
                    <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                        
                        <img src="@/assets/dashAssets/img/error.png" alt="" style="width: 100px; margin: 10px;">

                        <p style="text-align: center; margin-bottom: 40px; color: #ffffff;">{{errorText}}</p>

                        <button class="operations__btn btn btn_blue" @click="closeE">Okay</button>
                        
                    </div>

                    
                    
                    
                </div>
                

                

                
            </div>
            
        </div>

        </div>

</template>

<style scoped>
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: grey;
    opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: grey;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
    color: grey;
    }

    

</style>

<script>
    import { doc, addDoc, collection, query, where, getDocs, serverTimestamp, updateDoc } from "firebase/firestore"; 
    import { db } from '@/firebase';
    import axios from 'axios';
    import HeaderBody from '@/components/dashboard/HeaderBody.vue';
    export default {

        components:{
            HeaderBody,
        },

        data(){
            return{
                loadingText:"",
                successText: "",
                errorText: "",
                stakingList: [],
            }
        },

        mounted(){

            //toggle sidebar active
            document.getElementById("rWallet").classList.remove("active");
            document.getElementById("rSwap").classList.remove("active");
            document.getElementById("rStaking").classList.add("active");
            document.getElementById("rTrans").classList.remove("active");
            document.getElementById("rCard").classList.remove("active");

            this.getStakingInfo();

        },

        methods:{

            round(num, decimals){
                return +(Math.round(num + "e" + decimals) + "e-" + decimals);
            },

            closeS(){
                    this.$refs.successModal.classList.remove("bg-active");
                    this.$router.go();
            },

            closeE(){
                this.$refs.errorModal.classList.remove("bg-active");
            },

            async getStakingInfo(){
                //start loading
                this.loadingText= "Loading...";
                this.$refs.loadingModal.classList.add("bg-active"); 

                let i = -1;
                const q = query(collection(db, "AdminFStaking")); 
                const querySnapshot = await getDocs(q);
                
                querySnapshot.forEach((doc) => {
                    i++;

                    this.stakingList[i] = {
                        name: doc.data().name,
                        symbol: doc.data().symbol,
                        image: doc.data().image,
                        apy: Number(doc.data().apy),
                    }
                    
                })

                this.$refs.loadingModal.classList.remove("bg-active"); 
            },

            async stakeN(name, image, symbol, apy, amountId){

                //start loading
                this.loadingText= "Staking "+symbol+"...";
                this.$refs.loadingModal.classList.add("bg-active");

                let  balance, walletId, newBalance;
                let amount = 0;
                let price = 0;
                let lockedAmount = 0;

                amount = document.getElementById(""+amountId).value;

                await axios.get('https://min-api.cryptocompare.com/data/pricemultifull?fsyms='+symbol+'&tsyms=USD')
                .then( response => {

                    price = Number(Object.values(response.data.RAW)[0].USD.PRICE);
                    //console.log("PRICE: "+price)
                
                });

                if(Number(amount) < 1000 || Number(amount) == ""){

                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Minimum amount is $1000";
                    return;

                }

                lockedAmount = amount / price;

                //check balance
                const q = query(collection(db, "UserWallets"), where("userId", "==", sessionStorage.getItem("id")), where("symbol", "==", symbol));

                const querySnapshot = await getDocs(q);

                querySnapshot.forEach((doc) => {

                    balance = doc.data().balance;
                    walletId = doc.id;

                })

                if(Number(balance) < Number(lockedAmount) ){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Insufficient "+symbol+" balance";
                    return;
                }

                newBalance = balance - lockedAmount;

                const wRef = doc(db, "UserWallets", String(walletId));
                await updateDoc(wRef, {
                    balance: this.round(Number(newBalance), 7)
                });

                //upload transaction
                await addDoc(collection(db, "UserStakings"), {
                    type: "Flexible staking",
                    name: name,
                    image: image,
                    symbol: symbol,
                    walletId: walletId,
                    userId: sessionStorage.getItem("id"),
                    apy: Number(apy),
                    lockedPeriod: 0,
                    lockedAmount: this.round(Number(lockedAmount), 7),
                    reward: 0,
                    startDate: serverTimestamp(),
                    endDate: 0,
                  
                    status: "in progress"
                });

                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.successModal.classList.add("bg-active");
                this.successText = symbol+" has been staked successfully";

            }
        }
    }
</script>