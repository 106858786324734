<template>
    
    <div class="fugu--inner-section">
    <div class="container" >
      <div class="fugu--breadcrumbs-section" >
        <div class="fugu--breadcrumbs-data center-content">
          <h1 class="wow fadeInUpX text-white" data-wow-delay="0s">Blog & Resources</h1>
          <p class="wow fadeInUpX text-white" data-wow-delay="0.10s">Your source of crypto market analysis, news, developments, and project reviews. Discover and keep up to date with the latest crypto news and events.</p>
         
        </div>
      </div>
      <!-- End breadcrumb -->
      
      <!-- End blog top -->
      <div class="fugu-blog-section fugu-section-padding" style="margin-top: -100px;">
        <div class="container">
          <div class="row">
            <div class="col-xl-8 col-lg-7" style="width: 100%;">
              <div class="row" >

                <div class="col-xl-6 col-lg-12 col-md-6" >
                  <div class="fugu-blog-card wow fadeInUpX" data-wow-delay="0s" style="background-color: #6F2880;">
                    <div class="fugu-blog-thumb">
                      <router-link :to="{name: 'blog1'}">
                        <img src="@/assets/baseAssets/images/warren2.png" alt="">
                      </router-link>
                    </div>
                    <div class="fugu-blog-content">
                      <div class="fugu-blog-meta">
                        <ul>
                          <li><router-link :to="{name: 'blog1'}" class="text-white">by David williams</router-link></li>
                          <li><router-link :to="{name: 'blog1'}" class="text-white">December 16,2021</router-link></li>
                        </ul>
                      </div>
                      <h5>
                        <router-link :to="{name: 'blog1'}" class="text-white">
                            Warren taking a bite of the Metaverse Buffet?
                        </router-link>
                      </h5>
                      <p class="text-white">It’s glaring Mr. Buffet has done his homework on the leading NFT network , Ethereum looking to use the former’s lower (or next to nothing ) gas fees to oversee transactions on the said network.</p>
                      <router-link class="fugu-icon-btn text-white" :to="{name: 'blog1'}">Read More <img src="@/assets/baseAssets/images/arrow-black-right.svg" alt=""></router-link>
                    </div>
                  </div>
                </div>

                <div class="col-xl-6 col-lg-12 col-md-6" >
                    <div class="fugu-blog-card wow fadeInUpX" data-wow-delay="0s" style="background-color: #6F2880;">
                      <div class="fugu-blog-thumb">
                        <router-link :to="{name: 'blog2'}">
                          <img src="@/assets/baseAssets/images/metaverse.png" alt="">
                        </router-link>
                      </div>
                      <div class="fugu-blog-content">
                        <div class="fugu-blog-meta">
                          <ul>
                            <li><router-link :to="{name: 'blog2'}" class="text-white">by Philip Miller</router-link></li>
                            <li><router-link :to="{name: 'blog2'}" class="text-white">December 22,2021</router-link></li>
                          </ul>
                        </div>
                        <h5>
                          <router-link :to="{name: 'blog2'}" class="text-white">
                            The OMCDEX Metaverse (Omniverse)
                          </router-link>
                        </h5>
                        <p class="text-white">The word 'metaverse' catapulted into the collective consciousness of the world after Facebook recently announced it was rebranding itself as Meta. </p>
                        <router-link class="fugu-icon-btn text-white" :to="{name: 'blog2'}">Read More <img src="@/assets/baseAssets/images/arrow-black-right.svg" alt=""></router-link>
                      </div>
                    </div>
                </div>

                <div class="col-xl-6 col-lg-12 col-md-6" >
                    <div class="fugu-blog-card wow fadeInUpX" data-wow-delay="0s" style="background-color: #6F2880;">
                      <div class="fugu-blog-thumb">
                        <router-link :to="{name: 'blog3'}">
                          <img src="@/assets/baseAssets/images/decentraWallet1.png" alt="">
                        </router-link>
                      </div>
                      <div class="fugu-blog-content">
                        <div class="fugu-blog-meta">
                          <ul>
                            <li><router-link :to="{name: 'blog3'}" class="text-white">by Steve Baker</router-link></li>
                            <li><router-link :to="{name: 'blog3'}" class="text-white">December 20,2021</router-link></li>
                          </ul>
                        </div>
                        <h5>
                          <router-link :to="{name: 'blog3'}" class="text-white">
                            The Decentralized wallet
                          </router-link>
                        </h5>
                        <p class="text-white">Decentralised wallets, also labelled as DeFi wallets, are the gateways into these vast ecosystems of decentralised applications otherwise known as dApps. </p>
                        <router-link class="fugu-icon-btn text-white" :to="{name: 'blog3'}">Read More <img src="@/assets/baseAssets/images/arrow-black-right.svg" alt=""></router-link>
                      </div>
                    </div>
                </div>

                <div class="col-xl-6 col-lg-12 col-md-6" >
                    <div class="fugu-blog-card wow fadeInUpX" data-wow-delay="0s" style="background-color: #6F2880;">
                      <div class="fugu-blog-thumb">
                        <router-link :to="{name: 'blog4'}">
                          <img src="@/assets/baseAssets/images/CvsT.png" alt="">
                        </router-link>
                      </div>
                      <div class="fugu-blog-content">
                        <div class="fugu-blog-meta">
                          <ul>
                            <li><router-link :to="{name: 'blog4'}" class="text-white">by Racheal Adams</router-link></li>
                            <li><router-link :to="{name: 'blog4'}" class="text-white">December 18,2021</router-link></li>
                          </ul>
                        </div>
                        <h5>
                          <router-link :to="{name: 'blog4'}" class="text-white">
                            Crypto coin vs Crypto Token
                          </router-link>
                        </h5>
                        <p class="text-white">We are all guilty of using the terms ‘coin’ and ‘token’ interchangeably at some point in our journey in the crypto world; some of us still do(as long as it makes us money, who cares?). </p>
                        <router-link class="fugu-icon-btn text-white" :to="{name: 'blog4'}">Read More <img src="@/assets/baseAssets/images/arrow-black-right.svg" alt=""></router-link>
                      </div>
                    </div>
                </div>

                <div class="col-xl-6 col-lg-12 col-md-6" >
                    <div class="fugu-blog-card wow fadeInUpX" data-wow-delay="0s" style="background-color: #6F2880;">
                      <div class="fugu-blog-thumb">
                        <router-link :to="{name: 'blog5'}">
                          <img src="@/assets/baseAssets/images/icoB.png" alt="">
                        </router-link>
                      </div>
                      <div class="fugu-blog-content">
                        <div class="fugu-blog-meta">
                          <ul>
                            <li><router-link :to="{name: 'blog5'}" class="text-white">by Abigail Seaberg</router-link></li>
                            <li><router-link :to="{name: 'blog5'}" class="text-white">30 jul 2022</router-link></li>
                          </ul>
                        </div>
                        <h5>
                          <router-link :to="{name: 'blog5'}" class="text-white">
                            Best ICO 2022 and Top 5 Cryptocurrencies for this Year
                          </router-link>
                        </h5>
                        <p class="text-white">Whether we like it or not, the crypto world has changed significantly. It’s not just about market caps and price changes anymore. </p>
                        <router-link class="fugu-icon-btn text-white" :to="{name: 'blog5'}">Read More <img src="@/assets/baseAssets/images/arrow-black-right.svg" alt=""></router-link>
                      </div>
                    </div>
                </div>

                <div class="col-xl-6 col-lg-12 col-md-6" >
                    <div class="fugu-blog-card wow fadeInUpX" data-wow-delay="0s" style="background-color: #6F2880;">
                      <div class="fugu-blog-thumb">
                        <router-link :to="{name: 'blog6'}">
                          <img src="@/assets/baseAssets/images/warren3.png" alt="">
                        </router-link>
                      </div>
                      <div class="fugu-blog-content">
                        <div class="fugu-blog-meta">
                          <ul>
                            <li><router-link :to="{name: 'blog6'}" class="text-white">by Abigail Seaberg</router-link></li>
                            <li><router-link :to="{name: 'blog6'}" class="text-white">13 Feb 2022</router-link></li>
                          </ul>
                        </div>
                        <h5>
                          <router-link :to="{name: 'blog6'}" class="text-white">
                            Solana based NFT/metaverse token attracting whales
                          </router-link>
                        </h5>
                        <p class="text-white">The NFT market is currently attracting a lot of BIG WHALES especially Solana-based tokens(due to the fact that it has little to no gas fees), and these unlisted tokens because of the ambiguous funding by investors is giving them room for growth and a lot of potential in the crypto-verse.</p>
                        <router-link class="fugu-icon-btn text-white" :to="{name: 'blog6'}">Read More <img src="@/assets/baseAssets/images/arrow-black-right.svg" alt=""></router-link>
                      </div>
                    </div>
                </div>

                <div class="col-xl-6 col-lg-12 col-md-6" >
                    <div class="fugu-blog-card wow fadeInUpX" data-wow-delay="0s" style="background-color: #6F2880;">
                      <div class="fugu-blog-thumb">
                        <router-link :to="{name: 'blog7'}">
                          <img src="@/assets/baseAssets/images/blog7Img.png" alt="">
                        </router-link>
                      </div>
                      <div class="fugu-blog-content">
                        <div class="fugu-blog-meta">
                          <ul>
                            <li><router-link :to="{name: 'blog7'}" class="text-white">by Adam Stonewall</router-link></li>
                            <li><router-link :to="{name: 'blog7'}" class="text-white">25 Dec 2023</router-link></li>
                          </ul>
                        </div>
                        <h5>
                          <router-link :to="{name: 'blog7'}" class="text-white">
                            OMCDEX Exclusive Christmas Promo
                          </router-link>
                        </h5>
                        <p class="text-white">Tis the season to be jolly, and at OMCDEX, we're thrilled to bring a dash of crypto magic to your Christmas festivities! As a token of our appreciation for your continued support, we're offering you a chance to win 3 mystery boxes and a share of ISO 20022 tokens worth $80,000. It's our way of saying thank you for being a valued part of the OMCDEX family.</p>
                        <router-link class="fugu-icon-btn text-white" :to="{name: 'blog7'}">Read More <img src="@/assets/baseAssets/images/arrow-black-right.svg" alt=""></router-link>
                      </div>
                    </div>
                </div>
                
              </div>

             

            </div>
            
          </div>
        </div>
      </div>


    </div>
    </div>

    <!-- End cta section -->

    <div class="fugu-go-top">
        <img src="@/assets/baseAssets/images/arrow-black-right.svg" alt="">
    </div>

    <hr>

</template>