<template>
    
    <!-- BANNER -->
    <div class="fugu-hero-section hm" >
        <div class="container">
        <div class="row">
            <div class="col-xl-6 col-lg-7">
            <div class="fugu-hero-content">
                <h2 class="wow fadeInUpX text-white" data-wow-delay="0s" >Earn interest on your crypto assets</h2><br>
                <p class="wow fadeInUpX text-white" data-wow-delay="0.25s" > While buying crypto and HODLing it until the price goes up is a great option for turning a profit, it requires lots of time and a bit of luck. With OMCDEX staking, you can put your idle assets to work straight away and have a predictable source of passive income. Just top up and start earning annual interest immediately. No further action is needed on your side.</p>
            
                <div class="fugu-btn-wrap">
                
                    <router-link class="fugu-btn" :to="{name: 'sign-up'}" target="_blank" style="color: #ffffff;">Get Started</router-link>
                    
                </div>

                

            </div>
            </div>
            <div class="col-xl-6 col-lg-5" >
            <div class="fugu-hero-thumb" >
                <img src="@/assets/baseAssets/images/hand-mocup.png" alt="">
                <div class="fugu-shape2">
                <img src="@/assets/baseAssets/images/shape2.png" alt="">
                </div>
                <div class="fugu-hero-star">
                <img src="@/assets/baseAssets/images/star-shape.png" alt="">
                </div>
            </div>
            </div>
        </div>
        </div>
        <div class="fugu-shape1">
        <img src="@/assets/baseAssets/images/shape1.png" alt="">
        </div>
    </div>

    <hr>

    <div class="fugu-client-section" style="background-color: #120130;">
        <div class="container">
        <div class="fugu-client-title text-white">
            <h5 class="text-white">Trusted by the world's most popular companies</h5>
        </div>
        <div class="fugu-client-slider">
            <div class="fugu-brand-logo">
            <img src="@/assets/baseAssets/images/client_icon_1.png" alt="">
            </div>
            <div class="fugu-brand-logo">
            <img src="@/assets/baseAssets/images/client_icon_2.png" alt="">
            </div>
            <div class="fugu-brand-logo">
            <img src="@/assets/baseAssets/images/client_icon_3.png" alt="">
            </div>
            <div class="fugu-brand-logo">
            <img src="@/assets/baseAssets/images/client_icon_4.png" alt="">
            </div>
            <div class="fugu-brand-logo">
            <img src="@/assets/baseAssets/images/client_icon_5.png" alt="">
            </div>
            <div class="fugu-brand-logo">
            <img src="@/assets/baseAssets/images/client_icon_6.png" alt="">
            </div>
            <div class="fugu-brand-logo">
            <img src="@/assets/baseAssets/images/client_icon_7.png" alt="">
            </div>
            
        </div>
        </div>
    </div>

    <hr>

    <div class="fugu-testimonial-section fugu-section-padding2">
        <div class="container">
        <div class="fugu-section-title title-large">
            <h2 class="text-white">Flexible staking</h2>
            <p class="text-white">Want to level up your crypto journey? Unlock the Auto Flexible staking feature on OMCDEX wallet and earn your APR holding or trading Metaverse Tokens. Flexible Staking is a low-risk way to earn with your crypto in your spot wallet. Your crypto earns it's APR sitting or trading in your OMCDEX Spot wallet and can be withdrawn at users convenience.</p>
        </div>
        

        <div class="row" style="display: flex; justify-content:center; align-items:center">

            <div class="feature-card" style="height: 300px; margin-top: 0px;">
                <div style="margin-top: -70px;">
                    <!--HEADER-->
                    <div style="display: flex; justify-content: flex-start; align-items: center; flex-direction: column; ">
                        <div class="feature-icon">
                            <img src="@/assets/dashAssets/img/coins/hbar.png" alt="" style="width: 150px;">
                        </div>
                        <div style="margin-left: 5px;">
                            <p class="feature-title pb-15 text-white" style="font-size: 20px; margin-top: 10px;">Hedera (HBAR)</p>
                            <p class="feature-des" style=" text-align: center; color: #4FBF67;">45% APY</p>
                        </div>
                    </div>
                    
                </div>

                
            </div>

            <div class="feature-card" style="height: 300px; margin-top: 0px;">
                <div style="margin-top: -70px;">
                    <!--HEADER-->
                    <div style="display: flex; justify-content: flex-start; align-items: center; flex-direction: column; ">
                        <div class="feature-icon">
                            <img src="@/assets/dashAssets/img/coins/sandbox.png" alt="" style="width: 150px;">
                        </div>
                        <div style="margin-left: 5px;">
                            <p class="feature-title pb-15 text-white" style="font-size: 20px; margin-top: 10px;">Sandbox (SAND)</p>
                            <p class="feature-des" style=" text-align: center; color: #4FBF67;">37.53% APY</p>
                        </div>
                    </div>
                    
                </div>

                
            </div>

            <div class="feature-card" style="height: 300px; margin-top: 0px;">
                <div style="margin-top: -70px;">
                    <!--HEADER-->
                    <div style="display: flex; justify-content: flex-start; align-items: center; flex-direction: column; ">
                        <div class="feature-icon">
                            <img src="@/assets/dashAssets/img/coins/enj.png" alt="" style="width: 150px;">
                        </div>
                        <div style="margin-left: 5px;">
                            <p class="feature-title pb-15 text-white" style="font-size: 20px; margin-top: 10px;">Enjin (ENJ)</p>
                            <p class="feature-des" style=" text-align: center; color: #4FBF67;">33.46% APY</p>
                        </div>
                    </div>
                    
                </div>

                
            </div>

            <div class="feature-card" style="height: 300px; margin-top: 0px;">
                <div style="margin-top: -70px;">
                    <!--HEADER-->
                    <div style="display: flex; justify-content: flex-start; align-items: center; flex-direction: column; ">
                        <div class="feature-icon">
                            <img src="@/assets/dashAssets/img/coins/gala.png" alt="" style="width: 150px;">
                        </div>
                        <div style="margin-left: 5px;">
                            <p class="feature-title pb-15 text-white" style="font-size: 20px; margin-top: 10px;">Gala (GALA)</p>
                            <p class="feature-des" style=" text-align: center; color: #4FBF67;">27% APY</p>
                        </div>
                    </div>
                    
                </div>

                
            </div>

            <div class="feature-card" style="height: 300px; margin-top: 0px;">
                <div style="margin-top: -70px;">
                    <!--HEADER-->
                    <div style="display: flex; justify-content: flex-start; align-items: center; flex-direction: column; ">
                        <div class="feature-icon">
                            <img src="@/assets/dashAssets/img/coins/decentraland.png" alt="" style="width: 150px;">
                        </div>
                        <div style="margin-left: 5px;">
                            <p class="feature-title pb-15 text-white" style="font-size: 20px; margin-top: 10px;">Decentraland (MANA)</p>
                            <p class="feature-des" style=" text-align: center; color: #4FBF67;">23.75% APY</p>
                        </div>
                    </div>
                    
                </div>

                
            </div>

            

            
            
        </div>

        

        </div>
    </div>

    <hr>

    <div class="fugu-testimonial-section fugu-section-padding2">
        <div class="container">
        <div class="fugu-section-title title-large">
            <h2 class="text-white">Locked staking</h2>
            <p class="text-white">OMCDEX locked staking is a process where you lock your crypto for a certain amount of days, and as compensation, you will generate an annual interest yield. Locked staking is one part of the complete OMCDEX Earn feature, which includes many different possibilities to generate passive income from crypto.</p>
        </div>
        

        <div class="row" style="display: flex; justify-content:center; align-items:center">

            <div class="feature-card" style="height: 300px; margin-top: 0px;">
                <div style="margin-top: -70px;">
                    <!--HEADER-->
                    <div style="display: flex; justify-content: flex-start; align-items: center; flex-direction: column; ">
                        <div class="feature-icon">
                            <img src="@/assets/dashAssets/img/coins/ada.png" alt="" style="width: 150px;">
                        </div>
                        <div style="margin-left: 5px;">
                            <p class="feature-title pb-15 text-white" style="font-size: 20px; margin-top: 10px;">Cardano (ADA)</p>
                            <p class="feature-des" style=" text-align: center; color: #4FBF67; margin-top: 0px;">8.35% ~ 52.20% APY</p>
                        </div>
                    </div>
                    
                </div>

                
            </div>

            <div class="feature-card" style="height: 300px; margin-top: 0px;">
                <div style="margin-top: -70px;">
                    <!--HEADER-->
                    <div style="display: flex; justify-content: flex-start; align-items: center; flex-direction: column; ">
                        <div class="feature-icon">
                            <img src="@/assets/dashAssets/img/coins/eth.png" alt="" style="width: 150px;">
                        </div>
                        <div style="margin-left: 5px;">
                            <p class="feature-title pb-15 text-white" style="font-size: 20px; margin-top: 10px;">Ethereum (ETH)</p>
                            <p class="feature-des" style=" text-align:center; color: #4FBF67; margin-top: 0px;">7.68% ~ 22.50% APY</p>
                        </div>
                    </div>
                    
                </div>

                
            </div>

            <div class="feature-card" style="height: 300px; margin-top: 0px;">
                <div style="margin-top: -70px;">
                    <!--HEADER-->
                    <div style="display: flex; justify-content: flex-start; align-items: center; flex-direction: column; ">
                        <div class="feature-icon">
                            <img src="@/assets/dashAssets/img/coins/omicron.png" alt="" style="width: 150px;">
                        </div>
                        <div style="margin-left: 5px;">
                            <p class="feature-title pb-15 text-white" style="font-size: 20px; margin-top: 10px;">OMCDEX (OMC)</p>
                            <p class="feature-des" style="text-align:center; color: #4FBF67; margin-top: 0px;">9.62% ~ 70.85% APY</p>
                        </div>
                    </div>
                    
                </div>

                
            </div>

            <div class="feature-card" style="height: 300px; margin-top: 0px;">
                <div style="margin-top: -70px;">
                    <!--HEADER-->
                    <div style="display: flex; justify-content: flex-start; align-items: center; flex-direction: column; ">
                        <div class="feature-icon">
                            <img src="@/assets/dashAssets/img/coins/sol.png" alt="" style="width: 150px;">
                        </div>
                        <div style="margin-left: 5px;">
                            <p class="feature-title pb-15 text-white" style="font-size: 20px; margin-top: 10px;">Solana (SOL)</p>
                            <p class="feature-des" style="text-align:center; color: #4FBF67; margin-top: 0px;">12.26% ~ 54.80% APY</p>
                        </div>
                    </div>
                    
                </div>

                
            </div>

            <div class="feature-card" style="height: 300px; margin-top: 0px;">
                <div style="margin-top: -70px;">
                    <!--HEADER-->
                    <div style="display: flex; justify-content: flex-start; align-items: center; flex-direction: column; ">
                        <div class="feature-icon">
                            <img src="@/assets/dashAssets/img/coins/xrp.png" alt="" style="width: 150px;">
                        </div>
                        <div style="margin-left: 5px;">
                            <p class="feature-title pb-15 text-white" style="font-size: 20px; margin-top: 10px;">Ripple (XRP)</p>
                            <p class="feature-des" style="margin-top: 0px; text-align: center; color: #4FBF67;">5.50% ~ 27.50% APY</p>
                        </div>
                    </div>
                    
                </div>

                
            </div>

            <div class="feature-card" style="height: 300px; margin-top: 0px;">
                <div style="margin-top: -70px;">
                    <!--HEADER-->
                    <div style="display: flex; justify-content: flex-start; align-items: center; flex-direction: column; ">
                        <div class="feature-icon">
                            <img src="@/assets/dashAssets/img/coins/algo.png" alt="" style="width: 150px;">
                        </div>
                        <div style="margin-left: 5px;">
                            <p class="feature-title pb-15 text-white" style="font-size: 20px; margin-top: 10px;">Algorand (ALGO)</p>
                            <p class="feature-des" style="margin-top: 0px; text-align: center; color: #4FBF67;">7.24% ~ 33.82% APY</p>
                        </div>
                    </div>
                    
                </div>

                
            </div>

            <div class="feature-card" style="height: 300px; margin-top: 0px;">
                <div style="margin-top: -70px;">
                    <!--HEADER-->
                    <div style="display: flex; justify-content: flex-start; align-items: center; flex-direction: column; ">
                        <div class="feature-icon">
                            <img src="@/assets/dashAssets/img/coins/crypto_com.png" alt="" style="width: 150px;">
                        </div>
                        <div style="margin-left: 5px;">
                            <p class="feature-title pb-15 text-white" style="font-size: 20px; margin-top: 10px;">Crypto.com (CRO)</p>
                            <p class="feature-des" style="margin-top: 0px; text-align: center; color: #4FBF67;">6.31% ~ 41.55% APY</p>
                        </div>
                    </div>
                    
                </div>

                
            </div>

            <div class="feature-card" style="height: 300px; margin-top: 0px;">
                <div style="margin-top: -70px;">
                    <!--HEADER-->
                    <div style="display: flex; justify-content: flex-start; align-items: center; flex-direction: column; ">
                        <div class="feature-icon">
                            <img src="@/assets/dashAssets/img/coins/quant.png" alt="" style="width: 150px;">
                        </div>
                        <div style="margin-left: 5px;">
                            <p class="feature-title pb-15 text-white" style="font-size: 20px; margin-top: 10px;">Quant (QNT)</p>
                            <p class="feature-des" style="margin-top: 0px; text-align: center; color: #4FBF67;">6.28% ~ 41.76% APY</p>
                        </div>
                    </div>
                    
                </div>

                
            </div>

            

            
            
        </div>

        

        </div>
    </div>

    <div class="fugu-go-top">
        <img src="@/assets/baseAssets/images/arrow-black-right.svg" alt="">
    </div>

  <hr>

</template>