<template>
    
    <div class="fugu-blog-section fugu-section-padding">

        <div class="container">
            <div class="row">
        
        <div class="col-xl-8 col-lg-7" style="width: 100%; margin-bottom: 50px;">
          <div class="fugu-blog-single-wrap">
            
            <h3 class="text-white">OMCDEX User Agreement</h3><br><br>

            <p class="text-white">This agreement (the “Agreement”) is for customers who reside outside the United States of America, United Kingdom, European Economic Area, Japan and Singapore. In reviewing these terms you will see that some text is coloured in green. These clauses only apply to the regulated services provided to you by OMCDEX, Ltd (also trading as OMCDEX) is regulated by the UK Financial Conduct Authority. This is a contract between you and:</p><br>

            <p class="text-white">OMCDEX , Ltd ("OMCDEX") a private limited company incorporated in England and Wales and whose registered office address is 5 Fleet Place, London EC4M 7RD, United Kingdom.</p><br>

            <p class="text-white">References in this Agreement to "OMCDEX", "we", "our" or "us", are to OMCDEX Payments and/or OMCDEX UK depending on the services being discussed, and references to "you" or "your" are to the person with whom OMCDEX enters into this Agreement. </p><br>

            <p class="text-white">By signing up to use an account through OMCDEX.IO, or any of our associated websites, application programming interfaces (“APIs”), or mobile applications (collectively the "Site"), you agree that you have read, understood, and accept all of the terms and conditions contained in this Agreement, as well as our Privacy Policy and Cookie Policy.</p><br>

            <p class="text-white">We refer to the E-Money Services, Digital Currency Services and Additional Services (all defined below) collectively as the "OMCDEX Services", which can be accessed via the platform operated by OMCDEX (the “OMCDEX Platform”) (including the online platform which is accessible via the Site or at such location as may be prescribed by OMCDEX from time to time).</p><br>

            <p class="text-white">Access to E-Money Services is not automatic is only provided where OMCDEX Payments decides in its discretion to provide them. Each of the OMCDEX Services is provided by either OMCDEX Payments or OMCDEX UK, as set out in clause 2 below. </p><br>

            <p class="text-white">*You should be aware that the risk of loss in trading or holding Digital Currencies can be substantial. As with any asset, the value of Digital Currencies can go up or down and there can be a substantial risk that you lose money buying, selling, holding, or investing in digital currencies.</p><br>

            <p class="text-white">Digital Currency Services and Additional Services are not currently regulated by the Financial Conduct Authority. The Digital Currency Services and Additional Services are not within scope of the jurisdiction of the UK Financial Ombudsman Service, and your Digital Currencies and E-Money are not subject to protection under the UK Financial Services Compensation Scheme.</p><br>

            <p class="text-white">You should carefully consider whether trading or holding Digital Currencies is suitable for you in light of your financial condition.*</p><br>

            <p class="text-white"></p>

            <p class="text-white"></p>

            <p class="text-white"></p>

            <p class="text-white"></p>
            
          </div>
        
      </div>
            </div>
        </div>

    </div>

    <hr>

</template>