<template>
    
    <div class="fugu-blog-section fugu-section-padding">

        <div class="container">
      <div class="row">
        
        <div class="col-xl-8 col-lg-7" style="width: 100%; margin-bottom: 50px;">
          <div class="fugu-blog-single-wrap">
            <img class="wow fadeInUpX" src="@/assets/baseAssets/images/blog7Img.png" alt="">
            <div class="fugu-blog-meta">
              <ul>
                <li class="text-white">by Adam Stonewall</li>
                <li class="text-white">Dec 25,2023</li>
              </ul>
            </div>
            <h3 style="color: #da4bfb;">OMCDEX EXCLUSIVE CHRISTMAS PROMO</h3>

            <br>
            <p class="text-white">Tis the season to be jolly, and at OMCDEX, we're thrilled to bring a dash of crypto magic to your Christmas festivities! As a token of our appreciation for your continued support, we're offering you a chance to win 3 mystery boxes and a share of ISO 20022 tokens worth $80,000. It's our way of saying thank you for being a valued part of the OMCDEX family.</p>

            <br><br>

            <h5  style="color: #da4bfb;">Requirements</h5>

            <br>
            <p class="text-white">Each participant must hold at least $15,000 worth of Solana or stake $15,000 worth of native ISO 20022 tokens.</p>

            <br><br>
            <h5 style="color: #da4bfb;">Terms and Conditions</h5>

            <br>

            <p class="text-white"> -- An eligible participant is someone that has completed all of the above requirements, holding a minimum of $15,000 worth of Solana or staking ISO 20022 tokens worth $15,000 .</p>

            

            <br>
            <p class="text-white"> -- Participant stands a chance of winning 3 mystery boxes and share ISO 20022 tokens worth $80,000. </p>

            <br>
            <p class="text-white"><span style="font-weight: 800; color: #da4bfb;">NB: </span> This promo starts from <span style="color: #da4bfb;">26th December and ends on 31st December, 2023.</span> OMCDEX reserves the right to disqualify users if required.</p>

            <br>

            <p class="text-white">This promo will be conducted on a first-come, first-served basis, and participant must have completed the requirements before the deadline to be eligible for the reward.</p>

            <br>
            <p class="text-white">Want to level up your crypto? Join the early participants and stand a chance to be on win big.</p>

            <br>
            <p class="text-white">Contact <span style="color: #da4bfb;">support@omcdex.io</span> for further enquires</p>
            
        </div>
        
      </div>
    </div>
    </div>

    </div>

    <hr>

</template>