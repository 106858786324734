<template>

    <div class="page">
        <SidebarBody/>
        <UsersBody/>
    </div>
    
  </template>
  
  <script>
  import { defineComponent } from "vue";
  import SidebarBody from "@/components/master/SidebarBody.vue";
  import UsersBody from "@/components/master/UsersBody.vue";
  
  export default defineComponent({
    name: "MasterUsersView",
    components: {
      SidebarBody,
      UsersBody
    },
  });
  </script>
  