<template>
    <Navbar/>
    <Blog5Body/>
    <Footer/>
  </template>
  
  <script>
  import { defineComponent } from "vue";
  import Navbar from "@/components/NavbarBody.vue";
  import Blog5Body from "@/components/blog/Blog5Body.vue";
  import Footer from "@/components/FooterBody.vue"; 
  
  export default defineComponent({
    name: "Blog5View",
    components: {
      Navbar,
      Blog5Body,
      Footer
    },
  });
  </script>
  