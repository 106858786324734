<template>
    
    <div class="section fugu-section-padding"  style="background-acolor: #120130;">
    <div class="container">
      <div class="row">
        <div class="col-xl-5 col-lg-6 col-md-10">
          <div class="fugu-default-content contact-page">
            <h2 class="text-white">Reach out to us</h2>
            <p class="text-white">If you have any questions, please fill out the form and our team will get back to you within 24 hours.</p>
            <div class="fugu-contact-info">
              <ul>
                <li>
                  <a href="tel:+44 741 836 4171">
                    <div class="fugu-contact-info-icon">
                      <img src="@/assets/baseAssets/images/call.svg" alt="">
                    </div>
                    <h5 class="text-white">Call us</h5>
                    <p class="text-white">+44 741 8364 809</p>
                  </a>
                </li>
                <li>
                  <a href="mailto:support@omcdex.io">
                    <div class="fugu-contact-info-icon">
                      <img src="@/assets/baseAssets/images/email.svg" alt="">
                    </div>
                    <h5 class="text-white">Email us</h5>
                    <p class="text-white">support@omcdex.io</p>
                  </a>
                </li>
                <li>
                  <a href="">
                    <div class="fugu-contact-info-icon">
                      <img src="@/assets/baseAssets/images/map.svg" alt="">
                    </div>
                    <h5 class="text-white">Office address</h5>
                    <p class="text-white">Headley Ln, Dorking RH5 6DF, UK 7M7P+96 Leatherhead, United Kingdom</p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <form class="col-xl-6 col-lg-6 offset-xl-1 shd" @submit.prevent="sendEmail">
          <div class="fugu-contact-wrap  wow fadeInUpX" style="background-color: #120130;">
            <div class="fugu-input-field">
              <label class="text-white">Your name</label>
              <input class="text-white" type="text" required  v-model="name">
            </div>
            <div class="fugu-input-field">
              <label class="text-white">Email address</label>
              <input class="text-white" type="email" required v-model="email">
            </div>
            <div class="fugu-input-field">
              <label class="text-white">Write your message</label>
              <textarea class="text-white" name="textarea" required v-model="message"></textarea>
            </div>
            <button class="prime-button shd" id="fugu-input-submit" ref="btn" style="background-color:#6F2880; color: #ffffff; width: 100%;">{{btn}}</button>
          </div>
        </form>
        
      </div>
    </div>
    </div>

    <hr>

</template>

<script>
  import { secureToken } from '@/smtp';
  export default {
    
    data(){
      return{
          name: '',
          email: '',
          message: '',
          btn: 'Send Message'
      }
    },

    methods: {

      sendEmail(){

        this.btn = 'Sending message...';
        this.$refs.btn.disabled = true;

        let name = this.name;
        let email = this.email;
        let message = this.message;


        Email.send({
        SecureToken: secureToken,
        To : "support@omcdex.io",
        From : "support@omcdex.io",
        Subject : "Contact form",
        Body : `<!DOCTYPE html>
<html lang="en">
<head>

    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet">
    
</head>
<body style="margin: 0; padding: 0; font-family: sans-serif; background-color: #ffffff; overflow-x: hidden; font-family: 'Nunito', sans-serif;">
    <section style="width: 100vw; margin: 0; padding: 0; background-color: #14112e;">
        <div style="text-align:center; padding-bottom: 20px; padding-top: 20px; width: 100%; ">
            <img src="https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/images%2Flogo.png?alt=media&token=bf7f4424-2c79-4d80-b73f-f49a9fe78bc4" alt="" width="130px">
        </div>

        <hr>

        <div style="padding-left: 10px; padding-right: 10px; padding-top: 20px; padding-bottom: 20px; font-size: 14px; background-color: #14112e; color: #ffffff;">

            <div style=" padding-top: 10px; padding-bottom: 20px; font-size: 16px; color: #ffffff;">
                A user sent a message from the contact form  
            </div> 

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Name: ${name}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Email: ${email}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Message: ${message}
            </div> 

           
        </div>

        <hr>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Stay connected @ <span><a href="mailto:support@omcdex.io" style="color: #da4bfb;">support@omcdexvault.io</a></span>
        </div>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Risk warning: Cryptocurrency trading is subject to high market risk. OMCDEX will make the best efforts to choose high-quality coins, but will not be responsible for your trading losses. Please trade with caution.
        </div>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Kindly note: Please be aware of phishing sites and always make sure you are visiting the official omcdex.io website when entering sensitive data.
        </div>

        <div style="padding-left: 10px; padding-right: 10px; padding-top: 20px; padding-bottom: 70px; font-size: 12px; color: #ffffff;">
            ©2024 <span><a href="https://www.omcdex.io" target="_blank" rel="noopener noreferrer" style="color: #da4bfd;">omcdex.io</a></span> All Rights Reserved
        </div>
        
    </section>
</body>
</html>`
            }).
        then((message) => {
            //console.log(message);
            this.$refs.btn.disabled = true;
            this.btn = "Message sent successfully"
            
        })


      }


    }

  }
</script>