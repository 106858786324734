<template>
    
    <div class="section fugu-section-padding">
    <div class="container">
      <div class="coming-soon-column">
        
        <div class="coming-soon-content">
          <h2 class="text-white">OMCDEX token</h2>
          <p class="text-white">OMCDEX is a SPL deflationary token with smooth and automatic distribution of liquidity. The OMCDEX ecosystem is designed to solve the problems of most deflationary tokens.</p>
        </div>
        <div class="fugu-countdown-wrap">
          <div class="fugu-countdown-item wow fadeInUpX" data-wow-delay="0s" style="border-color: white;">
            <div class="countdown-days">
              <div class="number text-white"></div>
            </div>
            <p class="text-white">Days</p>
          </div>
          <div class="fugu-countdown-item wow fadeInUpX" data-wow-delay=".10s" style="border-color: white;">
            <div class="countdown-hours" >
              <div class="number text-white"></div>
            </div>
            <p class="text-white">Hours</p>
          </div>
          <div class="fugu-countdown-item wow fadeInUpX" data-wow-delay=".20s" style="border-color: white;">
            <div class="countdown-minutes">
              <div class="number text-white"></div>
            </div>
            <p class="text-white">Minutes</p>
          </div>
          <div class="fugu-countdown-item wow fadeInUpX" data-wow-delay=".30s" style="border-color: white;">
            <div class="countdown-seconds">
              <div class="number text-white"></div>
            </div>
            <p class="text-white">Seconds</p>
          </div>
        </div>
        
      </div>
    </div>
    </div>

    <hr>

    <div class="section bg-warning-300 fugu-section-padding3">
        <div class="container">
        <div class="fugu-section-title">
            <h2 class="text-white">Benefits</h2>
            <p class="text-white">The OMCDEX token smart contract automatically distributes finances among participants in the OMCDEX ecosystem. Over time, the coin will only grow by 1000x the initial price.</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
            <div class="fugu-iconbox-wrap4 wow fadeInUpX" data-wow-delay=".10s" style="border-color: #ffffff;">
                <div class="fugu-iconbox-icon4">
                <img src="@/assets/baseAssets/images/neb1.png" alt="">
                </div>
                <div class="fugu-iconbox-data4">
                <h4 class="text-white">50% burn</h4>
                <p class="text-white">Constant burning of coins after listing on exchanges will result in 50% of all coins burnt</p>
                </div>
            </div>
            </div>
            <div class="col-lg-4 col-md-6">
            <div class="fugu-iconbox-wrap4 wow fadeInUpX" data-wow-delay=".20s" style="border-color: #ffffff;">
                <div class="fugu-iconbox-icon4">
                <img src="@/assets/baseAssets/images/frozen.png" alt="">
                </div>
                <div class="fugu-iconbox-data4">
                <h4 class="text-white">Frozen LP</h4>
                <p class="text-white">The liquidity pool is frozen. This provides a guarantee against sharp jumps in volatility.</p>
                </div>
            </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
            <div class="fugu-iconbox-wrap4 wow fadeInUpX" data-wow-delay=".30s" style="border-color: #ffffff;">
                <div class="fugu-iconbox-icon4">
                <img src="@/assets/baseAssets/images/exchange.png" alt="">
                </div>
                <div class="fugu-iconbox-data4">
                <h4 class="text-white">OMCDEX DEX</h4>
                <p class="text-white">Development of a decentralized exchange</p>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>

    <hr>

    <div class="section bg-warning-300 fugu-section-padding3">
        <div class="container">
        <div class="fugu-section-title">
            <h2 class="text-white">Numbers</h2>
            <p class="text-white">All token holders will receive 5% all transaction automatically. Another 5% of all transactions will be added back to the liquidity pool</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
            
                <div class="feature-card-token2">

                    <div style="width: 100%; height: 100%; border-radius: 50%; background-color: #6F2880; display: flex; flex-direction: column; align-items: center; justify-content: center;">
                        <div class="feature-contain pt-25">
                            <h2 class="text-white">100%</h2>
                            <p class="text-white" style="margin-top: 10px;">18 000 000 000</p>
                            <p class="text-white">OMCDEX tokens</p>
                        </div>
                    </div>
                    
                    <p class="text-white" style="margin-top: 10px; ">18 billion listed on Uniswap & Pancakeswap</p>
                </div>


            </div>

            <div class="col-lg-4 col-md-6">
            
                <div class="feature-card-token2">

                    <div style="width: 100%; height: 100%; border-radius: 50%; background-image: url('https://s1.fileditch.ch/ivctwRoChnKoRbFnxyFh.png'); background-repeat: no-repeat; background-position: center; background-size: cover; display: flex; flex-direction: column; align-items: center; justify-content: center;">
                        <div class="feature-contain pt-25">
                            <h2 class="text-white">50%</h2>
                            <p class="text-white" style="margin-top: 10px;">9 000 000 000</p>
                            <p class="text-white">OMCDEX tokens</p>
                        </div>
                    </div>
                    
                    <p style="margin-top: 10px; color: #ffffff;">9 billion tokens burnt during swap</p>
                </div>

            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="feature-card-token2">

                    <div style="width: 100%; height: 100%; border-radius: 50%; background-color: #6FFC4C; display: flex; flex-direction: column; align-items: center; justify-content: center;">
                        <div class="feature-contain pt-25">
                            <h2 class="text-white">100%</h2>
                            <p class="text-white" style="margin-top: 10px;">9 000 000 000</p>
                            <p class="text-white">OMCDEX tokens</p>
                        </div>
                    </div>
                    
                    <p class="text-white" style="margin-top: 5px;">9 billion tokens left after swap</p>
                </div>
            </div>
        </div>
        </div>
    </div>

    <hr>

    <div class="fugu-go-top">
        <img src="@/assets/baseAssets/images/arrow-black-right.svg" alt="">
    </div>

</template>


<script>
  export default {

    mounted(){
      this.getCount();
    },

    methods:{

      getCount(){

        // Specify the deadline date
        const deadlineDate = new Date('December 31, 2025 23:59:59').getTime();

        console.log("DEADLINE: ");

        // Cache all countdown boxes into consts
        const countdownDays = document.querySelector('.countdown-days .number');
        const countdownHours= document.querySelector('.countdown-hours .number');
        const countdownMinutes= document.querySelector('.countdown-minutes .number');
        const countdownSeconds= document.querySelector('.countdown-seconds .number');

        // Update the count down every 1 second (1000 milliseconds)
        setInterval(() => {    
          // Get current date and time
          const currentDate = new Date().getTime();

          // Calculate the distance between current date and time and the deadline date and time
          const distance = deadlineDate - currentDate;

          // Calculations the data for remaining days, hours, minutes and seconds
          const days = Math.floor(distance / (1000 * 60 * 60 * 24));
          const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((distance % (1000 * 60)) / 1000);

          // Insert the result data into individual countdown boxes
          if(countdownDays) {
            countdownDays.innerHTML = days;
          }
          if(countdownHours) {
            countdownHours.innerHTML = hours;
          }
          if(countdownMinutes) {
            countdownMinutes.innerHTML = minutes;
          }
          if(countdownSeconds) {
            countdownSeconds.innerHTML = seconds;
          }
          
          
        }, 1000);

      }

    }
    
  }
</script>