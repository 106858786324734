<template>
  <Navbar/>
  <AboutBody/>
  <Footer/>
</template>

<script>
import { defineComponent } from "vue";
import Navbar from "@/components/NavbarBody.vue";
import AboutBody from "@/components/AboutBody.vue";
import Footer from "@/components/FooterBody.vue"; 

export default defineComponent({
  name: "AboutView",
  components: {
    Navbar,
    AboutBody,
    Footer
  },
});
</script>
