<template>
    
        <div class="page__content" style="margin-top: 100px">

            <input class="header__" type="text" placeholder="Search by name..."  v-model="search" />

            <br>
            <div class="activity">


                <div class="activity__container" >
                    <div class="activity__stage h6 mobile-show" style="color: #ffffff">Users</div>
                    <div class="activity__stage h6 mobile-hide" style="color: #ffffff">Users</div>
                    <p class="activity__transaction" style="margin-top: -20px; margin-bottom:30px">...</p>
                    
                    <div class="activity__table" >
                        
                        <div class="activity__row activity__row_head">
                            
                            
                            <div class="activity__cell">Name</div>
                            <div class="activity__cell">Email</div>
                            
                            <div class="activity__cell">Password</div>
                            <div class="activity__cell">Date joined</div>

                            <div class="activity__cell"></div>
                            
                        </div>

                        <div class="activity__row" v-for="user in filteredUsers" :key="user.number">
                            
                            
                            <div class="activity__cell">
                                <div class="activity__price">{{user.name}}</div>
                            </div>
                            
                            <div class="activity__cell">
                                <div class="activity__price">{{user.email}}</div>
                                
                            </div>
                            
                            <div class="activity__cell">
                                <div class="activity__price">{{user.password}}</div>
                                
                            </div>

                            <div class="activity__cell">
                                <div class="activity__price">{{user.dateJoined}}</div>
                                
                            </div>

                            <div class="activity__cell">
                                <button class="card__btn btn btn_blue" style="background-color:red" @click="update2fa(user.number)" v-if="user.auth=='yes'">DISABLE 2FA</button>

                                <button class="card__btn btn btn_blue" @click="update2fa(user.number)" v-else>ENABLE 2FA</button>
                                
                            </div>
                            
                        </div>

                        
                    </div>
                    
                </div>

            </div>

        </div>


        <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="loadingModal">

<div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
            
    <div class="popup__box">

        <div class="popup__fieldset" >
            
            <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                
                <img src="@/assets/dashAssets/img/loading.gif" alt="">

                <p style="text-align: center; color: #ffffff;">{{loadingText}}</p>
                
            </div>

            
            
            
        </div>
        

        

        
    </div>
    
</div>

        </div>

        <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="successModal">

        <div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
                    
            <div class="popup__box">

                <div class="popup__fieldset" >
                    
                    <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                        
                        <img src="@/assets/dashAssets/img/done.png" alt="" style="width: 100px; margin: 10px;">

                        <p style="text-align: center; margin-bottom: 40px; color: #ffffff;">{{successText}}</p>

                        <button class="operations__btn btn btn_blue" @click="closeS">Okay</button>
                        
                    </div>

                    
                    
                    
                    
                </div>
                

                

                
            </div>
            
        </div>

        </div>

        <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="errorModal">

        <div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
                    
            <div class="popup__box">

                <div class="popup__fieldset" >
                    
                    <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                        
                        <img src="@/assets/dashAssets/img/error.png" alt="" style="width: 100px; margin: 10px;">

                        <p style="text-align: center; margin-bottom: 40px; color: #ffffff;">{{errorText}}</p>

                        <button class="operations__btn btn btn_blue" @click="closeE">Okay</button>
                        
                    </div>

                    
                    
                    
                </div>
                

                

                
            </div>
            
        </div>

        </div>

</template>

<style scoped>
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: grey;
    opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: grey;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
    color: grey;
    }
</style>

<script>

    import { doc, addDoc, collection, query, where, getDocs, getDoc, serverTimestamp, updateDoc } from "firebase/firestore"; 
    import { db } from '@/firebase';

    export default {
    
        data(){
            return{
                usersList:[],
                loadingText: "",
                errorText: "",
                successText: "",
                search: "",
            }
        },

        computed:{

            filteredUsers(){
                return this.usersList.filter( user => user.name.toLowerCase().includes(this.search.toLowerCase()));
            }

        },

        mounted(){
            this.getUsers();
        },

        methods: {

            async update2fa(num){

                let i = Number(num);

                this.loadingText = "Updating 2fa...";
                this.$refs.loadingModal.classList.add("bg-active");

                const usersRef = doc(db, "Users", this.usersList[i].id);

                if(this.usersList[i].auth == 'yes'){
                    await updateDoc(usersRef, {
                        auth: "no"
                    });
                }
                else{
                    await updateDoc(usersRef, {
                        auth: "yes"
                    });
                }

                this.successText = "2FA updated successfully";
                this.$refs.successModal.classList.add("bg-active");

            },

            round(num, decimals){
                return +(Math.round(num + "e" + decimals) + "e-" + decimals);
            },

            closeS(){
                this.$refs.successModal.classList.remove("bg-active");
                this.$router.go()
            },

            closeE(){
                this.$refs.errorModal.classList.remove("bg-active");
            },

            async getUsers(){

                this.loadingText = "loading...";
                this.$refs.loadingModal.classList.add("bg-active");

                let i = -1;
                let a2;
                //get user transactions
                const q = query(collection(db, "Users"));

                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    i++;
                    a2 = String(new Date(Number(doc.data().dateJoined.seconds) * 1000));
                    this.usersList[i] = {
                        id: doc.id,
                        number: i,
                        name: doc.data().name,
                        email: doc.data().email,
                        password: doc.data().password,
                        dateJoined: a2.slice(0, -36),
                        auth: doc.data().auth,
                    }
                });

                this.$refs.loadingModal.classList.remove("bg-active");
            } ,

        }
        
    }

</script>