<template>

    <div class="page">
        <SidebarBody/>
        <FlexibleStakingBody/>
    </div>
    
  </template>
  
  <script>
  import { defineComponent } from "vue";
  import SidebarBody from "@/components/dashboard/SidebarBody.vue";
  import FlexibleStakingBody from "@/components/dashboard/FlexibleStakingBody.vue";
  
  export default defineComponent({
    name: "FlexibleStakingView",
    components: {
      SidebarBody,
      FlexibleStakingBody
    },
  });
  </script>
  