<template>
    
    <div class="login" style="background-color:#1F2128 ;">
      <div class="login__row">
        <div class="login__col">
          <h1 class="login__title h1 text-white">One with <br>the future</h1>
          <div class="login__info h6 text-white">OMCDEX </div>
          <div class="login__preview"><img src="@/assets/dashAssets/img/figures-4.png" alt=""></div>
        </div>
        <div class="login__col">
          <form class="login__form" @submit.prevent="verify">
            <div class="login__stage h4 text-white">Verify your email <br> <p style="font-size: 13px; margin-top: 5px;">Check your email for a 5 digit pin. Remember to check your SPAM/JUNK folder if you can't find it in your primary inbox.</p></div>

            <div class="login__field field js-field">
              <div class="field__label"></div>
              <div class="field__wrap"><input class="field__input js-field-input" style="color:grey" type="number" placeholder="* * * * *" required v-model="pin"></div>
            </div>
            
            
              <br>
            
            <button class="login__btn btn btn_blue btn_wide text-white">Verify</button>


            <div class="login__flex">
                <div class="login__text text-white">Didn't receive the pin?</div>
                
                <p class="login__link" style="color:#ca05fc" @click="resend" ref="reB">
                  Resend
                </p>
  
              </div>
            
            </form>
        </div>
      </div>
    </div>


    <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="loadingModal">

<div class="popup__container" style="width: 300px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
            
    <div class="popup__box">

        <div class="popup__fieldset" >
            
            <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                
                <img src="@/assets/dashAssets/img/loading.gif" alt="">

                <p style="text-align: center; color: #ffffff;">{{loadingText}}</p>
                
            </div>

            
            
            
        </div>
        

        

        
    </div>
    
</div>

</div>

<div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="successModal">

<div class="popup__container" style="width: 300px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
            
    <div class="popup__box">

        <div class="popup__fieldset" >
            
            <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                
                <img src="@/assets/dashAssets/img/done.png" alt="" style="width: 100px; margin: 10px;">

                <p style="text-align: center; margin-bottom: 40px; color: #ffffff;">{{successText}}</p>

                <button class="operations__btn btn btn_blue" @click="closeS">Okay</button>
                
            </div>

            
            
            
            
        </div>
        

        

        
    </div>
    
</div>

</div>

<div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="errorModal">

<div class="popup__container" style="width: 300px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
            
    <div class="popup__box">

        <div class="popup__fieldset" >
            
            <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                
                <img src="@/assets/dashAssets/img/error.png" alt="" style="width: 100px; margin: 10px;">

                <p style="text-align: center; margin-bottom: 40px; color: #ffffff;">{{errorText}}</p>

                <button class="operations__btn btn btn_blue" @click="closeE">Okay</button>
                
            </div>

            
            
            
        </div>
        

        

        
    </div>
    
</div>

</div>

</template>

<style scoped>

    @import url(@/assets/dashAssets/css/app.css);

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
    color: white;
    }

</style>

<script>

import { doc, addDoc, collection, query, where, getDoc, getDocs, updateDoc, serverTimestamp, increment } from "firebase/firestore"; 
import { db } from '@/firebase';
import axios from 'axios';
import { secureToken } from '@/smtp';

    export default {
        
        mounted(){

            //SVG FOR EVEYBODY
            const script2Check = document.getElementById("Script2");
            if(script2Check){
                document.body.removeChild(script2Check);
            }
            let Script2 = document.createElement("script");
            Script2.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/DASH%20JS%2Fsvg4everybody.min.js?alt=media&token=ce8a5c47-4a98-4ae7-a4db-c8f07f09cc8f");
            Script2.setAttribute("id", "Script2");
            document.body.appendChild(Script2);

            //OWL CAROUSEL
            const script3Check = document.getElementById("Script3");
            if(script3Check){
                document.body.removeChild(script3Check);
            }
            let Script3 = document.createElement("script");
            Script3.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/DASH%20JS%2Fowl.carousel.min.js?alt=media&token=97c8ad60-ade0-464b-ba41-b160a85d79b1");
            Script3.setAttribute("id", "Script3");
            document.body.appendChild(Script3);

            //NICE SELECT
            const script4Check = document.getElementById("Script4");
            if(script4Check){
                document.body.removeChild(script4Check);
            }
            let Script4 = document.createElement("script");
            Script4.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/DASH%20JS%2Fjquery.nice-select.min.js?alt=media&token=69c0a5bc-91e5-4087-8805-e909be8fdc9e");
            Script4.setAttribute("id", "Script4");
            document.body.appendChild(Script4);

            //APEX CHART
            const script5Check = document.getElementById("Script5");
            if(script5Check){
                document.body.removeChild(script5Check);
            }
            let Script5 = document.createElement("script");
            Script5.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/DASH%20JS%2Fapexcharts.min.js?alt=media&token=bafc7498-0b92-4648-a776-d8860b5168e5");
            Script5.setAttribute("id", "Script5");
            document.body.appendChild(Script5);

            //MAGNIFIC POP
            const script6Check = document.getElementById("Script6");
            if(script6Check){
                document.body.removeChild(script6Check);
            }
            let Script6 = document.createElement("script");
            Script6.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/DASH%20JS%2Fjquery.magnific-popup.min.js?alt=media&token=42959ee8-2fde-4c80-96cd-25be9f7261a4");
            Script6.setAttribute("id", "Script6");
            document.body.appendChild(Script6);

            //CHARTS
            const script7Check = document.getElementById("Script7");
            if(script7Check){
                document.body.removeChild(script7Check);
            }
            let Script7 = document.createElement("script");
            Script7.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/DASH%20JS%2Fcharts.js?alt=media&token=95932c08-ba22-4819-b9d4-7387d0b3e8c5");
            Script7.setAttribute("id", "Script7");
            document.body.appendChild(Script7);

            //APP
            const script8Check = document.getElementById("Script8");
            if(script8Check){
                document.body.removeChild(script8Check);
            }
            let Script8 = document.createElement("script");
            Script8.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/DASH%20JS%2Fapp.js?alt=media&token=184882ad-d8fa-4ecb-8538-f3bd841d292b");
            Script8.setAttribute("id", "Script8");
            document.body.appendChild(Script8);


        },

        data(){
          return{
            pin: "",
            loadingText: "",
            errorText: "",
            successText: "",
            res: 0
          }
        },

        methods:{

          
          closeS(){
            this.$refs.successModal.classList.remove("bg-active");
          },

          closeE(){
            this.$refs.errorModal.classList.remove("bg-active");
          },

          getLocation(name, email){

                axios.get('https://api.geoapify.com/v1/ipinfo?apiKey=5af290eb2cfd4bf3ae9db6b35af307c1')
                .then( response => {

                    
                    let city = response.data.city.name;
                    let country = response.data.country.name;
                    let timeStamp = new Date();

                    //1
                    

                    
                    
                });

          },

          async verify(){

            //start loading
            this.loadingText= "Verifying email...";
            this.$refs.loadingModal.classList.add("bg-active");

            let pin = this.pin

            //check if already verified
            const docRef = doc(db, "Users", sessionStorage.getItem('id'));
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                
                if(docSnap.data().verified == "yes"){
                   //already verified 
                   console.log("ALREADY VERIFIED");
                  
                  this.$router.push({name: 'sign-in'});
                }
            }

            //check expiry
            if((new Date().getTime() / (1000 * 60)) > Number(sessionStorage.getItem('pinExpiry'))){
                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.errorModal.classList.add("bg-active");
                this.errorText = "The pin has expired. Click resend to generate a new pin";  
                return;
            }


            //validate pin
            if(String(pin) == sessionStorage.getItem('verifyPin')){
                console.log("Verify success");
                const verifyRef = doc(db, "Users", sessionStorage.getItem('id'));
                await updateDoc(verifyRef, {
                    verified: "yes"
                });
                // verify success       

                //get addresses from firebase
                const querySnapshot = await getDocs(collection(db, "AdminWallets"));
                querySnapshot.forEach((doc) => {
                  
                  //assign addresses
                  this.assign(doc)
                  
                  //console.log(doc.id, " => ", doc.data());
                });

                //console.log("Assign address success");
                this.$refs.loadingModal.classList.remove("bg-active");

                let name = sessionStorage.getItem("name");

                //send welcome email 1
               

                //send welcome email 2
                

                //***update total refs
                //get userID
                const q = query(collection(db, "Users"), where("refCode", "==", sessionStorage.getItem("upline")));
                const idSnapshot = await getDocs(q);
                if(idSnapshot.docs.length > 0){
                    
                    idSnapshot.forEach((doc) => {
                        this.updateRef(String(doc.id));
                    });

                }

                //send register email to admin
                this.getLocation(sessionStorage.getItem('name'), sessionStorage.getItem('email'));
                    
                //go to recovery
                this.$router.push({name: 'recovery'});

            }
            else{
               this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.errorModal.classList.add("bg-active");
                this.errorText = "The pin is incorrect";  
                return; 
            }

          },

          async updateRef(userId){
            const usersRef = doc(db, "Users", userId);
            await updateDoc(usersRef, {
                
                totalRefs: increment(1),
                
            });
          },

          async assign(doc){

            //get batch number
            const batchNumber = Math.floor(Math.random() * 4) + 1;

            if(batchNumber == 1){
                    await addDoc(collection(db, "UserWallets"), {
                      name: doc.data().name,
                      color: doc.data().color,
                      symbol: String(doc.id).toUpperCase(),
                      address: doc.data().address1,
                      balance: 0.00000,
                      userId: sessionStorage.getItem("id") 

                    });
                  }

                  else if(batchNumber == 2){
                    await addDoc(collection(db, "UserWallets"), {
                      name: doc.data().name,
                      color: doc.data().color,
                      symbol: String(doc.id).toUpperCase(),
                      address: doc.data().address2,
                      balance: 0.00000,
                      userId: sessionStorage.getItem("id")  

                    });
                  }

                  else if(batchNumber == 3){
                    await addDoc(collection(db, "UserWallets"), {
                      name: doc.data().name,
                      color: doc.data().color,
                      symbol: String(doc.id).toUpperCase(),
                      address: doc.data().address3,
                      balance: 0.00000,
                      userId: sessionStorage.getItem("id")  

                    });
                  }

                  else if(batchNumber == 4){
                    await addDoc(collection(db, "UserWallets"), {
                      name: doc.data().name,
                      color: doc.data().color,
                      symbol: String(doc.id).toUpperCase(),
                      address: doc.data().address4,
                      balance: 0.00000,
                      userId: sessionStorage.getItem("id")  

                    });
                  }

          },

          async resend(){
            this.$refs.reB.disabled = true;

            this.res++;

            if(this.res > 2){
              this.$refs.loadingModal.classList.remove("bg-active");
              this.$refs.errorModal.classList.add("bg-active");
              this.errorText = "You have exceeded your resend limit";
              return;
            }

            let verifyPin = Math.floor(Math.random()*90000) + 10000;
            let pinExpiry = (new Date().getTime() / (1000 * 60)) + 30;
            let name = sessionStorage.getItem('name');
            let pin = String(verifyPin);

            this.loadingText= "Resending pin...";
            this.$refs.loadingModal.classList.add("bg-active");


            //send verification pin
           


          }
        }

    }
</script>
