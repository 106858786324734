<template>
    
    <div class="header" >
                    <div class="header__search">
                        <input class="header__" style="padding: 5px 10px 5px 10px;" type="text" placeholder="Search asset name..." id="search2"/>
                        
                    </div>

                    <button class="header__logo js-popup-open" href="#popup-settings" data-effect="mfp-zoom-in">
                        <img src="@/assets/dashAssets/img/ava-header.png" alt=""/>
                    </button>

                    <div class="header__group" style="width: 100%;">

                        
                        <div class="header__item header__item_notifications" style="margin-left: auto;">
                            <router-link class="header__head" :to="{name: 'transactions'}">
                                <svg class="icon icon-notification">
                                    <use xlink:href="@/assets/dashAssets/img/sprite.svg#icon-notification"></use>
                                </svg>
                            </router-link> 
                        </div>
                        
                        
                    </div>
                    
                    <button class="header__user js-popup-open"  href="#popup-settings" data-effect="mfp-zoom-in" style=" margin-left: auto;">
                        <img src="@/assets/dashAssets/img/ava-header.png" alt=""/>
                    </button>
                    <button class="header__toggle">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
                            <path d="M22 12H3" stroke="#11142d"></path>
                            <g stroke="#808191">
                                <path d="M22 4H13"></path>
                                <path opacity=".301" d="M22 20H13"></path>
                            </g>
                            <path d="M7 7l-5 5 5 5" stroke="#11142d"></path>
                        </svg>
                    </button>
    </div>

    <div class="popup popup_settings mfp-hide" id="popup-settings" style="z-index: 99">
            <div class="popup__title h6" style="color:#ffffff">Account Settings</div>
            <div class="popup__tabs js-tabs">
                
                <div class="popup__nav mobile-show">
                    <!-- <a class="popup__link js-tabs-link active" href="#">Profile</a> -->
                    <a class="popup__link js-tabs-link active" href="#" style="padding:15px">Referral</a>
                    <a class="popup__link js-tabs-link" href="#" style="padding:15px">Password</a>
                    <a class="popup__link js-tabs-link" href="#" style="padding:15px">2FA</a>
                    <a class="popup__link js-tabs-link" href="#" style="padding:15px">Recovery</a>
                </div>

                <div class="popup__nav">
                    <!-- <a class="popup__link js-tabs-link active" href="#">Profile</a> -->
                    <a class="popup__link js-tabs-link active" href="#">Referrals</a>
                    <a class="popup__link js-tabs-link " href="#">Password</a>
                    <a class="popup__link js-tabs-link" href="#">2FA</a>
                    <a class="popup__link js-tabs-link" href="#">Recovery phrase</a>
                </div>

                <div class="popup__container">
                    
                    <!-- <div class="popup__item js-tabs-item" style="display: block;">
                        <div class="popup__user">
                            <div class="popup__label popup__label_mb32">Your Avatar</div>
                            <div class="popup__line">
                                <div class="popup__ava">
                                    <img class="popup__pic" src="@/assets/dashAssets/img/ava-header.png" alt="">
                                </div>
                                <div class="popup__details">
                                    <div class="popup__btns">
                                        <div class="popup__loading">
                                            <input class="popup__file" type="file">
                                            <button class="popup__upload" style="padding-left: 10px; padding-right:10px">.//ff/ffss/fffff.png</button>
                                            

                                            <button class="popup__btn btn btn_blue" type="submit" style="margin-top: 15px;">Update Avatar</button>
                                            
                                        </div>

                                        
                                        
                                    </div>

                                    
                                    
                                </div>
                            </div>
                            
                        </div>
                        <div class="popup__fieldset">
                            <div class="popup__row">
                                <div class="popup__field">
                                    <div class="popup__label">Full Name</div>
                                    <div class="popup__wrap">
                                        <input class="popup__input" type="text">
                                    </div>
                                </div>
                                <div class="popup__field">
                                    <div class="popup__label">OMCDEX User ID</div>
                                    <div class="popup__wrap">
                                        <input class="popup__input" type="text">
                                    </div>
                                </div>
                            </div>
                            <div class="popup__row">
                                <div class="popup__field">
                                    <div class="popup__label">Email</div>
                                    <div class="popup__wrap">
                                        <input class="popup__input" type="email">
                                    </div>
                                </div>
                                
                            </div>
                            
                            
                        </div>
                        
                        
                    </div> -->
                    
                    <div class="popup__item js-tabs-item" style="display:block">
                        <div class="popup__invite">
                            <div class="popup__info h6" style="color: #ffffff; ">Invite your friends to join OMCDEX and Earn 3% on every of their deposits</div>
                            <div class="popup__label">Your Referral Code (Click to copy!)</div>
                            <div class="popup__wrap" @click="copyRef">
                                <input class="popup__input" type="text" disabled v-model="refLink" style="font-size: 10px;">
                                <!-- <button class="popup__copy">
                                    <svg class="icon icon-copy">
                                        <use xlink:href="@/assets/dashAssets/img/sprite.svg#icon-copy"></use>
                                    </svg>
                                </button> -->
                            </div>
                        </div>
                        <div class="popup__category">Total Rewards</div>
                        <div class="popup__flex">
                            <div class="popup__money h3" style="color: #4EBA62;">${{ refBalance }}</div>
                            <!-- <div class="popup__currency">USD</div> -->
                        </div>
                        <div class="popup__parameters">
                            <!-- <div class="popup__parameter">
                                <div class="popup__head">
                                    <div class="popup__text">Inviter Rewards</div>
                                    <div class="popup__text">0.00 USDT</div>
                                </div>
                                <div class="popup__body">You’re earning 20% of the trading fees your referrals pay.</div>
                            </div> -->
                            <div class="popup__parameter">
                                <div class="popup__head">
                                    <div class="popup__text">Total Invited</div>
                                    <div class="popup__text">{{ totalRefs }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="popup__item js-tabs-item" >
                        <form class="popup__form" @submit.prevent="changePassword">
                            <div class="popup__title h6" style="color:#ffffff">Change Password</div>
                            <div class="popup__field field js-field">
                                <div class="field__label"></div>
                                <div class="field__wrap">
                                    <input class="field__input js-field-input" type="password" placeholder="Old password" v-model="oldPassword" required>
                                </div>
                            </div>
                            <div class="popup__field field js-field">
                                <div class="field__label"></div>
                                <div class="field__wrap">
                                    <input class="field__input js-field-input" type="password" placeholder="New password" v-model="newPassword" required>
                                </div>
                            </div>
                            <div class="popup__field field js-field">
                                <div class="field__label"></div>
                                <div class="field__wrap">
                                    <input class="field__input js-field-input" type="password" v-model="cNewPassword" placeholder="Re-type new password" required>
                                </div>
                            </div>
                            <br>
                            <button class="popup__btn btn btn_blue btn_wide">Change Password</button>
                        </form>
                    </div>

                    <div class="popup__item js-tabs-item">
                        
                        <div class="popup__box">
                            <form class="popup__form" @submit.prevent="update2fa">
                                <div class="popup__title h6" style="color:#ffffff">Enable / Disable 2FA</div>
                                <p style="color: #7e7f8f;">Enter your password to enable or disable two factor authentication for your account</p>
                                <br>
                                <div class="popup__field field js-field">
                                    <div class="field__label"></div>
                                    <div class="field__wrap">
                                        <input class="field__input js-field-input" type="password" placeholder="Password" v-model="fPassword" required>
                                    </div>
                                </div>
                                <br>
                                <button class="popup__btn btn btn_blue btn_wide">{{faStatus}} 2FA</button>
                            </form>
                        </div>
                    </div>

                    <div class="popup__item js-tabs-item">
                        
                        <div class="popup__box">
                            <form class="popup__form" @submit.prevent="checkRecovery">
                                <div class="popup__title h6" style="color:#ffffff">Recovery phrase</div>
                                <p style="color: #7e7f8f;">Enter your password to view your 12 word recovery phrase. Your recovery phrase allows you to recover your OMCDEX wallet. Please do not expose or share your recovery phrase with anyone as doing so could result in your funds being stolen.</p>
                                <br>
                                <div class="popup__field field js-field">
                                    <div class="field__label"></div>
                                    <div class="field__wrap">
                                        <input class="field__input js-field-input" placeholder="Password" type="password" v-model="rPassword" required>
                                    </div>
                                </div>
                                <br>
                                <button class="popup__btn btn btn_blue btn_wide">Show recovery phrase</button>
                            </form>
                        </div>

                        

                    </div>
                </div>
            </div>
        </div>

        

        <div style=" display: flex; justify-content: center; align-items: center; z-index: 9999; " class="loadingModalBg" ref="recoveryModal" >
            <div class="popup__container" style="width: 500px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
                    
                    <div class="popup__box" >

                        <div class="popup__title h6" style="color:#ffffff">Recovery phrase</div>
                        
                        <br>
                        
                        
                        <p style="text-align: center; font-size: 18px; word-spacing: 20px; width:100%; border-radius: 10px; background-color: #6F2880; padding: 10px;"> {{recovery}}</p>

                        
                        <br><br>

                        <div style="width:100%; display:flex; justify-content:center; align-items:center">
                            <button class="popup__btn btn btn_blue" style="background-color:#FF2525" @click="closeR">Close</button>
                        </div>

                        
                    </div>
                    
                </div>
        </div>

        <div style=" display: flex; justify-content: center; align-items: center; z-index: 9999; " class="loadingModalBg" ref="loadingModal">

<div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
            
    <div class="popup__box">

        <div class="popup__fieldset" >
            
            <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                
                <img src="@/assets/dashAssets/img/loading.gif" alt="">

                <p style="text-align: center; color: #ffffff;">{{loadingText}}</p>
                
            </div>

            
            
            
        </div>
        

        

        
    </div>
    
</div>

        </div>

        <div style=" display: flex; justify-content: center; align-items: center; z-index: 9999; " class="loadingModalBg" ref="successModal">

        <div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
                    
            <div class="popup__box">

                <div class="popup__fieldset" >
                    
                    <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                        
                        <img src="@/assets/dashAssets/img/done.png" alt="" style="width: 100px; margin: 10px;">

                        <p style="text-align: center; margin-bottom: 40px; color: #ffffff;">{{successText}}</p>

                        <button class="operations__btn btn btn_blue" @click="closeS">Okay</button>
                        
                    </div>

                    
                    
                    
                    
                </div>
                

                

                
            </div>
            
        </div>

        </div>

        <div style=" display: flex; justify-content: center; align-items: center; z-index: 9999; " class="loadingModalBg" ref="successModal2">

        <div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
                    
            <div class="popup__box">

                <div class="popup__fieldset" >
                    
                    <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                        
                        <img src="@/assets/dashAssets/img/done.png" alt="" style="width: 100px; margin: 10px;">

                        <p style="text-align: center; margin-bottom: 40px; color: #ffffff;">{{successText}}</p>

                        <button class="operations__btn btn btn_blue" @click="closeS2">Okay</button>
                        
                    </div>

                    
                    
                    
                    
                </div>
                

                

                
            </div>
            
        </div>

        </div>

        <div style=" display: flex; justify-content: center; align-items: center; z-index: 9999; " class="loadingModalBg" ref="errorModal" >

        <div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
                    
            <div class="popup__box">

                <div class="popup__fieldset" >
                    
                    <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                        
                        <img src="@/assets/dashAssets/img/error.png" alt="" style="width: 100px; margin: 10px;">

                        <p style="text-align: center; margin-bottom: 40px; color: #ffffff;">{{errorText}}</p>

                        <button class="operations__btn btn btn_blue" @click="closeE">Okay</button>
                        
                    </div>

                    
                    
                    
                </div>
                

                

                
            </div>
            
        </div>

        </div>

</template>

<style scoped>
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: grey;
    opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: grey;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
    color: grey;
    }

</style>

<script>

    import { doc, addDoc, collection, query, where, getDocs, getDoc, serverTimestamp, updateDoc } from "firebase/firestore"; 
    import { db } from '@/firebase';

    export default {
        data(){
            return{
                loadingText:"",
                successText: "",
                errorText: "",
                currentPassword: "",
                oldPassword: "",
                newPassword: "",
                cNewPassword: "",
                fPassword: "",
                faStatus: "",
                recovery: "",
                rPassword: "",
                refLink: "",
                totalRefs: 0,
                refBalance: 0,
            }
        },

        mounted(){
            this.options();
            this.getPassword();
        },
        
        methods:{
            closeS(){
                this.$refs.successModal.classList.remove("bg-active");
                this.$router.go();
            },

            closeS2(){
                this.$refs.successModal2.classList.remove("bg-active");
            },

            closeE(){
                this.$refs.errorModal.classList.remove("bg-active");
            },

            closeR(){
                this.$refs.recoveryModal.classList.remove("bg-active");
            },

            copyRef(){
                navigator.clipboard.writeText(this.refLink);
                this.$refs.successModal2.classList.add("bg-active");
                this.successText = "Your referral link has been copied to your clipboard";
            },

            async getPassword(){
                //start loading
                this.loadingText= "Loading...";
                this.$refs.loadingModal.classList.add("bg-active"); 

                const passwordRef = doc(db, "Users", sessionStorage.getItem('id'));
                const passwordSnap = await getDoc(passwordRef);

                if (passwordSnap.exists()) {
                    this.currentPassword = passwordSnap.data().password;
                    this.recovery = passwordSnap.data().phrase;
                    this.refLink = "https://www.omcdex.io/account/sign-up?ref="+passwordSnap.data().refCode;
                    this.refBalance = passwordSnap.data().refBalance;
                    this.totalRefs = passwordSnap.data().totalRefs;

                    if(passwordSnap.data().auth == "no"){
                        this.faStatus = "Enable";
                    }
                    else{
                        this.faStatus = "Disable";
                    }
                    
                }

                this.$refs.loadingModal.classList.remove("bg-active"); 

            },

            async changePassword(){
                //start loading
                this.loadingText= "Loading...";
                this.$refs.loadingModal.classList.add("bg-active"); 

                if(this.oldPassword != this.currentPassword ){
                    this.errorText = "Incorrect password"
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    return;
                }

                if(this.newPassword != this.cNewPassword ){
                    this.errorText = "Passwords do not match"
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    return;
                }

                if(this.newPassword.length < 8 ){
                    this.errorText = "Password can not be less than 8 characters"
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    return;
                }

                if(this.newPassword == this.currentPassword ){
                    this.errorText = "New password can not be the same as old password"
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    return;
                }

                //update password
                const userRef = doc(db, "Users", sessionStorage.getItem("id"));
                await updateDoc(userRef, {
                    password: String(this.newPassword)
                });

                this.successText = "Password changed successfully"
                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.successModal.classList.add("bg-active");

            },

            async update2fa(){
                //start loading
                this.loadingText= "Updating 2FA status...";
                this.$refs.loadingModal.classList.add("bg-active"); 

                if(this.fPassword != this.currentPassword ){
                    this.errorText = "Incorrect password"
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    return;
                }

                //update status
                const userRef = doc(db, "Users", sessionStorage.getItem("id"));

                if(this.faStatus == "Enable"){
                    await updateDoc(userRef, {
                        auth: "yes"
                    });
                }
                else{
                    await updateDoc(userRef, {
                        auth: "no"
                    });
                }

                this.successText = "2FA status updated successfully"
                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.successModal.classList.add("bg-active");
                

            },

            checkRecovery(){
                //start loading
                this.loadingText= "Checking password...";
                this.$refs.loadingModal.classList.add("bg-active"); 

                if(this.rPassword != this.currentPassword ){
                    this.errorText = "Incorrect password"
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    return;
                }

                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.recoveryModal.classList.add("bg-active");

                //show recovery
            },

            options(){
                var option = $('.js-tabs-select .option');

                option.on('click', function () {
                    var thisOption = $(this),
                        indexOption = thisOption.index();
                    console.log(indexOption);
                    $('.js-tabs-item').hide();
                    $('.js-tabs-item').eq(indexOption).fadeIn();
                });
            }


        }
    }
</script>

