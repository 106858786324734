<template>
  <router-view/>
</template>

<script>
  export default {

    mounted(){
      document.addEventListener('contextmenu', function(e) {
        e.preventDefault(); 
      });
    }
    
  }
</script>

