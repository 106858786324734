<template>
    
    <div class="page__content">


        <HeaderBody/>

        <div class="activity" >
                    <div class="activity__container">
                        
                        <div class="activity__sorting">

                            <div class="activity__tags tablet-hide">
                                <router-link class="activity__link active" :to="{name: 'locked-staking'}" id="rStaking11">Locked staking</router-link>
                                <router-link class="activity__link " :to="{name: 'flexible-staking'}" id="rStaking12">Flexible staking</router-link>
                                <router-link class="activity__link" :to="{name: 'staking-rewards'}" id="rStaking13">Rewards</router-link>
                                
                            </div>

                            <div class="activity__tags tablet-show">
                                <router-link class="activity__link active" :to="{name: 'locked-staking'}" id="rStaking21"  style="padding: 10px 15px 10px 15px;">Locked staking</router-link>
                                <router-link class="activity__link"  :to="{name: 'flexible-staking'}" id="rStaking22" style="padding: 10px 15px 10px 15px;">Flexible staking</router-link>
                                <router-link class="activity__link"  :to="{name: 'staking-rewards'}" id="rStaking23" style="padding: 10px 15px 10px 15px;">Rewards</router-link>
                                
                            </div>
                            
                        </div>
                        
                        
                    </div>
                    
        </div>

        <div class="promotions">
                    <div class="promotions__wrapper">

                        <div class="search" style="margin-top: -40px; margin-bottom: 0px;">
                            <div class="search__container" >
                                <div class="search__wrap">
                                    <div class="search__title h3" style="color:#ffffff">Locked Staking</div>
                                    <div class="search__info" style="margin-bottom: 5px;">OMCDEX locked staking is a process where you lock your assets for a certain amount of days, and as compensation, you will generate an interest yield.</div>
                                    <div class="search__info" style="margin-bottom: 5px;">Locked staking rewards can not be redemmed until the staking period is over.</div>
                                    <div class="search__info" style="margin-bottom: 5px; font-weight: 700;">The minimum amount required for locked staking is $1000</div>
                                </div>
                                
                                <div class="search__preview">
                                    <img src="@/assets/dashAssets/img/figures-22.png" alt="">
                                </div>
                            </div>
                        </div>
                        
                        <br>
                        <hr>
                        <br>
                        <br>
                        
                        <div class="promotions__list">

                            
                            <div class="promotions__item" v-for="sl in stakingList" :key="sl.image">
                                <a class="promotions__preview" style="width: 100%; display:flex; justify-content:space-around; align-items:center">
                                    <img :src="sl.image" alt="" style="width: 70px; margin-top: 10px; border-radius: 50%;">
                                </a>
                                

                                <div style="width: 100%; display:flex; justify-content:space-around; align-items:center; flex-direction: column; margin-top: 5px;">
                                    <p>{{sl.name}}</p>
                                    
                                </div>

                                <div class="promotions__body">

                                    <div class="popup__field">
                                        <div class="popup__label">Duration</div>
                                        <div class="popup__wrap">
                                            <select  :id="sl.symbol+'Select'" style="color:#ffffff; padding:20px 15px 20px 15px; border-radius: 10px; background-color: #242731; width: 100%;">
                                                <option value="30">Alpha package (30 days --- {{sl.days30}}% APY)</option>
                                                <option value="60" >Delta package (60 days --- {{sl.days60}}% APY)</option>
                                                <option value="90" >Kappa package (90 days --- {{sl.days90}}% APY)</option>
                                                <option value="180" >Sigma package (180 days --- {{sl.days180}}% APY)</option>
                                                <option value="360">Omega package (360 days --- {{sl.days360}}% APY)</option>
                                            </select>

                                            <!-- <select  :id="sl.symbol+'Select'" class="select select_arrows select_sm">
                                                <option value="30" >Alpha package ({{sl.days30}}% APY)</option>
                                                
                                                <option value="60" >Delta package ({{sl.days60}}% APY)</option>
                                                <option value="90" >Kappa package ({{sl.days90}}% APY)</option>
                                                <option value="180" >Sigma package ({{sl.days180}}% APY)</option>
                                                <option value="360">Omega package ({{sl.days360}}% APY)</option>
                                            </select> -->

                                        </div>
                                    </div>

                                    

                                    <br>

                                    <div class="operations__field field js-field">
                                        <div class="popup__label">Amount</div>
                                        <div class="field__wrap">
                                            <input class="field__input js-field-input" type="number" placeholder="$0.00" style="text-align: center; font-size: 30px;" :id="sl.name+'Input'">
                                        </div>
                                    </div>

                                    <br>
                                    
                                    <button class="details__btn btn btn_blue btn_wide" @click="stake(sl.name, sl.image, sl.symbol, sl.name+'Input', sl.symbol+'Select')">Stake {{sl.symbol}}</button>

                                </div>
                            </div>
                            
                        </div>
                        
                    </div>
        </div>


    </div>

        <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="loadingModal">

<div class="popup__container" style="width: 300px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
            
    <div class="popup__box">

        <div class="popup__fieldset" >
            
            <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                
                <img src="@/assets/dashAssets/img/loading.gif" alt="">

                <p style="text-align: center; color: #ffffff;">{{loadingText}}</p>
                
            </div>

            
            
            
        </div>
        

        

        
    </div>
    
</div>

        </div>

        <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="successModal">

        <div class="popup__container" style="width: 300px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
                    
            <div class="popup__box">

                <div class="popup__fieldset" >
                    
                    <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                        
                        <img src="@/assets/dashAssets/img/done.png" alt="" style="width: 100px; margin: 10px;">

                        <p style="text-align: center; margin-bottom: 40px; color: #ffffff;">{{successText}}</p>

                        <button class="operations__btn btn btn_blue" @click="closeS">Okay</button>
                        
                    </div>

                    
                    
                    
                    
                </div>
                

                

                
            </div>
            
        </div>

        </div>

        <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="errorModal">

        <div class="popup__container" style="width: 300px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
                    
            <div class="popup__box">

                <div class="popup__fieldset" >
                    
                    <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                        
                        <img src="@/assets/dashAssets/img/error.png" alt="" style="width: 100px; margin: 10px;">

                        <p style="text-align: center; margin-bottom: 40px; color: #ffffff;">{{errorText}}</p>

                        <button class="operations__btn btn btn_blue" @click="closeE">Okay</button>
                        
                    </div>

                    
                    
                    
                </div>
                

                

                
            </div>
            
        </div>

        </div>

</template>

<style scoped>
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: grey;
    opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: grey;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
    color: grey;
    }

    

</style>

<script>
    import { doc, addDoc, collection, query, where, getDocs, getDoc, serverTimestamp, updateDoc } from "firebase/firestore"; 
    import { db } from '@/firebase';
    import axios from 'axios';
    import HeaderBody from '@/components/dashboard/HeaderBody.vue';
export default {

    components:{
        HeaderBody,
    },

    mounted(){

        
        //this.magnific();
        this.niceSelect();
        //this.niceSelect2();

        //toggle sidebar active
        document.getElementById("rWallet").classList.remove("active");
        document.getElementById("rSwap").classList.remove("active");
        document.getElementById("rStaking").classList.add("active");
        document.getElementById("rTrans").classList.remove("active");
        document.getElementById("rCard").classList.remove("active");

        // document.getElementById("rStaking11").classList.add("active");
        // document.getElementById("rStaking12").classList.remove("active");
        // document.getElementById("rStaking13").classList.remove("active");

        // document.getElementById("rStaking21").classList.add("active");
        // document.getElementById("rStaking22").classList.remove("active");
        // document.getElementById("rStaking23").classList.remove("active");
        
        this.reward();
        this.getStakingInfo();

    },

    data(){
        return{
            loadingText:"",
            successText: "",
            errorText: "",
            stakingList: [],
        }
    },

    methods:{

        round(num, decimals){
            return +(Math.round(num + "e" + decimals) + "e-" + decimals);
        },

        niceSelect(){
            $('.select').niceSelect();
        },

        niceSelect2(){
                var option = $('.js-tabs-select .option');

                option.on('click', function () {
                    var thisOption = $(this),
                        indexOption = thisOption.index();
                    console.log(indexOption);
                    $('.js-tabs-item').hide();
                    $('.js-tabs-item').eq(indexOption).fadeIn();
                });
        },

        magnific(){
                var link = $('.js-popup-open');
                link.magnificPopup({
                    type: 'inline',
                    fixedContentPos: true,
                    removalDelay: 200,
                    callbacks: {
                        beforeOpen: function beforeOpen() {
                            this.st.mainClass = this.st.el.attr('data-effect');
                        }
                    }
                });
        },

        closeS(){
                this.$refs.successModal.classList.remove("bg-active");
                this.$router.go();
        },

        closeE(){
            this.$refs.errorModal.classList.remove("bg-active");
        },

        async reward(){
            //get staking trans
            let todaySecs = new Date().getTime()/1000;

            const q = query(collection(db, "UserStakings"), where("userId", "==", sessionStorage.getItem("id"))); 
            const querySnapshot = await getDocs(q);

            if(querySnapshot.docs.length > 0){
                querySnapshot.forEach((doc) => {

                    //check endDate;
                    if(doc.data().type == "Locked staking" && doc.data().status == "in progress"){
                        if( todaySecs > doc.data().endDate){

                            //staking complete
                            this.updateStaking(doc.data().walletId, doc.data().reward, doc.data().lockedAmount, doc.id);
                            
                            
                        }
                    }

                })
            }


        },

        async updateStaking(walletId, reward, lockedAmount, docId){

            let balance, newBalance;

            //get balance
            const walletRef = doc(db, "UserWallets", walletId);
            const walletSnap = await getDoc(walletRef);

            if (walletSnap.exists()) {
                balance = walletSnap.data().balance;
            }

            else{
                console.log("NO WALLET WITH THIS ID");
                return;
            }

            //update balance
            newBalance = balance + reward + lockedAmount;
            await updateDoc(walletRef, {
                balance: this.round(Number(newBalance), 7)
            });

            const stakeRef = doc(db, "UserStakings", docId);
            await updateDoc(stakeRef, {
                status: "completed"
            });
        },

        async getStakingInfo(){
            //start loading
            this.loadingText= "Loading...";
            this.$refs.loadingModal.classList.add("bg-active"); 

            let i = -1;
            const q = query(collection(db, "AdminStaking")); 
            const querySnapshot = await getDocs(q);
            
            querySnapshot.forEach((doc) => {
                i++;

                this.stakingList[i] = {
                    name: doc.data().name,
                    symbol: doc.data().symbol,
                    image: doc.data().image,
                    days30: doc.data().days30,
                    days60: doc.data().days60,
                    days90: doc.data().days90,
                    days180: doc.data().days180,
                    days360: doc.data().days360,
                }
                
            })

            this.$refs.loadingModal.classList.remove("bg-active"); 
        },

        async stake(name, image, symbol, amountId, selectId){

            //start loading
            this.loadingText= "Staking "+symbol+"...";
            this.$refs.loadingModal.classList.add("bg-active"); 
            
            let  balance, walletId, apy, reward, days, newBalance;
            let amount = 0;
            let price = 0;
            let lockedAmount = 0;

            amount = document.getElementById(""+amountId).value;
            //console.log("Amount "+amount);


            //get price

            if(symbol == "OMC"){
                await axios.get('https://min-api.cryptocompare.com/data/pricemultifull?fsyms=OSMO&tsyms=USD')
                .then( response => {

                    price = Number(Object.values(response.data.RAW)[0].USD.PRICE);
                    //console.log("PRICE: "+price)
                
                });
            }
            else{
                await axios.get('https://min-api.cryptocompare.com/data/pricemultifull?fsyms='+symbol+'&tsyms=USD')
                .then( response => {

                    price = Number(Object.values(response.data.RAW)[0].USD.PRICE);
                    //console.log("PRICE: "+price)
                
                });
            }    

            if(Number(amount) < 1000 || Number(amount) == ""){

                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.errorModal.classList.add("bg-active");
                this.errorText = "Minimum amount is $1000";
                return;

            }

            //console.log("PRICE: "+price)
            lockedAmount = amount / price;
            //console.log("lockedamount "+lockedAmount);
            

            //check balance
            const q = query(collection(db, "UserWallets"), where("userId", "==", sessionStorage.getItem("id")), where("symbol", "==", symbol));

            const querySnapshot = await getDocs(q);

            querySnapshot.forEach((doc) => {

                balance = doc.data().balance;
                walletId = doc.id;

            })

            if(Number(balance) < Number(lockedAmount) ){
                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.errorModal.classList.add("bg-active");
                this.errorText = "Insufficient "+symbol+" balance";
                return;
            }

            //update balance
            newBalance = balance - lockedAmount;

            const wRef = doc(db, "UserWallets", String(walletId));
            await updateDoc(wRef, {
                balance: this.round(Number(newBalance), 7)
            });

            //get days and apy
            if(document.getElementById(selectId).value == "30"){
                days = 30;
                for(let i=0; i<this.stakingList.length; i++){
                    if(String(symbol) == String(this.stakingList[i].symbol)){
                        apy = Number(this.stakingList[i].days30);
                    }
                }
            }

            else if(document.getElementById(selectId).value == "60"){
                days = 60;
                for(let i=0; i<this.stakingList.length; i++){
                    if(String(symbol) == String(this.stakingList[i].symbol)){
                        apy = Number(this.stakingList[i].days60);
                    }
                }
            }

            else if(document.getElementById(String(selectId)).value == "90"){
                days = 90;
                for(let i=0; i<this.stakingList.length; i++){
                    if(String(symbol) == String(this.stakingList[i].symbol)){
                        apy = Number(this.stakingList[i].days90);
                    }
                }
            }

            else if(document.getElementById(selectId).value == "180"){
                days = 180;
                for(let i=0; i<this.stakingList.length; i++){
                    if(String(symbol) == String(this.stakingList[i].symbol)){
                        apy = Number(this.stakingList[i].days180);
                    }
                }
            }

            else if(document.getElementById(selectId).value == "360"){
                days = 360;
                for(let i=0; i<this.stakingList.length; i++){
                    if(String(symbol) == String(this.stakingList[i].symbol)){
                        apy = Number(this.stakingList[i].days360);
                    }
                }
            }

            //get reward
            let rApy = apy/100;
            reward = (Number(lockedAmount) * (rApy/365)) * Number(days);

            //get end date
            let todaySecs = new Date().getTime()/1000;
            let durationSecs = days * 24 * 60 * 60;
            let endDateSecs = todaySecs + durationSecs;

            //upload
            //upload transaction
            await addDoc(collection(db, "UserStakings"), {
                type: "Locked staking",
                name: name,
                image: image,
                symbol: symbol,
                walletId: walletId,
                userId: sessionStorage.getItem("id"),
                apy: apy,
                lockedPeriod: days,
                lockedAmount: this.round(Number(lockedAmount), 7),
                reward: this.round(Number(reward), 7),
                startDate: serverTimestamp(),
                endDate: Number(endDateSecs),
                status: "in progress"
            });

            
            this.$refs.loadingModal.classList.remove("bg-active");
            this.$refs.successModal.classList.add("bg-active");
            this.successText = symbol+" has been staked successfully";

            //upload stake
        },



    }

}
</script>
