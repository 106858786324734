<template>
    
    <div class="fugu-blog-section fugu-section-padding">

        <div class="container">
      <div class="row">
        
        <div class="col-xl-8 col-lg-7" style="width: 100%; margin-bottom: 50px;">
          <div class="fugu-blog-single-wrap">
            <img class="wow fadeInUpX" src="@/assets/baseAssets/images/warren3.png" alt="">
            <div class="fugu-blog-meta">
              <ul>
                <li class="text-white">by Abigail Seaberg</li>
                <li class="text-white">Feb 13,2021</li>
              </ul>
            </div>
            <h3 class="text-white">SOLANA-BASED NFT/META-VERSE TOKEN ATTRACTING WHALES</h3>

            <p class="text-white">You have probably heard a lot about NFTs by now if not, how do you have Internet?</p>

            <p class="text-white">The NFT market is currently attracting a lot of BIG WHALES especially Solana-based tokens(due to the fact that it has little to no gas fees), and these unlisted tokens because of the ambiguous funding by investors is giving them room for growth and a lot of potential in the crypto-verse.</p>

            <p class="text-white">From current researches into the crypto/NFT space, the OMCDEX(OMCN) token amongst many has the highest potential for growth in the Solana blockchain. OMCDEX(OMCN) is an NFT/Meta-verse token (first on the Sol blockchain) aimed at creating awareness and aiding victims of the COVID virus. The developers of this token have created a decentralized wallet where users can hold their crypto coins, an NFT collection, a staking pool all narrowed into the same platform making it easy for users to navigate through and invest in their project without having to be disorderly.</p>

            <p class="text-white">This token, coupled with the fact that they have an aim to help with the on-going pandemic and are also a high potential unlaunched project(ICO if you will), has brought it into the field of vision of big investors. Warren Buffet has claimed to be investing into this project and a few others. Warren looks to fund this project as an Silent investor as he wants the developers to have full control of the project.</p>

            <p class="text-white">OMCDEX token from what I believe will surely be in the watchlist of not just whales but the rest of the crypto world as they are coming in with a never-done-before vision in the entire crypto-verse. One would say they stand out from the rest of the upcoming Solana-based token projects.            </p>

            <p class="text-white"></p>
            
        </div>
        
      </div>
    </div>
    </div>

    </div>

    <hr>

</template>