<template>
    <SignInBody/>
</template>
  
  <script>
  import { defineComponent } from "vue";
  import SignInBody from "@/components/account/SignInBody.vue";
  
  export default defineComponent({
    name: "SignInView",
    components: {
      SignInBody,
    },
  });
  </script>
  