<template>
    
    <div class="page__content">

        <HeaderBody/>

        <div class="activity" >
                    <div class="activity__container">
                        
                        <div class="activity__sorting">

                            <div class="activity__tags tablet-hide">
                                <router-link class="activity__link" :to="{name: 'locked-staking'}" id="rStaking11">Locked staking</router-link>
                                <router-link class="activity__link " :to="{name: 'flexible-staking'}" id="rStaking12">Flexible staking</router-link>
                                <router-link class="activity__link active" :to="{name: 'staking-rewards'}" id="rStaking13">Rewards</router-link>
                                
                            </div>

                            <div class="activity__tags tablet-show">
                                <router-link class="activity__link" :to="{name: 'locked-staking'}" id="rStaking21"  style="padding: 10px 15px 10px 15px;">Locked staking</router-link>
                                <router-link class="activity__link"  :to="{name: 'flexible-staking'}" id="rStaking22" style="padding: 10px 15px 10px 15px;">Flexible staking</router-link>
                                <router-link class="activity__link active"  :to="{name: 'staking-rewards'}" id="rStaking23" style="padding: 10px 15px 10px 15px;">Rewards</router-link>
                                
                            </div>
                            
                        </div>
                        
                        
                    </div>
                    
        </div>

        <div class="promotions">
                    <div class="promotions__wrapper" >

                        <div class="wallets__wrapper" style="margin-top: -45px; margin-bottom: 0px;">
                            <div class="wallets__row">
                                <div class="wallets__total">
                                <div class="wallets__title b6">Total pending rewards</div>
                                <div class="wallets__flex">
                                  <!-- <div class="wallets__number b3">{{totalSol}}</div>
                                  <div class="wallets__currency" style="background-color: #6F2880;">SOL</div> -->
                                  <div class="wallets__number b3" >${{totalUsd}}</div>
                                  
                                </div>
                                <!-- <div class="wallets__price b5" > ${{totalUsd}}</div> -->
                              </div>
                                
                            </div>
                            <div class="wallets__inner">
                                <div class="wallets__list">
                                    <div class="wallets__item">
                                        <div class="wallets__icon">
                                            <img src="@/assets/dashAssets/img/circle-purple.svg" alt="">
                                        </div>
                                        <div class="wallets__details">
                                            <div class="wallets__info ">Active</div>
                                            <div class="wallets__price ">{{active}}</div>
                                        </div>
                                    </div>
                                    <div class="wallets__item">
                                        <div class="wallets__icon">
                                            <img src="@/assets/dashAssets/img/circle-blue.svg" alt="">
                                        </div>
                                        <div class="wallets__details">
                                            <div class="wallets__info ">completed</div>
                                            
                                            <div class="wallets__price ">{{completed}}</div>
                                        </div>
                                    </div>

                                    <div class="search__preview mtn">
                                        <img src="@/assets/dashAssets/img/figures-24.png" alt="">
                                    </div>

                                </div>


                            </div>

                            
                            
                        </div>
                        
                        <br>
                        <hr>
                        <br>

                        
                        <div class="promotions__list">

                            <div class="promotions__item" v-for="rewards in rewardsList" :key="rewards.userId">
                                <a class="promotions__preview" style="width: 100%; display:flex; justify-content:space-around; align-items:center; margin-top: 5px;">
                                    <img :src="rewards.image" alt="" style="width: 70px; border-radius: 50%; ">
                                </a>
                                

                                <div style="width: 100%; display:flex; justify-content:space-around; align-items:center; flex-direction: column;">
                                    <h5 style="color:#ffffff; margin-top: 5px;">{{rewards.name}}</h5>
                                    
                                </div>     

                                <div class="promotions__body">

                                    <div class="hr-line"></div>
                                    

                                    <div>

                                        <div style="width: 100%; display:flex; justify-content:space-between; align-items:center; padding: 10px">
                                            <p style="font-size: 12px; color:#8f8e8e; ">Type</p>
                                            <p>{{rewards.type}}</p>
                                        </div>

                                        <div style="width: 100%; display:flex; justify-content:space-between; align-items:center; padding: 10px">
                                            <p style="font-size: 12px; color:#8f8e8e; ">Locked amount</p>
                                            <p>{{rewards.lockedAmount}} {{rewards.symbol}}</p>
                                        </div>

                                        
    
                                        <div style="width: 100%; display:flex; justify-content:space-between; align-items:center; padding: 10px;">
                                            <p style="font-size: 12px; color:#8f8e8e; ">Start date</p>
                                            <p>{{rewards.startDate}}</p>
                                        </div>


                                        <div style="width: 100%; display:flex; justify-content:space-between; align-items:center; padding: 10px;" v-if="rewards.type=='Locked staking'">
                                            <p style="font-size: 12px; color:#8f8e8e; ">End date</p>
                                            <p>{{rewards.endDate}}</p>
                                        </div>


                                        <div style="width: 100%; display:flex; justify-content:space-between; align-items:center; padding: 10px;" v-if="rewards.type=='Locked staking'">
                                            <p style="font-size: 12px; color:#8f8e8e; ">Locked period</p>
                                            <p>{{rewards.lockedPeriod}} days</p>
                                        </div>

                                        <div style="width: 100%; display:flex; justify-content:space-between; align-items:center; padding: 10px;">
                                            <p style="font-size: 12px; color:#8f8e8e; ">APY</p>
                                            <p>{{rewards.apy}}%</p>
                                        </div>

                                        <div style="width: 100%; display:flex; justify-content:space-between; align-items:center; padding: 10px;">
                                            <p style="font-size: 12px; color:#8f8e8e; ">Reward</p>
                                            <p>{{rewards.reward}} {{rewards.symbol}}</p>
                                        </div>

                                        <div style="width: 100%; display:flex; justify-content:space-between; align-items:center; padding: 10px;">
                                            <p style="font-size: 12px; color:#8f8e8e; ">Status</p>
                                            <div class="promotions__date bg-green-opacity color-orange" v-if="rewards.status=='in progress'">{{rewards.status}}</div>
                                            <div class="promotions__date bg-green-opacity color-green" v-if="rewards.status=='completed'">{{rewards.status}}</div>
                                        </div>
                                    </div>

                                    
                                    <button class="details__btn btn btn_blue btn_wide" style="margin-top: 10px; margin-bottom:10px" v-if="rewards.type=='Flexible staking' && rewards.status=='in progress'" @click="withdraw(rewards.number)">Withdraw {{rewards.symbol}}</button>

                                </div>
                            </div>
                            
                        </div>
                        
                    </div>
        </div>

    </div>

    <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="loadingModal">

<div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
            
    <div class="popup__box">

        <div class="popup__fieldset" >
            
            <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                
                <img src="@/assets/dashAssets/img/loading.gif" alt="">

                <p style="text-align: center; color: #ffffff;">{{loadingText}}</p>
                
            </div>

            
            
            
        </div>
        

        

        
    </div>
    
</div>

        </div>

        <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="successModal">

        <div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
                    
            <div class="popup__box">

                <div class="popup__fieldset" >
                    
                    <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                        
                        <img src="@/assets/dashAssets/img/done.png" alt="" style="width: 100px; margin: 10px;">

                        <p style="text-align: center; margin-bottom: 40px; color: #ffffff;">{{successText}}</p>

                        <button class="operations__btn btn btn_blue" @click="closeS">Okay</button>
                        
                    </div>

                    
                    
                    
                    
                </div>
                

                

                
            </div>
            
        </div>

        </div>

        <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="errorModal">

        <div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
                    
            <div class="popup__box">

                <div class="popup__fieldset" >
                    
                    <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                        
                        <img src="@/assets/dashAssets/img/error.png" alt="" style="width: 100px; margin: 10px;">

                        <p style="text-align: center; margin-bottom: 40px; color: #ffffff;">{{errorText}}</p>

                        <button class="operations__btn btn btn_blue" @click="closeE">Okay</button>
                        
                    </div>

                    
                    
                    
                </div>
                

                

                
            </div>
            
        </div>

        </div>

</template>

<style scoped>
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: grey;
    opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: grey;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
    color: grey;
    }   

</style>

<script>

    import { doc, addDoc, collection, query, where, getDocs, getDoc, serverTimestamp, updateDoc, orderBy } from "firebase/firestore"; 
    import { db } from '@/firebase';
    import axios from 'axios';
    import HeaderBody from '@/components/dashboard/HeaderBody.vue';

    export default {

        components:{
            HeaderBody,
        },

        data(){
            return{
                loadingText:"",
                successText: "",
                errorText: "",
                active: 0,
                totalUsd: 0,
                completed: 0,
                totalSol: 0,
                rewardsList: [],
            }
        },

        mounted(){
            //toggle sidebar active
            document.getElementById("rWallet").classList.remove("active");
            document.getElementById("rSwap").classList.remove("active");
            document.getElementById("rStaking").classList.add("active");
            document.getElementById("rTrans").classList.remove("active");
            document.getElementById("rCard").classList.remove("active");

            this.getStakeTrans();
        }, 

        methods:{

            closeS(){
                this.$refs.successModal.classList.remove("bg-active");
                this.$router.go();
            },

            closeE(){
                this.$refs.errorModal.classList.remove("bg-active");
            },

            round(num, decimals){
                return +(Math.round(num + "e" + decimals) + "e-" + decimals);
            },

            async getStakeTrans(){
                //start loading
                this.loadingText= "Loading...";
                this.$refs.loadingModal.classList.add("bg-active"); 

                const q = query(collection(db, "UserStakings"), where("userId", "==", sessionStorage.getItem("id")), orderBy("startDate", "desc")); 
                const querySnapshot = await getDocs(q);

                let i = -1;
                let a2, a3;
                let activeTemp = 0;
                let completedTemp = 0;
                

                let todaySecs = new Date().getTime()/1000;
                let mReward = 0;
                               
                querySnapshot.forEach((doc) => {
                    i++;

                    a2 = String(new Date(Number(doc.data().startDate.seconds) * 1000));
                    a3 = String(new Date(Number(doc.data().endDate) * 1000));
                    mReward = Number(doc.data().reward);
                    
                    //count active
                    if(doc.data().status == "in progress"){
                        activeTemp++;
                    }
                    else{
                        completedTemp++;
                    }

                    //FS rewards
                    if(doc.data().type == "Flexible staking"){
                        let checkSecs = todaySecs - doc.data().startDate.seconds;
                        let days = checkSecs/(60 * 60 * 24);

                        let rApy = doc.data().apy/100;
                        mReward = doc.data().lockedAmount * (rApy/365) * Number(days);
                    }

                    this.rewardsList[i] = {
                        number: i,
                        id: doc.id,
                        apy: Number(doc.data().apy),
                        endDateSecs: Number(doc.data().endDate),
                        endDate: a3.slice(0, -36),
                        symbol: doc.data().symbol,
                        image: doc.data().image,
                        lockedAmount: Number(doc.data().lockedAmount),
                        lockedPeriod: Number(doc.data().lockedPeriod),
                        name: doc.data().name,
                        reward: this.round(mReward, 7),
                        startDate: a2.slice(0, -36),
                        startDateSecs: Number(doc.data().startDate.seconds),
                        status: doc.data().status,
                        type: doc.data().type,
                        userId: doc.data().userId,
                        walletId: doc.data().walletId,
                    }
                    
                })

                this.active = activeTemp;
                this.completed = completedTemp;

                if(this.rewardsList.length > 0){
                    this.getTotal();
                }

                this.$refs.loadingModal.classList.remove("bg-active"); 
            },

            async getTotal(){

                let temp;
                let temp2 = 0;
                let totalUsd;

                for(let i=0; i< this.rewardsList.length; i++){

                    if(this.rewardsList[i].status == 'in progress'){

                        let tempSym;
                        tempSym = this.rewardsList[i].symbol;

                        if(this.rewardsList[i].symbol == "OMC"){
                            tempSym = "OSMO";
                        }

                        await axios.get('https://min-api.cryptocompare.com/data/pricemultifull?fsyms='+tempSym+'&tsyms=USD')
                        .then( response => {

                            let price = Number(Object.values(response.data.RAW)[0].USD.PRICE);
                            let lockedAmount = this.rewardsList[i].reward;
                            temp = price * lockedAmount;
                            temp2 += temp; 
                            
                            totalUsd = this.round(temp2, 2);
                            this.totalUsd = totalUsd;
                        }); 
                    }

                }

                await axios.get('https://min-api.cryptocompare.com/data/pricemultifull?fsyms=sol&tsyms=USD')
                .then( response => {

                    let price = 0;
                    let solBalance = 0;

                    price = Number(Object.values(response.data.RAW)[0].USD.PRICE);
                    solBalance = temp2/price;
                    
                    this.totalSol = this.round(solBalance, 7);
                    
                });

            },

            async withdraw(i){

                this.loadingText= "Withdrawing "+this.rewardsList[i].symbol+"...";
                this.$refs.loadingModal.classList.add("bg-active"); 

                let balance = 0;

                //check days
                let todaySecs = new Date().getTime()/1000;
                let checkSecs = todaySecs - this.rewardsList[i].startDateSecs;
                
                let days = checkSecs/(60 * 60 * 24);

                if(days < 1){
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Minimum durartion for withdrawal is 1 day";
                    return;
                }


                //get reward
                let rApy = this.rewardsList[i].apy/100;
                let reward = this.rewardsList[i].lockedAmount * (rApy/365) * Number(days);

                //get balance
                const walletRef = doc(db, "UserWallets", this.rewardsList[i].walletId);
                const walletSnap = await getDoc(walletRef);

                if (walletSnap.exists()) {
                    balance = walletSnap.data().balance;
                }

                //update balance
                let newBalance = balance + reward + this.rewardsList[i].lockedAmount;
                await updateDoc(walletRef, {
                    balance: this.round(Number(newBalance), 7)
                });

                //update status
                const stakeRef = doc(db, "UserStakings", this.rewardsList[i].id);
                await updateDoc(stakeRef, {
                    status: "completed"
                });

                this.$refs.loadingModal.classList.remove("bg-active");
                this.$refs.successModal.classList.add("bg-active");
                this.successText = "Withdrawal completed";
            }
        }
    }
</script>