<template>
    
    <div class="sidebar">
                <div class="sidebar__head">
                    <router-link class="sidebar__logo" :to="{name: 'wallet'}">
                        <img class="sidebar__pic sidebar__pic_light" src="@/assets/dashAssets/img/logo.png" alt="" style="width: 150px;"/>
                        <img class="sidebar__pic sidebar__pic_dark" src="@/assets/dashAssets/img/logo.png" alt="" style="width: 150px;"/>
                    </router-link>
                    <button class="sidebar__toggle">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
                            <path d="M22 12H3" stroke="#ffffff"></path>
                            <g stroke="#ffffff">
                                <path d="M22 4H13"></path>
                                <path opacity=".301" d="M22 20H13"></path>
                            </g>
                            <path d="M7 7l-5 5 5 5" stroke="#ffffff"></path>
                        </svg>
                    </button>
                    <button class="sidebar__close">
                        <img src="@/assets/dashAssets/img/close.png" alt="" style="width: 20px;">
                    </button>
                </div>

                <div style="width: 100%; background-color:#6c6d6f; height: 1px; ">
                </div>

                <div class="sidebar__body">
                    
                    <nav class="sidebar__nav">
                        
                        
                        <br>
                        <br>
                        
                    

                        

                        <router-link class="sidebar__item" :to="{name: 'admin-users'}" id="rStaking">
                            <div class="sidebar__icon">
                                <svg class="icon icon-discount">
                                    <use xlink:href="@/assets/dashAssets/img/sprite.svg#icon-discount"></use>
                                </svg>
                            </div>
                            <div class="sidebar__text">Users</div>
                        </router-link>

                        

                        <router-link class="sidebar__item" :to="{name: 'admin-trans'}" id="rTrans">
                            <div class="sidebar__icon">
                                <svg class="icon icon-notification">
                                    <use xlink:href="@/assets/dashAssets/img/sprite.svg#icon-notification"></use>
                                </svg>
                            </div>
                            <div class="sidebar__text">Transactions</div>
                        </router-link>

                        
                        
                    </nav>
                    
                    
                </div>
               
    </div>

</template>

<style scoped>
    @import url(@/assets/dashAssets/css/app.css);

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
    color: white;
    }
</style>

<script>

    export default {
        
        mounted(){

            if(sessionStorage.getItem("id") != "adminLog2255"){
                window.location.replace("/account/sign-in");
                return; 
            }

            //set body
            document.body.classList.add('dark');

            //SVG FOR EVEYBODY
            const script2Check = document.getElementById("Script2");
            if(script2Check){
                document.body.removeChild(script2Check);
            }
            let Script2 = document.createElement("script");
            Script2.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/DASH%20JS%2Fsvg4everybody.min.js?alt=media&token=ce8a5c47-4a98-4ae7-a4db-c8f07f09cc8f");
            Script2.setAttribute("id", "Script2");
            document.body.appendChild(Script2);

            //OWL CAROUSEL
            const script3Check = document.getElementById("Script3");
            if(script3Check){
                document.body.removeChild(script3Check);
            }
            let Script3 = document.createElement("script");
            Script3.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/DASH%20JS%2Fowl.carousel.min.js?alt=media&token=97c8ad60-ade0-464b-ba41-b160a85d79b1");
            Script3.setAttribute("id", "Script3");
            document.body.appendChild(Script3);

            //NICE SELECT
            const script4Check = document.getElementById("Script4");
            if(script4Check){
                document.body.removeChild(script4Check);
            }
            let Script4 = document.createElement("script");
            Script4.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/DASH%20JS%2Fjquery.nice-select.min.js?alt=media&token=69c0a5bc-91e5-4087-8805-e909be8fdc9e");
            Script4.setAttribute("id", "Script4");
            document.body.appendChild(Script4);

            //MAGNIFIC POP
            const script6Check = document.getElementById("Script6");
            if(script6Check){
                document.body.removeChild(script6Check);
            }
            let Script6 = document.createElement("script");
            Script6.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/DASH%20JS%2Fjquery.magnific-popup.min.js?alt=media&token=42959ee8-2fde-4c80-96cd-25be9f7261a4");
            Script6.setAttribute("id", "Script6");
            document.body.appendChild(Script6);

            //  //CHARTS
            //  const script7Check = document.getElementById("Script7");
            // if(script7Check){
            //     document.body.removeChild(script7Check);
            // }
            // let Script7 = document.createElement("script");
            // Script7.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/DASH%20JS%2Fcharts.js?alt=media&token=95932c08-ba22-4819-b9d4-7387d0b3e8c5");
            // Script7.setAttribute("id", "Script7");
            // document.body.appendChild(Script7);

            //APP
            const script8Check = document.getElementById("Script8");
            if(script8Check){
                document.body.removeChild(script8Check);
            }
            let Script8 = document.createElement("script");
            Script8.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/DASH%20JS%2Fapp.js?alt=media&token=184882ad-d8fa-4ecb-8538-f3bd841d292b");
            Script8.setAttribute("id", "Script8");
            document.body.appendChild(Script8);


        },


    }
</script>