import { createRouter, createWebHistory } from 'vue-router'
import HomeView from "../views/HomeView.vue";
import AboutView from '../views/AboutView.vue';
import StakingView from '../views/StakingView.vue';
import TokenView from '../views/TokenView.vue';
import ContactView from '../views/ContactView.vue';
import PrivacyView from '../views/PrivacyView.vue';
import UserAgreeView from '../views/UserAgreeView.vue';
import BlogView from '../views/BlogView.vue';
import Blog1View from '../views/blog/Blog1View.vue';
import Blog2View from '../views/blog/Blog2View.vue';
import Blog3View from '../views/blog/Blog3View.vue';
import Blog4View from '../views/blog/Blog4View.vue';
import Blog5View from '../views/blog/Blog5View.vue';
import Blog6View from '../views/blog/Blog6View.vue';
import Blog7View from "../views/blog/Blog7View.vue";
import SignInView from '../views/account/SignInView.vue';
import SignUpView from '../views/account/SignUpView.vue';
import VerifyEmailView from '../views/account/VerifyEmailView.vue';
import a2faView from '../views/account/2faView.vue';
import RecoveryView from '../views/account/RecoveryView.vue';
import ForgotPasswordView from '../views/account/ForgotPasswordView.vue';
import ForgotPassword2View from '../views/account/ForgotPassword2View.vue';
import ResetPasswordView from '../views/account/ResetPasswordView.vue';
import WalletView from '../views/dashboard/WalletView.vue';
import SwapView from '../views/dashboard/SwapView.vue';
import LockedStakingView from '../views/dashboard/LockedStakingView.vue';
import FlexibleStakingView from '../views/dashboard/FlexibleStakingView.vue';
import StakingRewardsView from '../views/dashboard/StakingRewardsView.vue';
import TransactionsView from '../views/dashboard/TransactionsView.vue';
import CardView from '@/views/dashboard/CardView.vue';

import AdminTransactionsView from '../views/master/TransactionsView.vue';
import AdminUsersView from '../views/master/UsersView.vue';

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: AboutView,
  },
  {
    path: "/staking",
    name: "staking",
    component: StakingView,
  },
  {
    path: "/token",
    name: "token",
    component: TokenView,
  },
  {
    path: "/blog",
    name: "blog",
    component: BlogView,
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactView,
  },
  {
    path: "/privacy-policy",
    name: "privacy",
    component: PrivacyView,
  },
  {
    path: "/user-agreement",
    name: "user-agree",
    component: UserAgreeView,
  },

  /**** BLOG ****/
  {
    path: "/blog/Warren-taking-a-bite-of-the-Metaverse-Buffet",
    name: "blog1",
    component: Blog1View,
  },
  {
    path: "/blog/The-Omicron-Metaverse-(Omniverse)",
    name: "blog2",
    component: Blog2View,
  },
  {
    path: "/blog/The-Decentralized-wallet",
    name: "blog3",
    component: Blog3View,
  },
  {
    path: "/blog/Crypto-coin-vs-Crypto-Token",
    name: "blog4",
    component: Blog4View,
  },
  {
    path: "/blog/Best-ICO-2022-and-Top-5-Cryptocurrencies-for-this-Year",
    name: "blog5",
    component: Blog5View,
  },
  {
    path: "/blog/Solana-based-NFT/metaverse-token-attracting-whales",
    name: "blog6",
    component: Blog6View,
  },
  {
    path: "/blog/omicron-exclusive-christmas-promo",
    name: "blog7",
    component: Blog7View,
  },

  /**** ACCOUNT ****/

  {
    path: "/account/sign-in",
    name: "sign-in",
    component: SignInView,
  },

  {
    path: "/account/sign-up",
    name: "sign-up",
    component: SignUpView,
  },

  {
    path: "/account/verify-email",
    name: "verify-email",
    component: VerifyEmailView,
  },

  {
    path: "/account/recovery",
    name: "recovery",
    component: RecoveryView,
  },

  {
    path: "/account/login-auth",
    name: "2fa",
    component: a2faView,
  },

  {
    path: "/account/forgot-password",
    name: "forgot-password",
    component: ForgotPasswordView,
  },

  {
    path: "/account/forgot-password2",
    name: "forgot-password2",
    component: ForgotPassword2View,
  },

  {
    path: "/account/reset-password",
    name: "reset-password",
    component: ResetPasswordView,
  },

  /********* DASHBOARD **********/
  {
    path: "/dashboard/wallet",
    name: "wallet",
    component: WalletView,
  },

  {
    path: "/dashboard/swap",
    name: "swap",
    component: SwapView,
  },

  {
    path: "/dashboard/locked-staking",
    name: "locked-staking",
    component: LockedStakingView,
  },

  {
    path: "/dashboard/flexible-staking",
    name: "flexible-staking",
    component: FlexibleStakingView,
  },

  {
    path: "/dashboard/staking-rewards",
    name: "staking-rewards",
    component: StakingRewardsView,
  },

  {
    path: "/dashboard/transactions",
    name: "transactions",
    component: TransactionsView,
  },

  {
    path: "/dashboard/card",
    name: "card",
    component: CardView,
  },

  // Admin
  {
    path: "/admin/master/users",
    name: "admin-users",
    component: AdminUsersView,
  },

  {
    path: "/admin/master/transactions",
    name: "admin-trans",
    component: AdminTransactionsView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      top: 0,
    };
  },
});

export default router
