<template>
    
    <div class="login" style="background-color:#1F2128 ;">
      <div class="login__row">
        <div class="login__col">
          <h1 class="login__title h1 text-white">One with <br>the future</h1>
          <div class="login__info h6 text-white">OMCDEX </div>
          <div class="login__preview"><img src="@/assets/dashAssets/img/figures-2.png" alt=""></div>
        </div>
        <div class="login__col">
          <form class="login__form" @submit.prevent="signIn">
            <div class="login__stage h4 text-white">Sign-in to <br>OMCDEX </div>
            
            <div class="login__field field js-field">
              
              <div class="field__wrap"><input class="field__input js-field-input " style="color:grey" type="email" required v-model="email" placeholder="Email"></div>
              
            </div>

            <div class="login__field field js-field">
              
              <div class="field__wrap"><input class="field__input js-field-input" style="color:grey" type="password" required v-model="password" placeholder="Password"></div>
            </div>
            <div class="login__links text-right">
                <router-link class="login__link" :to="{name: 'forgot-password'}" style="color:#ca05fc"><svg class="icon icon-link">
                 
                </svg>Forgot Password?</router-link>
            </div>
            
            <button class="login__btn btn btn_blue btn_wide text-white" >Sign in</button>


            <div class="login__flex">
              <div class="login__text text-white">Don't have an account?</div>
              
              <router-link class="login__link" :to="{name: 'sign-up'}" style="color:#ca05fc">
                Sign up now
               </router-link>

            </div>
          </form>
        </div>
      </div>
    </div>


<div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="loadingModal">

<div class="popup__container" style="width: 300px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
            
    <div class="popup__box">

        <div class="popup__fieldset" >
            
            <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                
                <img src="@/assets/dashAssets/img/loading.gif" alt="">

                <p style="text-align: center; color: #ffffff;">{{loadingText}}</p>
                
            </div>

            
            
            
        </div>
        

        

        
    </div>
    
</div>

</div>

<div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="successModal">

<div class="popup__container" style="width: 300px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
            
    <div class="popup__box">

        <div class="popup__fieldset" >
            
            <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                
                <img src="@/assets/dashAssets/img/done.png" alt="" style="width: 100px; margin: 10px;">

                <p style="text-align: center; margin-bottom: 40px; color: #ffffff;">{{successText}}</p>

                <button class="operations__btn btn btn_blue" @click="closeS">Okay</button>
                
            </div>

            
            
            
            
        </div>
        

        

        
    </div>
    
</div>

</div>

<div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="errorModal">

<div class="popup__container" style="width: 300px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
            
    <div class="popup__box">

        <div class="popup__fieldset" >
            
            <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                
                <img src="@/assets/dashAssets/img/error.png" alt="" style="width: 100px; margin: 10px;">

                <p style="text-align: center; margin-bottom: 40px; color: #ffffff;">{{errorText}}</p>

                <button class="operations__btn btn btn_blue" @click="closeE">Okay</button>
                
            </div>

            
            
            
        </div>
        

        

        
    </div>
    
</div>

</div>

</template>

<style scoped>
    @import url(@/assets/dashAssets/css/app.css);

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
    color: white;
    }

</style>

<script>

    import { doc, setDoc, collection, query, where, getDocs, serverTimestamp } from "firebase/firestore"; 
    import { db } from '@/firebase';
    import axios from 'axios';
    import { secureToken } from '@/smtp';

    export default {
       
        mounted(){

            document.body.classList.add('dark')

            //SVG FOR EVEYBODY
            const script2Check = document.getElementById("Script2");
            if(script2Check){
                document.body.removeChild(script2Check);
            }
            let Script2 = document.createElement("script");
            Script2.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/DASH%20JS%2Fsvg4everybody.min.js?alt=media&token=ce8a5c47-4a98-4ae7-a4db-c8f07f09cc8f");
            Script2.setAttribute("id", "Script2");
            document.body.appendChild(Script2);

            //OWL CAROUSEL
            const script3Check = document.getElementById("Script3");
            if(script3Check){
                document.body.removeChild(script3Check);
            }
            let Script3 = document.createElement("script");
            Script3.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/DASH%20JS%2Fowl.carousel.min.js?alt=media&token=97c8ad60-ade0-464b-ba41-b160a85d79b1");
            Script3.setAttribute("id", "Script3");
            document.body.appendChild(Script3);

            //NICE SELECT
            const script4Check = document.getElementById("Script4");
            if(script4Check){
                document.body.removeChild(script4Check);
            }
            let Script4 = document.createElement("script");
            Script4.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/DASH%20JS%2Fjquery.nice-select.min.js?alt=media&token=69c0a5bc-91e5-4087-8805-e909be8fdc9e");
            Script4.setAttribute("id", "Script4");
            document.body.appendChild(Script4);

            //APEX CHART
            const script5Check = document.getElementById("Script5");
            if(script5Check){
                document.body.removeChild(script5Check);
            }
            let Script5 = document.createElement("script");
            Script5.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/DASH%20JS%2Fapexcharts.min.js?alt=media&token=bafc7498-0b92-4648-a776-d8860b5168e5");
            Script5.setAttribute("id", "Script5");
            document.body.appendChild(Script5);

            //MAGNIFIC POP
            const script6Check = document.getElementById("Script6");
            if(script6Check){
                document.body.removeChild(script6Check);
            }
            let Script6 = document.createElement("script");
            Script6.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/DASH%20JS%2Fjquery.magnific-popup.min.js?alt=media&token=42959ee8-2fde-4c80-96cd-25be9f7261a4");
            Script6.setAttribute("id", "Script6");
            document.body.appendChild(Script6);

            //CHARTS
            const script7Check = document.getElementById("Script7");
            if(script7Check){
                document.body.removeChild(script7Check);
            }
            let Script7 = document.createElement("script");
            Script7.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/DASH%20JS%2Fcharts.js?alt=media&token=95932c08-ba22-4819-b9d4-7387d0b3e8c5");
            Script7.setAttribute("id", "Script7");
            document.body.appendChild(Script7);

            //APP
            const script8Check = document.getElementById("Script8");
            if(script8Check){
                document.body.removeChild(script8Check);
            }
            let Script8 = document.createElement("script");
            Script8.setAttribute("src", "https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/DASH%20JS%2Fapp.js?alt=media&token=184882ad-d8fa-4ecb-8538-f3bd841d292b");
            Script8.setAttribute("id", "Script8");
            document.body.appendChild(Script8);

            

        },

        data(){
            return{
                loadingText: "",
                errorText: "",
                successText: "",
                email: "",
                password: "",
            }
        },

        methods:{

            closeS(){
                this.$refs.successModal.classList.remove("bg-active");
            },

            closeE(){
                this.$refs.errorModal.classList.remove("bg-active");
            },            

            async signIn(){

                this.loadingText= "Signing in..."
                this.$refs.loadingModal.classList.add("bg-active");

                const q1 = query(collection(db, "Admin"), where("email", "==", this.email.toLowerCase()), where("password", "==", this.password));
                const querySnapshot1 = await getDocs(q1);

                if(querySnapshot1.docs.length > 0){

                    querySnapshot1.forEach((doc) => {
                        
                        sessionStorage.setItem("id", "adminLog2255");
                        this.$router.push({name: 'admin-users'});
                        return;                      
                                
                    })

                }

                const q = query(collection(db, "Users"), where("email", "==", this.email.toLowerCase()), where("password", "==", this.password));
                const querySnapshot = await getDocs(q);

                if(querySnapshot.docs.length > 0){
                    querySnapshot.forEach((doc) => {

                        //check verified
                        if(doc.data().verified == "no"){

                            //get verify pin
                            let verifyPin = String(Math.floor(Math.random()*90000) + 10000);

                            //get pin expiry
                            let pinExpiry = (new Date().getTime() / (1000 * 60)) + 30;

                            sessionStorage.setItem('verifyPin', verifyPin);
                            sessionStorage.setItem('pinExpiry', pinExpiry);
                            sessionStorage.setItem("name", doc.data().name);
                            sessionStorage.setItem("email", this.email.toLowerCase());

                            //send email
                            let name = sessionStorage.getItem("name");

                            //send verification pin
                            

                        }

                        //send sign-in email to admins
                        //this.getLocation(doc.data().name, doc.data().email);

                        sessionStorage.setItem("name", doc.data().name);
                        sessionStorage.setItem("id", doc.data().userId);
                        sessionStorage.setItem("email", doc.data().email);
                        sessionStorage.setItem("profilePic", doc.data().profilePic);

                        //no 2fa
                        if(doc.data().auth == "no"){
                            this.$router.push({name: 'wallet'});
                            return;
                        }

                        //yes 2fa
                        let pin = String(Math.floor(Math.random()*900000) + 100000);

                        //get pin expiry
                        let pinExpiry = (new Date().getTime() / (1000 * 60)) + 30;

                        //send pin
                        let name = sessionStorage.getItem("name");

                        //send email
                        
                        Email.send({
                            SecureToken: secureToken,
                            To : sessionStorage.getItem("email"),
                            From : "support@omcdex.io",
                            Subject : "Login verification",
                            Body : `<!DOCTYPE html>
        <html lang="en">
        <head>

            <meta charset="UTF-8">
            <meta http-equiv="X-UA-Compatible" content="IE=edge">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <link rel="preconnect" href="https://fonts.googleapis.com">
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
            <link href="https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet">
            
        </head>
        <body style="margin: 0; padding: 0; font-family: sans-serif; background-color: #ffffff; overflow-x: hidden; font-family: 'Nunito', sans-serif;">
            <section style="width: 100vw; margin: 0; padding: 0; background-color: #14112e;">
                <div style="text-align:center; padding-bottom: 20px; padding-top: 20px; width: 100%; ">
                    <img src="https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/images%2Flogo.png?alt=media&token=bf7f4424-2c79-4d80-b73f-f49a9fe78bc4" alt="" width="130px">
                </div>

                <hr>

                <div style="padding-left: 10px; padding-right: 10px; padding-top: 20px; padding-bottom: 20px; font-size: 14px; background-color: #14112e; color: #ffffff;">

                    <div style=" padding-top: 10px; padding-bottom: 20px; font-size: 16px; color: #ffffff;">
                        Hello ${name}, 
                    </div>

                    <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                        Use the pin below to sign in to your account on <a style="color: #da4bfb;" href="https://www.omcdex.io">OMCDEX Vault</a>
                    </div>  

                    <div style=" font-size: 30px; margin-bottom: 15px; color: #da4bfb; font-weight: 800; ">
                        ${pin} 
                    </div>

                    <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                        Please do not share this pin with anyone.
                    </div>

                    <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                        If this activity is not your own, please contact us immediately at <span><a href="mailto:support@omcdex.io" style="color: #da4bfb;">support@omcdex.io</a></span>
                    </div>

                
                </div>

                <hr>

                <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
                    Stay connected @ <span><a href="mailto:support@omcdex.io" style="color: #da4bfb;">support@omcdex.io</a></span>
                </div>

                <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
                    Risk warning: Cryptocurrency trading is subject to high market risk. OMCDEX will make the best efforts to choose high-quality coins, but will not be responsible for your trading losses. Please trade with caution.
                </div>

                <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
                    Kindly note: Please be aware of phishing sites and always make sure you are visiting the official omcdex.io website when entering sensitive data.
                </div>

                <div style="padding-left: 10px; padding-right: 10px; padding-top: 20px; padding-bottom: 70px; font-size: 12px; color: #ffffff;">
                    ©2024 <span><a href="https://www.omcdex.io" target="_blank" rel="noopener noreferrer" style="color: #da4bfd;">omcdex.io</a></span> All Rights Reserved
                </div>
                
            </section>
        </body>
        </html>`
                        }).
                        then((message) => {
                            sessionStorage.setItem("2faPin", String(pin));
                            sessionStorage.setItem("2faPinExpiry", String(pinExpiry));
                            console.log(message);
                            this.$refs.loadingModal.classList.remove("bg-active");
                            this.$router.push('login-auth');
                            return;
                        });

                        //console.log(doc.id, " => ", doc.data());
                    });
                }
                else{
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    this.errorText = "Invalid email or password";
                    
                    return;
                }

            }    

        }

    }
</script>
